
import { Component, Input, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { process, State } from '@progress/kendo-data-query';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared.service';
import { SweetAlertIcon } from '../../constants/enum';
import { LoaderService } from '../../../core/services/loader.service';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-multiflats-expended',
  templateUrl: './multiflats-expended.component.html',
  styleUrl: './multiflats-expended.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class MultiflatsExpendedComponent {
  @Input() public flatsArry!: flatsArry[];
  public skip = 0;
  public residentialunitsListPageSize = 10;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public residenatilUintExtendedgridData: GridDataResult = { data: [], total: 0 };
  totalflatsCount: number = 0;
  public multiFlatsState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  constructor(private router: Router, private loaderService: LoaderService,) {

    this.loaderService.setLoading(true);
    // Consider moving data loading logic to ngOnInit if you need to load data
    this.loadWalletHistory();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['flatsArry']) {
      console.log('Wallet changed:', this.flatsArry);
      this.loadWalletHistory();
    }
  }





  private loadWalletHistory(): void {
    const state: State = {
      take: this.residentialunitsListPageSize,
      sort: [],  // Add sorting logic if needed
      filter: this.filter,
    };

    if (this.flatsArry) {
      const processedData = process(this.flatsArry, state);
      this.residenatilUintExtendedgridData = {
        data: processedData.data,
        total: this.totalflatsCount,
      };
    }
    this.loaderService.setLoading(false);
  }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }










}



interface flatsArry {
  // Define the properties of the FlatsArry object here
  residentialUnitId: number;
  flatNo: string;
  floor: string;
  block: string;
  tower: string;
}