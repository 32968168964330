<div class="container-fluid">

  <div class="row">
    <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>

  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <form class="row" [formGroup]="billsForm">
            <div class="row">
              <div class="col-lg-3">
                <div class="mb-4">

                  <label for="basicpill-pancard-input">Residential unit <span class="required">*</span></label>
                  <ng-select [items]="residentialUnitList" formControlName="ResidentialUnit"
                    (change)="residentialUnitChange($event)" [multiple]="false" bindLabel="residentialUnit"
                    bindValue="residentialUnitId" [placeholder]="'Select...'"></ng-select>
                  <!--  @if(submitted && billsControls['ResidentialUnit'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['ResidentialUnit'].errors['required']){
                    <div>{{prifileValidations.bill.residentialUnit}}</div>}

                  </div>} -->
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Consumer name <span class="required">*</span></label>
                  <input type="text" class="form-control" formControlName="consumer" placeholder="Consumer Name">
                  @if(submitted && billsControls['consumer'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['consumer'].errors['required']){
                    <div>{{prifileValidations.bill.consumer}}</div>}

                  </div>}
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Utility <span class="required">*</span></label>
                  <ng-select [items]="utilityTypeList" formControlName="utilityType" id="ocupantName" name="ocupantName"
                    (clear)="resetCalculations($event)" [(ngModel)]="selectedUtilityType"
                    (change)="utilityChange($event)" [multiple]="ismultiDropDown" bindLabel="utilityName"
                    bindValue="utilityId" [placeholder]="'Select...'"></ng-select>
                  @if(submitted && billsControls['utilityType'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['utilityType'].errors['required']){
                    <div>{{prifileValidations.bill.utility}}</div>}

                  </div>}
                </div>
              </div>

              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Meter number <span class="required">*</span></label>
                  <ng-select [items]="utilityMetersList" formControlName="MeterNumber" id="MeterNumber"
                    (change)="meterChange($event)" [(ngModel)]="selectedMeterNos" name="MeterNumber"
                    [multiple]="ismultiDropDown" bindLabel="macAddress" bindValue="umdId"
                    [placeholder]="'Select...'"></ng-select>
                  @if(submitted && billsControls['MeterNumber'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['MeterNumber'].errors['required']){
                    <div>{{prifileValidations.bill.mterNumber}}</div>}

                  </div>}
                </div>
              </div>

              @if(this.billsData.billType == 'manual'){
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Select bill for month <span class="required">*</span></label>
                  <ng-select [items]="billformonthList" formControlName="selectBillForMonth" id="MeterNumber"
                    name="MeterNumber" (change)="monthChange($event)" bindLabel="name" bindValue="id" [multiple]="false"
                    [placeholder]="'Select...'" class="select-month-dropdown"></ng-select>

                  @if(submitted && billsControls['selectBillForMonth'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['selectBillForMonth'].errors['required']){
                    <div>{{prifileValidations.bill.selectBillForMonth}}</div>}

                  </div>}
                </div>
                <span class="invalid-feedback">{{monthErrorMesg}}</span>
              </div>

              }
              <!-- <input class="form-control" type="date" formControlName="selectBillForMonth"> -->
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Total amount</label>
                  <input type="text" class="form-control" formControlName="totalAmount" placeholder="">
                  @if(submitted && billsControls['totalAmount'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['totalAmount'].errors['required']){
                    <div>{{prifileValidations.bill.totalAmount}}</div>}

                  </div>}
                </div>
              </div>
              <!-- <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Bill Status</label>
                  <input type="text" class="form-control" formControlName="billStatus" placeholder="" value="Unpaid">
                  @if(submitted && billsControls['billStatus'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['billStatus'].errors['required']){
                    <div>{{prifileValidations.bill.billStatus}}</div>}

                  </div>}
                </div>
              </div> -->
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Previous bill generated date </label>
                  <input type="text" class="form-control date" formControlName="LastMonthBillGeneratedDate"
                    placeholder="Select Date" name="singleDate" autocomplete="off" bsDatepicker [bsConfig]="{
                  isAnimated: true, 
                  dateInputFormat: 'DD/MM/YYYY',
                  showWeekNumbers: false,
                       
          }" />

                  <!--  <input class="form-control" type="date" format="'dd/MM/yyyy'"
                    formControlName="LastMonthBillGeneratedDate"> -->
                </div>
              </div>

              <!--   <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Bill Generated Date </label>
                  <input class="form-control" type="date" placeholder="dd/MM/yyyy" format="'dd/MM/yyyy'"
                    formControlName="billGeneratedDate">

                </div>
              </div> -->

              @if(billsData.billType=='adhoc'){
              <!--  <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Start date </label>
                  <input class="form-control" type="date" formControlName="startdate">

                </div>
              </div> -->
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">End date <span class="required">*</span></label>
                  <input type="text" class="form-control date" formControlName="endDate" placeholder="Select Date"
                    name="singleDate" autocomplete="off" (bsValueChange)="Selectdates($event)" bsDatepicker [bsConfig]="{
                    isAnimated: true, 
                    dateInputFormat: 'DD/MM/YYYY',
                    maxDate: today,  
               minDate: minDate,
                    showWeekNumbers: false,
                         
            }" />
                  <!-- <input class="form-control" type="date" format="'dd/MM/yyyy'" [attr.min]="getMinDate()"
                    (change)="Selectdates($event)" [attr.max]="getcurentdateDisabled()" formControlName="endDate"> -->
                </div>

              </div>}

              <!-- <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">Billed days – Dates diff </label>
                  <input type="text" class="form-control" formControlName="billedDays" placeholder="">
                  @if(submitted && billsControls['billedDays'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['billedDays'].errors['required']){
                    <div>{{prifileValidations.bill.billDays}}</div>}

                  </div>}
                </div>
              </div> -->
              <!-- 
              <div class="col-lg-3">
                <div class="mb-4">
                  <label for="basicpill-pancard-input">General Charges </label>
                  <input type="text" class="form-control" placeholder="" formControlName="generalCharge">
                  @if(submitted && billsControls['generalCharge'].errors){
                  <div class="invalid-feedback">
                    @if(billsControls['generalCharge'].errors['required']){
                    <div>{{prifileValidations.bill.generalCharge}}</div>}

                  </div>}
                </div>
              </div>  -->
              <!-- accordion Start -->
              <div>
                @if(gas_array.length>0){ <accordion [closeOthers]="false" class="accordion custom-accordian"
                  [isAnimated]="true">
                  <accordion-group [isOpen]="isGasOpen" [heading]="lengthOfgas" class="accordion-item mb-3 panel-open"
                    #group>
                    <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                      <i class="mdi font-size-24"
                        [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                    </span>
                    <div class="" formArrayName="gas_array">
                      <div *ngFor="let gas of gas_array.controls; let empIndex=index">
                        <div [formGroupName]="empIndex">
                          <div class="row">
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Meter no</label>
                                <input type="text" class="form-control" name="macAddress" id="macAddress"
                                  formControlName='macAddress'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Type</label>
                                <input type="text" class="form-control" formControlName='type'>

                              </div>
                            </div>


                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Unit rate </label>
                                <input type="text" class="form-control" name="UnitRateGas" id="UnitRateGas"
                                  formControlName='UnitRateGas' [disabled]="true">
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Previous bill meter reading (m³)</label>
                                <input class="form-control" type="text" name="previousMeterReadingGas"
                                  id="previousMeterReadingGas" formControlName='previousMeterReadingGas'>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Current bill meter reading (m³) </label>
                                <input type="text" class="form-control" name="componenurrentMeterReadingGas"
                                  id="componenurrentMeterReadingGas" formControlName='componenurrentMeterReadingGas'>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Number of units consumed (m³) </label>

                                <input type="text" class="form-control" name="noOfUnitsConsumedGas"
                                  id="noOfUnitsConsumedGas" formControlName='noOfUnitsConsumedGas'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Bill amount </label>
                                <input type="text" class="form-control" name="billAmount" id="billAmount"
                                  formControlName='billAmount'>

                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>
                        <div class="row" *ngIf="gas.get('utilityServiceCharges')?.value.length>0">
                          <table class="table custom-border-table text-center">
                            <thead>
                              <tr>
                                <th scope="col">Service Name</th>
                                <th scope="col">Amount(₹)</th>
                                <th scope="col">GST (%)</th>
                                <th scope="col">GST Amount(₹)</th>
                                <th scope="col">Total(₹)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let charge of gas.get('utilityServiceCharges')?.value">
                                <td>{{charge.name}}</td>
                                <td class="font-weight-bolder">
                                  {{charge.amount!=undefined?(charge.amount
                                  | number: '1.2-2') : '₹0.00'}}</td>
                                <td>{{charge.gst}}</td>

                                <td class="font-weight-bolder">
                                  {{charge.gstAmt!=undefined?(charge.gstAmt
                                  | number: '1.2-2') : '₹0.00'}}</td>
                                <td class="font-weight-bolder">
                                  {{charge.total!=undefined?(charge.total
                                  | number: '1.2-2') : '₹0.00'}}</td>
                              </tr>
                              <tr>
                                <th scope="row"></th>
                                <td colspan="1"></td>
                                <td></td>
                                <td class="font-weight-bolder"> Amount</td>
                                <td class="font-weight-bolder">
                                  {{gas.get('totalServiceCharge')?.value!=undefined?(gas.get('totalServiceCharge')?.value
                                  | number: '1.2-2') : '₹0.00'}}</td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                        <hr>
                      </div>

                    </div>


                  </accordion-group>

                </accordion>}

                @if(water_array.length>0){ <accordion [closeOthers]="false" class="accordion custom-accordian"
                  [isAnimated]="true">
                  <accordion-group [isOpen]="isWaterOpen" [heading]="lengthOFwater"
                    class="accordion-item mb-3 panel-open" #group>
                    <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                      <i class="mdi font-size-24"
                        [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                    </span>
                    <div class="" formArrayName="water_array">
                      <div *ngFor="let water of water_array.controls; let empIndex=index">
                        <div [formGroupName]="empIndex">

                          <div class="row">
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Meter no</label>
                                <input type="text" class="form-control" name="MeterNo" id="MeterNo"
                                  formControlName='MeterNo'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Type</label>
                                <input type="text" class="form-control" formControlName='type'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Unit Rate </label>
                                <input type="text" class="form-control" name="unitRateWater" id="unitRateWater"
                                  formControlName='unitRateWater'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Previous bill meter reading(L)</label>
                                <input class="form-control" type="text" name="previousMeterReadingWater"
                                  id="previousMeterReadingWater" formControlName='previousMeterReadingWater'>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Current bill meter reading(L) </label>
                                <input type="text" class="form-control" name="currentMeterReadinWater"
                                  id="currentMeterReadinWater" formControlName='currentMeterReadinWater'>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Number of units consumed </label>

                                <input type="text" class="form-control" name="noOfUnitsConsumedWater"
                                  id="noOfUnitsConsumedWater" formControlName='noOfUnitsConsumedWater'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Bill amount </label>
                                <input type="text" class="form-control" name="billAmount" id="billAmount"
                                  formControlName='billAmount'>

                              </div>
                            </div>

                            <hr>
                          </div>
                        </div>
                        <hr>
                        <div class="row" *ngIf="water.get('utilityServiceCharges')?.value.length>0">
                          <table class="table custom-border-table text-center">
                            <thead>
                              <tr>
                                <th scope="col">Service Name</th>
                                <th scope="col">Amount(₹)</th>
                                <th scope="col">GST (%)</th>
                                <th scope="col">GST Amount(₹)</th>
                                <th scope="col">Total(₹)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let charge of water.get('utilityServiceCharges')?.value">
                                <td>{{charge.name}}</td>
                                <td class="font-weight-bolder">
                                  {{charge.amount!=undefined?(charge.amount
                                  | number: '1.2-2') : '₹0.00'}}</td>
                                <td>{{charge.gst}}</td>

                                <td class="font-weight-bolder">
                                  {{charge.gstAmt!=undefined?(charge.gstAmt
                                  | number: '1.2-2') : '₹0.00'}}</td>
                                <td class="font-weight-bolder">
                                  {{charge.total!=undefined?(charge.total
                                  | number: '1.2-2') : '₹0.00'}}</td>

                              </tr>
                              <tr>
                                <th scope="row"></th>
                                <td colspan="1"></td>
                                <td></td>
                                <td class="font-weight-bolder"> Amount</td>
                                <td class="font-weight-bolder">
                                  {{water.get('totalServiceCharge')?.value!=undefined?(water.get('totalServiceCharge')?.value
                                  | number: '1.2-2') : '₹0.00'}}</td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </accordion-group>

                </accordion>}

                @if(electricity_array.length>0){<accordion [closeOthers]="false" class="accordion custom-accordian"
                  [isAnimated]="true">
                  <accordion-group [isOpen]="isEleOpen" [heading]="lengthOfelectricity"
                    class="accordion-item mb-3 panel-open" #group>
                    <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                      <i class="mdi font-size-24"
                        [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                    </span>
                    <div class="" formArrayName="electricity_array">
                      <div *ngFor="let row of electricity_array.controls; let empIndex=index">
                        <div [formGroupName]="empIndex">

                          <div class="row">
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Meter no</label>
                                <input type="text" class="form-control" name="MeterNo" id="MeterNo"
                                  formControlName='MeterNo' [disabled]="true">

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Type</label>
                                <input type="text" class="form-control" name="type" id="type" formControlName='type'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Unit rate EB </label>
                                <input type="text" class="form-control" name="unitRateEB" id="unitRateEB"
                                  formControlName='unitRateEB' [disabled]="true">

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Unit rate DG </label>
                                <input class="form-control" type="text" name="unitRateDG" id="unitRateDG"
                                  formControlName='unitRateDG'>
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Previous bill meter reading EB (kWh) </label>
                                <input type="text" class="form-control" name="previousMeterReadingEB"
                                  id="previousMeterReadingEB" formControlName='previousMeterReadingEB'>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Current bill meter reading EB (kWh) </label>
                                <input type="text" class="form-control" name="currentMeterReadingEB"
                                  id="currentMeterReadingEB" formControlName='currentMeterReadingEB'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Number of units consumed EB (kWh) </label>
                                <input type="text" class="form-control" name="noOfUnitsConsumedEB"
                                  id="noOfUnitsConsumedEB" formControlName='noOfUnitsConsumedEB'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Previous bill meter reading DG(kWh) </label>
                                <input type="text" class="form-control" name="previousMeterReadingDG"
                                  id="previousMeterReadingDG" formControlName='previousMeterReadingDG'>

                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Current bill meter reading DG (kWh)</label>
                                <input type="text" class="form-control" name="currentMeterReadingDG"
                                  id="currentMeterReadingDG" formControlName='currentMeterReadingDG'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="basicpill-lastname-input">Number Of units consumed DG (kWh) </label>
                                <input type="text" class="form-control" name="noOfUnitsConsumedDG"
                                  id="noOfUnitsConsumedDG" formControlName='noOfUnitsConsumedDG'>

                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="mb-3">
                                <label for="">
                                  Bill amount </label>
                                <input type="text" class="form-control" name="billAmount" id="billAmount"
                                  formControlName='billAmount'>
                              </div>
                            </div>
                          </div>
                          <hr>
                        </div>
                        <hr>


                        <div class="row" *ngIf="row.get('utilityServiceCharges')?.value.length>0">
                          <table class="table custom-border-table text-center ">
                            <thead>
                              <tr>
                                <th scope="col">Service Name</th>
                                <th scope="col">Amount(₹)</th>
                                <th scope="col">GST (%)</th>
                                <th scope="col">GST Amount(₹)</th>
                                <th scope="col">Total(₹)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let charge of row.get('utilityServiceCharges')?.value">
                                <td>{{charge.name}}</td>
                                <td class="font-weight-bolder">
                                  {{charge.amount!=undefined?(charge.amount
                                  | number: '1.2-2') : '₹0.00'}}</td>
                                <td>{{charge.gst}}</td>

                                <td class="font-weight-bolder">
                                  {{charge.gstAmt!=undefined?(charge.gstAmt
                                  | number: '1.2-2') : '₹0.00'}}</td>
                                <td class="font-weight-bolder">
                                  {{charge.total!=undefined?(charge.total
                                  | number: '1.2-2') : '₹0.00'}}</td>

                              </tr>
                              <tr>
                                <th scope="row"></th>
                                <td colspan="1"></td>
                                <td></td>
                                <td class="font-weight-bolder"> Amount</td>
                                <td class="font-weight-bolder">
                                  {{row.get('totalServiceCharge')?.value!=undefined?(row.get('totalServiceCharge')?.value
                                  | number: '1.2-2') : '₹0.00'}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>


                    </div>
                  </accordion-group>

                </accordion>}

                <!-- other Charges -->
                @if(otherCharge.length>0){ <accordion [closeOthers]="false" class="accordion custom-accordian"
                  [isAnimated]="true">
                  <accordion-group [isOpen]="isEleOpen" heading="Other Charges" class="accordion-item mb-3 panel-open"
                    #group>
                    <span accordion-heading class="float-end fs-5 d-flex align-items-center">
                      <i class="mdi font-size-24"
                        [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                    </span>

                    <div class="row" *ngIf="updateBillsbyResidentilauit.commonCharges.length>0">
                      <table class="table custom-border-table text-center">
                        <thead>
                          <tr>
                            <th scope="col">Service Name</th>
                            <th scope="col">Amount(₹)</th>
                            <th scope="col">GST (%)</th>
                            <th scope="col">GST Amount(₹)</th>
                            <th scope="col">Total(₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let charge of updateBillsbyResidentilauit.commonCharges">
                            <td>{{charge.name}}</td>
                            <td class="font-weight-bolder">
                              {{charge.amount!=undefined?(charge.amount
                              | number: '1.2-2') : '₹0.00'}}</td>
                            <td>{{charge.gst}}</td>

                            <td class="font-weight-bolder">
                              {{charge.gstAmt!=undefined?(charge.gstAmt
                              | number: '1.2-2') : '₹0.00'}}</td>
                            <td class="font-weight-bolder">
                              {{charge.total!=undefined?(charge.total
                              | number: '1.2-2') : '₹0.00'}}</td>

                          </tr>
                          <tr>
                            <th scope="row"></th>
                            <td colspan="1"></td>
                            <td></td>
                            <td class="font-weight-bolder"> Amount</td>
                            <td class="font-weight-bolder">
                              {{updateBillsbyResidentilauit.totalGeneralCharge!=undefined?(updateBillsbyResidentilauit.totalGeneralCharge
                              | number: '1.2-2') : '₹0.00'}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>


                    <!-- <div class="row" *ngFor="let col of updateBillsbyResidentilauit.commonCharges">
                      <div class="mb-3 col-lg-4">
                        <label for="">Service name</label>
                        <p>{{col.name }}</p>
                      </div>
                      <div class=" mb-3 col-lg-4">
                        <label for=""> Amount</label>
                        <p>{{col.amount?
                          (col.amount|currency:'INR':true):'0'}}</p>

                      </div>
                      <div class="mb-3 col-lg-4">
                        <label for="">GST %</label>
                        <p>{{col.gst}}</p>
                      </div>
                      <div class="mb-3 col-lg-4">
                        <label for="">GST amount</label>
                        <p>{{col.gstAmt?
                          (col.gstAmt|currency:'INR':true):'0'}}</p>

                      </div>
                      <div class="mb-3 col-lg-4">
                        <label for="">Total</label>
                        <p>{{col.total?
                          (col.total|currency:'INR':true):'0'}}</p>
                      </div>

                    </div> -->
                  </accordion-group>
                </accordion>}
              </div>
            </div>

          </form>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary" [routerLink]="'/bills'">
              Cancel
            </button>&nbsp;
            <button class="btn btn-primary" (click)="saveBills()" [disabled]="isdisabled">
              Generate
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>