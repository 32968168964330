<div *ngIf="loading">
  <div id="preloader">
    <div id="status">
      <img src="../../../../assets/images/Ripple Loader Animation Gif 1.gif" width="80" height="80" alt="Logo"
        id="loader-logo" />
      <!-- Rotating spinner -->
      <div class="spinner-chase">
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
        <div class="chase-dot"></div>
      </div>
    </div>
  </div>
</div>