import { Component, ViewChild } from '@angular/core';
import {
  DragOverEvent,
  NavigateEvent,
  SortableComponent,
} from '@progress/kendo-angular-sortable';
import { LoaderService } from './../../../core/services/loader.service';

import {
  FormArray,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Validations } from '../../../shared/constants/validations';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Store, compose, select } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  createConsumer,
  updateConsumer,
} from '../../../store/customer/consumer.action';
import { userListModel } from '../../../store/UserList/userlist.model';
import { CustomersModel } from '../../../store/customer/consumer.model';
import _ from 'lodash';
import { title } from 'process';
import { title_List } from '../../../shared/constants/templatesNames';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'app-rp-create-consumers',
  templateUrl: './rp-create-consumers.component.html',
  styleUrl: './rp-create-consumers.component.scss',
})
export class RpCreateConsumersComponent {
  @ViewChild('sortable') public sortable!: SortableComponent;
  consumersForm!: UntypedFormGroup;
  submitted: boolean = false;
  prifileValidations = Validations;
  emailRgex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$';
  nameRegex = '^[a-zA-Z]+(?: [a-zA-Z]+)?(?: [a-zA-Z]+)?$';

  breadCrumbItems: Array<{}> | undefined;
  blockRegexError!: string;
  Flat_HouseRegex_error!: string;
  FloorRegex_error!: string;
  TowerRegex_error!: string;
  public inputFields = [
    { label: 'Flat/House#', name: 'Flat_House', value: '' },
    { label: 'Floor', name: 'Floor', value: '' },
    { label: 'Tower', name: 'Tower', value: '' },
    { label: 'Block', name: 'Block', value: '' },
  ];
  currentUser: any;
  projectId: any;
  billingType: any;
  selectedtitle: any;
  updated_consumerData: any = {};
  public title_List = title_List
  configData: any;
  fromDate: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private router: Router,
    private store: Store,
    private sharedService: SharedService
  ) {
    if (
      history.state.consumerData != undefined &&
      history.state.consumerData !== null
    ) {
      this.updated_consumerData = history.state.consumerData;
    }

    this.breadCrumbItems = [
      { label: 'Consumer', url: 'consumers', active: true },
      {
        label: this.updated_consumerData?.id
          ? 'Update Consumer'
          : 'Create Consumer',
      },
    ];

    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.projectId = this.currentUser?.project.id;
    this.billingType = this.currentUser?.project.billingType;

    this.consumersForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
          Validators.pattern(this.nameRegex),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
          Validators.pattern(this.nameRegex),
        ],
      ],
      title: ['', Validators.required],
      email_first: [
        '',
        [Validators.required, Validators.pattern(this.emailRgex)],
      ],
      email_secound: ['', [Validators.pattern(this.emailRgex)]],
      countryCode: [null, Validators.required],
      mobile_Primary_Number: [
        '',
        [
          Validators.required,
          // Validators.maxLength(10),
          Validators.pattern(/^\d+$/),

        ],
      ],
      secondaryCountryCode: [null],
      mobile_Secondar_Number: ['', [
        // Validators.maxLength(10),
        Validators.pattern(/^\d+$/)
      ]],

      Occupied_Residential_Unit: [''],
      Enter_CO_Name: [''],
      Registered_date: ['', Validators.required],
      Custom_invoice_address: [false],
      // enable_wallet: [true],
    });
  }
  ngOnInit(): void {
    this.getAllConfigData();
    /* if (this.billingType == 'Postpaid') {
      this.consumersForm.controls['enable_wallet'].disable();
    } else {
      this.consumersForm.controls['enable_wallet'].enable();
    } */
    this.consumersForm.controls['Occupied_Residential_Unit'].disable();
    this.consumersForm.controls['Registered_date'].disable();
    this.fromDate = new Date();
    //this.selectedtitle = { id: 1, name: 'Mr' }

    if (Object.keys(this.updated_consumerData).length > 0) {
      const result = this.updated_consumerData?.residentialUnitList?.map((item: { flatNo: any; }) => item.flatNo).join(', ');

      // if (this.updated_consumerData?.title == 'Mr') {
      //   this.consumersForm.controls['title'].setValue('1');
      // } else if (this.updated_consumerData?.title == 'Mrs') {
      //   this.consumersForm.controls['title'].setValue('2');
      // } else {
      //   this.consumersForm.controls['title'].setValue('3');
      // }
      this.consumersForm.controls['Occupied_Residential_Unit'].disable();
      //console.log(this.updated_consumerData?.registeredDate)
      this.consumersForm.patchValue({
        title: this.updated_consumerData?.title ? this.updated_consumerData.title : '',
        firstName: this.updated_consumerData?.firstName
          ? this.updated_consumerData.firstName
          : '',
        lastName: this.updated_consumerData?.lastName
          ? this.updated_consumerData.lastName
          : '',
        email_first: this.updated_consumerData?.primary_Email
          ? this.updated_consumerData.primary_Email
          : '',
        email_secound: this.updated_consumerData?.secondary_Email
          ? this.updated_consumerData.secondary_Email
          : '',
        Enter_CO_Name: this.updated_consumerData?.coName
          ? this.updated_consumerData.coName
          : '',
        countryCode: this.updated_consumerData?.countryCodeId
          ? this.updated_consumerData.countryCodeId
          : '',
        mobile_Primary_Number: this.updated_consumerData?.phoneNumber
          ? this.updated_consumerData.phoneNumber
          : '',
        secondaryCountryCode: this.updated_consumerData?.secondaryCountryCodeId
          ? this.updated_consumerData.secondaryCountryCodeId
          : null,
        mobile_Secondar_Number: this.updated_consumerData?.secondaryPhoneNumber
          ? this.updated_consumerData.secondaryPhoneNumber
          : '',
        // this.formatDate(this.billGenerationDate)
        Registered_date: this.updated_consumerData?.registeredDate !== null

          ? this.formatDate(this.updated_consumerData.registeredDate)
          : '',
        Occupied_Residential_Unit: result,
        // Occupied_Residential_Unit: this.updated_consumerData?.residentialUnit,
        Custom_invoice_address: this.updated_consumerData?.isInvoiceRequired
          ? this.updated_consumerData.isInvoiceRequired
          : false,
        enable_wallet: this.updated_consumerData?.enable_Wallet
          ? this.updated_consumerData.enable_Wallet
          : false,
      });
      this.inputFields.forEach((field) => {
        if (field.name == 'Flat_House') {
          field.value = this.updated_consumerData.flatNo;
        }
        if (field.name == 'Floor') {
          field.value = this.updated_consumerData.floor;
        }
        if (field.name == 'Tower') {
          field.value = this.updated_consumerData.tower;
        }
        if (field.name == 'Block') {
          field.value = this.updated_consumerData.block;
        }
      });
      this.updated_consumerData.sortBy =
        this.updated_consumerData.sortBy.split(',');
      console.log(this.updated_consumerData.sortBy);
      this.inputFields.sort((a, b) => {
        //sortbale array
        return (
          this.updated_consumerData.sortBy.indexOf(a.name) -
          this.updated_consumerData.sortBy.indexOf(b.name)
        );
      });
    }
    console.log('this', this.consumersForm);
  }
  valueChange(value: any): void {
    console.log('valueChange', value);
    this.selectedtitle = value;
  }



  valiadationMessage: String = ''
  PrimaryvaliadationMobile(event: any) {
    console.log(event)
    switch (event.code) {
      /*  case '+971'://United Arab Emirates
         this.consumersControls['mobile_Primary_Number'].setValidators([Validators.required, Validators.maxLength(9), Validators.minLength(9), Validators.pattern(/^05[0-9]{8}$/),]);
         this.consumersControls['mobile_Primary_Number'].updateValueAndValidity();
         this.valiadationMessage = 'PhoneNumber Should Conatines min 9 and max 9 characters'
         break; */
      case '+91': // India
        this.consumersControls['mobile_Primary_Number'].setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^[6-9]\d{9}$/),]);
        this.consumersControls['mobile_Primary_Number'].updateValueAndValidity();
        this.valiadationMessage = 'PhoneNumber Should Conatines 10 characters'
        break;
      /* case '+86'://China
        this.consumersControls['mobile_Primary_Number'].setValidators([Validators.required, Validators.maxLength(11), Validators.minLength(11), Validators.pattern(/^1[3-9]\d{9}$/),]);
        this.consumersControls['mobile_Primary_Number'].updateValueAndValidity();
        this.valiadationMessage = 'PhoneNumber Should Conatines min 11 and max 11 characters'
        break;
 */

    }
  }

  SecondaryvaliadationMobile(event: any) {
    // console.log(event)
    switch (event.code) {
      /* case '+971'://United Arab Emirates
        this.consumersControls['mobile_Secondar_Number'].setValidators([Validators.maxLength(9), Validators.minLength(9), Validators.pattern(/^05[0-9]{8}$/),]);
        this.consumersControls['mobile_Secondar_Number'].updateValueAndValidity();
        this.valiadationMessage = 'PhoneNumber Should Conatines min 9 and max 9 characters'
        break; */
      case '+91': // India
        this.consumersControls['mobile_Secondar_Number'].setValidators([Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^[6-9]\d{9}$/),]);
        this.consumersControls['mobile_Secondar_Number'].updateValueAndValidity();
        this.valiadationMessage = 'PhoneNumber should contain 10 characters'
        break;
      /*   case '+86'://China
          this.consumersControls['mobile_Secondar_Number'].setValidators([Validators.maxLength(11), Validators.minLength(11), Validators.pattern(/^1[3-9]\d{9}$/),]);
          this.consumersControls['mobile_Secondar_Number'].updateValueAndValidity();
          this.valiadationMessage = 'PhoneNumber Should Conatines min 11 and max 11 characters'
          break; */


    }
  }

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data;
      }
    })
  }
  formatDate(isoString: any) {
    const date = new Date(isoString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
  saveConsumers(type: string) {
    if (this.consumersControls['mobile_Secondar_Number'].value.length > 0 && (this.consumersControls['secondaryCountryCode'].value == null || this.consumersControls['secondaryCountryCode'].value == undefined)) {
      return
    }
    this.submitted = true;
    console.log(this.consumersForm.value);
    console.log('inputFields', this.inputFields);
    if (
      this.consumersForm.valid &&
      this.consumersControls['email_secound'].value !=
      this.consumersControls['email_first'].value &&
      this.consumersControls['mobile_Secondar_Number'].value !=
      this.consumersControls['mobile_Primary_Number'].value
    ) {
      this.loaderService.setLoading(true);
      console.log(this.consumersForm.value);
      var request: any = {
        // title: this.selectedtitle.name,
        title: this.consumersForm.get('title')?.value,
        projectId: this.projectId,
        firstName: this.consumersForm.get('firstName')?.value,
        lastName: this.consumersForm.get('lastName')?.value,
        // "surName": this.consumersForm.get('surtName')?.value,
        primaryPhoneNumberCountryCodeId: this.consumersForm.get('countryCode')?.value,
        Primary_email: this.consumersForm.get('email_first')?.value,
        secondary_Email: this.consumersForm.get('email_secound')?.value,
        phoneNumber: this.consumersForm.get('mobile_Primary_Number')?.value,
        secondaryPhoneNumberCountryCodeId: this.consumersForm.get('secondaryCountryCode')?.value,
        secondaryPhoneNumber: this.consumersForm.get('mobile_Secondar_Number')
          ?.value,
        registeredDate: this.consumersForm.get('Registered_date')?.value,
        // "2024-05-30T05:56:06.303Z",,
        coName: this.consumersForm.get('Enter_CO_Name')?.value,
        isInvoiceRequired: this.consumersForm.get('Custom_invoice_address')
          ?.value
          ? this.consumersForm.get('Custom_invoice_address')?.value
          : false,
        /*  enable_wallet: this.consumersForm.get('enable_wallet')?.value
           ? this.consumersForm.get('enable_wallet')?.value
           : false, */
        flatNo: '',
        floor: '',
        tower: '',
        block: '',
        sortBy: '',
      };
      var sortBy: any[] = [];
      if (request.isInvoiceRequired == true) {
        this.inputFields.forEach((field: any) => {
          sortBy.push(field.name);

          switch (field.name) {
            case 'Flat_House':
              request.flatNo = field.value;
              break;
            case 'Floor':
              request.floor = field.value;
              break;
            case 'Tower':
              request.tower = field.value;
              break;
            default:
              request.block = field.value;
              break;
          }
        });
        /*  this.inputFields.forEach((field: any) => {
           sortBy.push(field.name);
           if (field.name == 'Flat_House') {
             request.flatNo = field.value;
           } else if (field.name == 'Floor') {
             request.floor = field.value;
           } else if (field.name == 'Tower') {
             request.tower = field.value;
           } else {
             request.block = field.value;
           }
         }); */
        request.sortBy = sortBy.toString();
      } else {
        (request.flatNo = ''),
          (request.block = ''),
          (request.floor = ''),
          (request.tower = '');
      }

      if (type == 'Create') {
        //updateConsumer
        delete request.primaryPhoneNumberCountryCodeId;
        delete request.secondaryPhoneNumberCountryCodeId;
        request.CountryCode = this.consumersForm.get('countryCode')?.value;
        request.SecondaryCountryCode = this.consumersForm.get('secondaryCountryCode')?.value;
        this.store.dispatch(createConsumer({ consumerData: request }));
      } else {


        this.store.dispatch(
          updateConsumer({
            updatedData: request,
            id: this.updated_consumerData.id,
          })
        );
      }

      console.log('request', request);
    }
  }
  getvaliadation(value: any, name: string): string {
    if (value == '') {
      if (name == 'Flat_House') {
        return 'Flat/House is required';
      }
      return name + ' is required';
    } else {
      return '';
    }
  }

  onFocusOut(event: any, name: string): void {
    const regex = /^[a-zA-Z0-9,#-]*$/;
    console.log('Focus out', event.target.value);

    let test = regex.test(event.target.value);

    if (name == 'Flat_House') {
      if (!test) {
        this.Flat_HouseRegex_error =
          'Only alphanumeric & special chars(# -,)_house';
      } else {
        this.Flat_HouseRegex_error = '';
      }
    }

    if (name == 'Floor') {
      if (!test) {
        this.FloorRegex_error = 'Only alphanumeric & special chars(# -,)_floor';
      } else {
        this.FloorRegex_error = '';
      }
    }

    if (name == 'Tower') {
      if (!test) {
        this.TowerRegex_error = 'Only alphanumeric & special chars(# -,)_Tower';
      } else {
        this.TowerRegex_error = '';
      }
    }
    if (name == 'Block') {
      if (!test)
        this.blockRegexError = 'Only alphanumeric & special chars(# -,)_Block';
    } else {
      this.blockRegexError = '';
    }
  }

  public onDragOver(event: DragOverEvent): void {
    event.preventDefault();
    this.sortable.moveItem(event.oldIndex, event.index);
  }

  public onNavigate(event: NavigateEvent): void {
    this.sortable.moveItem(event.oldIndex, event.index);
  }

  get consumersControls() {
    return this.consumersForm.controls;
  }

  get consumer_Invoices_List(): FormArray {
    return <FormArray>this.consumersForm.get(['consumer_Invoices_List']);
  }
}
