import { Injectable } from '@angular/core';
import { userListModel } from '../../../app/store/UserList/userlist.model';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { AppConfigData } from '../config/appConfigData';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { getDueBills } from '../../store/customer/consumer.action';

@Injectable({
  providedIn: 'root',
})
export class ConsumersService {
  constructor(private http: HttpClient, private router: Router) { }

  getConsumers(
    projectId: string = '',
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string,
    criteria: string = ''
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getConsumersUrl}?projectId=${projectId}&pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}` +
      `${criteria ? `&${criteria}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getConsumersExport(projectId: any, criteria: any): Observable<any> {
    let url;
    url = `${AppConfigData.getConsumersUrl}?projectId=${projectId}&criteria=${criteria}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  getConsumersMeterEExport(projectId: any, criteria: any): Observable<any> {
    let url;
    url = `${AppConfigData.getConsumerMeterControlsUrl + projectId
      }?criteria=${criteria}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getConsumersMeterOnOffExport(criteria: any): Observable<any> {
    let url;
    url = `${AppConfigData.getConsumerMeterOnOffControlsUrl
      }?criteria=${criteria}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  getConsumersRechargeHistoryExport(
    projectId: any,
    criteria: any
  ): Observable<any> {
    let url;
    url = `${AppConfigData.getConsumersRechargeHistoryUrl + projectId
      }?criteria=${criteria}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  // get bill  History export
  getConsumerBillHistoryExport(projectId: any, criteria: any): Observable<any> {
    let url;
    url = `${AppConfigData.consumerBillsHistoryUrl + projectId
      }?criteria=${criteria}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  // get bill  History
  getConsumerBillHistory(
    projectId: string = '',
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string,

  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.consumerBillsHistoryUrl + projectId
      }?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`


    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('consuner Recharge History failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  //get consumer Recgarge History;
  getConsumersRechargeHistory(
    projectId: string = '',
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getConsumersRechargeHistoryUrl + projectId
      }?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;
    // console.log(url)
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('consuner Recharge History failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  //getConsumerMeterControlsUrl
  getConsumerMeterControlsUrl(
    projectId: string = '',
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getConsumerMeterControlsUrl + projectId
      }?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('consuner Recharge History failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getConsumerMeterOnOffControlsUrl(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string,
    FromDate?: string,
    ToDate?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getConsumerMeterOnOffControlsUrl
      }?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${FromDate ? `&FromDate=${FromDate}` : ''}` +
      `${ToDate ? `&ToDate=${ToDate}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;



    /* 
        url =
          `${AppConfigData.getConsumerMeterOnOffControlsUrl
          }?pageIndex=${pageIndex}&pageSize=${pageSize}` +
          `${searchTerm ? `&search=${searchTerm}` : ''}` +
          `${filter ? `&${filter}` : ''}` +
          `${orderBy ? `&${orderBy}` : ''}`; */

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('consuner Recharge History failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getconsumerMeterDeatils(): Observable<any> {
    return this.http.get<any>(AppConfigData.getConsumerMeterDeatilsUrl).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('consuner Recharge History failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getDueBills(projectId: any): Observable<any> {
    return this.http
      .get<any>(AppConfigData.consumerdueBillsUrl + projectId)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('consuner Recharge History failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }
  getBillDetaildById(billNo: any): Observable<any> {
    return this.http
      .get<any>(AppConfigData.consumerBillsDetailsByIdUrl + billNo)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('consuner Recharge History failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  // settlements:

  getSettlementdeatils(
    pageIndex: number = 1,
    pageSize: number | undefined,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getSettlementDetailsUrl}?pageIndex=${pageIndex}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${pageSize == undefined ? `&criteria=${'All'}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('consuner Recharge History failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  checkStatusSettlment(transferId: any): Observable<any> {
    console.log('userData->', transferId);
    return this.http.get<any>(AppConfigData.CheckStatusTransferUrl + transferId).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  addSettlment(settlmentData: any): Observable<any> {
    console.log('userData->', settlmentData);
    return this.http.post<any>(AppConfigData.CreateTransferUrl, settlmentData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }





  //consumerBillsDetailsUrl
  getConsumptionRechargeHistory(
    projectId: string = '',
    pageIndex: number = 1,
    pageSize: number = 10,
    searchTerm: string = '',
    filter?: string,
    orderBy?: string,
    MeterId?: string
  ): Observable<any> {
    let url;
    url =
      `${AppConfigData.getconsumptionHistoryUrl + projectId
      }?pageIndex=${pageIndex}&pageSize=${pageSize}` +
      `${searchTerm ? `&search=${searchTerm}` : ''}` +
      `${MeterId ? `&MeterId=${MeterId}` : ''}` +
      `${filter ? `&${filter}` : ''}` +
      `${orderBy ? `&${orderBy}` : ''}`;

    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('consuner Recharge History failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  getConsumptionRechargeHistoryExport(
    projectId: any,
    criteria: any, MeterId?: string
  ): Observable<any> {
    let url;
    url = `${AppConfigData.getconsumptionHistoryUrl + projectId
      }?criteria=${criteria}` + `${MeterId ? `&MeterId=${MeterId}` : ''}`;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }
  // get consumer Faq;
  getConsumerFaqs(): Observable<any> {
    return this.http.get<any>(AppConfigData.getConsumersFaqUrl).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getuserprofile failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  //update MeterStatus

  ConsumerupdateMeterStatusUrl(
    meterId: any,
    meterStatus: any
  ): Observable<any> {
    console.log('userData->', meterId, meterStatus);

    //let url = `${AppConfigData.ConsumerupdateMeterStatusUrl + meterId}?Status=${meterStatus}`
    return this.http
      .post<any>(
        AppConfigData.ConsumerupdateMeterStatusUrl +
        meterId +
        `?status=${meterStatus} `,
        null
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('Update Meter Status  failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  //Create  User
  createConsumer(consumerData: any): Observable<any> {
    // console.log('userData->', consumerData);
    return this.http
      .post<any>(AppConfigData.createconsumerUrl, consumerData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  updateConsumer(consumerData: any, id: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.updateconsumerUrl + id, consumerData)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('getuserprofile failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  deleteConsumer(id: any): Observable<any> {
    return this.http.post<any>(AppConfigData.deleteconsumerUrl + id, null).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('deleteFaqs failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }

  getConsumerStatusId(id: any, status: any): Observable<any> {
    return this.http
      .post<any>(
        AppConfigData.getconsumerStatusIdUrl + id + `?status=${status} `,
        null
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          console.error('get stsus id failed:', error);
          return throwError(error); // Rethrow the error to propagate it further
        })
      );
  }

  updatemeterDeatils(updaedMeterData: any): Observable<any> {
    let url;
    url = `${AppConfigData.updatesortorderUrl}`

    return this.http.post<any>(url, updaedMeterData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        console.error('getMasters failed:', error);
        return throwError(error); // Rethrow the error to propagate it further
      })
    );
  }








}
