import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RpBreadcrumbComponent } from './rp-breadcrumb/rp-breadcrumb.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { NgStepperModule } from 'angular-ng-stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import {
  GridModule,
  PDFModule,
  ExcelModule,
} from '@progress/kendo-angular-grid';
import { DatePipe } from '@angular/common';

import { RpConsumptionHistoryComponent } from './rp-consumption-history/consumption-history/rp-consumption-history.component';
import { RpMetersReadingsComponent } from './rp-meters-reading/rp-meters-reading.component';
import { WalletHistoryComponent } from './wallet-history/wallet-history.component';
import { WalletHistoryExpendedComponent } from './wallet-history-expended/wallet-history-expended.component';
import { RpBillInvoicesComponent } from './rp-bill-invoices/rp-bill-invoices.component';
import { RpAlertsComponent } from './rp-alerts/rp-alerts.component';
import { BillsViewComponent } from './bills-view/bills-view.component';
import { MultiflatsExpendedComponent } from './multiflats-expended/multiflats-expended.component';

@NgModule({
  declarations: [
    RpBreadcrumbComponent,
    RpConsumptionHistoryComponent,
    RpMetersReadingsComponent,
    WalletHistoryComponent,
    WalletHistoryExpendedComponent,
    RpBillInvoicesComponent,
    RpAlertsComponent,
    BillsViewComponent,

    MultiflatsExpendedComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    BsDatepickerModule,
    TextBoxModule,
    ModalModule, DatePipe,
    AccordionModule,
    SharedModule,
    GridModule,
    PDFModule,
    ExcelModule,
    NgStepperModule,
    CdkStepperModule,
  ],
  exports: [
    RpBreadcrumbComponent,
    RpConsumptionHistoryComponent,
    RpMetersReadingsComponent,
    WalletHistoryComponent,
    WalletHistoryExpendedComponent, RpBillInvoicesComponent, BillsViewComponent, MultiflatsExpendedComponent
  ],
})
export class ComponentsModule { }
