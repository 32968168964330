import { Dashboard } from './dashboard.models';

import { createAction, props } from '@ngrx/store';


export const resetSuperAdminDashboardState = createAction(
  '[App] Reset  HistoryState'
);
export const getSuperAdminDashboard = createAction(
  '[super admin dashboard] dashboard'
);
export const getSuperAdminDashboardSuccess = createAction(
  '[super admin dashboard] Superadmindashboard Success',
  props<{ getSuperadmindashboardResponse: any }>()
);
export const getSuperAdminDashboardFailure = createAction(
  '[super admin dashboard] superadmindashboard Failure',
  props<{ getSuperadmindashboardResponseError: any }>()
);

export const getSuperAdminDashboardRevenuDetials = createAction(
  '[SuperAdmin Dashboard RevenuDetials] dashboard'
);
export const getSuperAdminDashboardRevenueSuccess = createAction(
  '[SuperAdmin Dashboard RevenuDetials] superadmindashboard Success',
  props<{ getSuperadmindashboardRevenueResponse: any }>()
);
export const getSuperAdminDashboardRevenueFailure = createAction(
  '[SuperAdmin Dashboard RevenuDetials] superadmindashboard Failure',
  props<{ getSuperadmindashboardRevenueResponseError: any }>()
);

export const getSuperAdminDashboardRevenuDetials_BasedOn_Date = createAction(
  '[getSuperAdminDashboardRevenuDetials_BasedOn_Date] dashboard',
  props<{
    daterange: any;
  }>()
);
export const getSuperAdminDashboardRevenuDetials_BasedOn_DateSuccess =
  createAction(
    '[getSuperAdminDashboardRevenuDetials_BasedOn_Date] super admin dashboard Success',
    props<{ getSuperadmindashboardRevenueOnDateResponse: any }>()
  );
export const getSuperAdminDashboardRevenuDetials_BasedOn_DateFailure =
  createAction(
    '[getSuperAdminDashboardRevenuDetials_BasedOn_Date] super admin dashboard Failure',
    props<{ getSuperadmindashboardRevenueOnDateResponseError: any }>()
  );


//admin dashboard:

export const getAdminDashboard = createAction('[ admin dashboard] dashboard', props<{ ProjectId: string; }>());
export const getAdminDashboardSuccess = createAction('[ admin dashboard] admindashboard Success', props<{ getadmindashboardResponse: any }>());
export const getAdminDashboardFailure = createAction('[ admin dashboard] admindashboard Failure', props<{ getadmindashboardResponseError: any }>());


export const getAdminFlatwiseConsmptionsDashboard = createAction('[ admin dashboard FlatwiseConsmptions ] dashboard FlatwiseConsmptions', props<{ flatwiseConsmptions: any }>());
export const getAdminFlatwiseConsmptionsDashboardSuccess = createAction('[ admin dashboard FlatwiseConsmptions] admindashboard  FlatwiseConsmptions Success', props<{ getadminFlatwiseConsmptionsdashboardResponse: any }>());
export const getAdminFlatwiseConsmptionsDashboardFailure = createAction('[ admin dashboard FlatwiseConsmptions] admindashboard  FlatwiseConsmptions Failure', props<{ getadminTopConsmptionsdashboardResponseError: any }>());


//consumer dashboard:

export const getConumerPostpaidDashboard = createAction('[ ConumerPostpaid dashboard] dashboard', props<{ consumerdata: any; }>());
export const getConumerPostpaidDashboardSuccess = createAction('[ ConumerPostpaid dashboard] ConumerPostpaiddashboard Success', props<{ getConumerPostpaidResponse: any }>());
export const getConumerPostpaidDashboardFailure = createAction('[ ConumerPostpaid dashboard] ConumerPostpaid Failure', props<{ getConumerPostpaidResponseError: any }>());


export const getConumerPostpaidConsumptionDashboard = createAction('[ ConumerPostpaid Consumption dashboard] dashboard', props<{ consumerdata: any; }>());
export const getConumerPostpaidConsumptionDashboardSuccess = createAction('[ ConumerPostpaid Consumption dashboard] ConumerPostpaidConsumptiondashboard Success', props<{ getConumerPostpaidConsumptionResponse: any }>());
export const getConumerPostpaidConsumptionDashboardFailure = createAction('[ ConumerPostpaid Consumption dashboard] ConumerPostpaidConsumptiondashboard Failure', props<{ getConumerPostpaidConsumptionResponseError: any }>());


export const getConumerConnectionsData = createAction('[ ConumerConnectionsData dashboard] dashboard', props<{ consumerdata: any; }>());
export const getConumerConnectionsDataSuccess = createAction('[ ConumerConnectionsData dashboard] ConumerPostpaidConnectionsData Success', props<{ getConumerConnectionsDataResponse: any }>());
export const getConumerConnectionsDataFailure = createAction('[ ConumerConnectionsData dashboard] ConumerConnectionsData Failure', props<{ getConumerConnectionsDataResponseError: any }>());
