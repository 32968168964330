import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LegendItemClickEvent,
  CategoryAxis,
  Legend,
  Series,
  SeriesLabelsContentArgs,
} from '@progress/kendo-angular-charts';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from '../../shared/shared.service';
import {
  getSuperAdminDashboard,
  getSuperAdminDashboardRevenuDetials,
  getSuperAdminDashboardRevenuDetials_BasedOn_Date,
  resetSuperAdminDashboardState,
} from '../../store/dashboard/dashboard.actions';
import {
  getSuperadmindashboardResponse,
  getSuperadmindashboardRevenueOnDateResponse,
  getSuperadmindashboardRevenueResponse,
} from '../../store/dashboard/dashboard-selector';
import { formatDate } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {
  superadmin_graphColors,
  monthColors,
} from '../../shared/constants/enum';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';
export interface Project {
  projectId: string;
  projectName: string;
}
@Component({
  selector: 'app-rp-dashboard',
  templateUrl: './rp-dashboard.component.html',
  styleUrl: './rp-dashboard.component.scss',
})
export class RpDashboardComponent {
  private getSuperadmindashboardResponse: Subscription = new Subscription();
  superAdminDashboardData: any;
  commStatusTotalCount: number = 0;
  gatewayStatusTotalCount: number = 0;
  totalResidentialUnits: number = 0;
  totalConsumers: number = 0;
  superAdminDashboardRevenueData: any = [];
  revenueData: any = [];
  categories: string[] = [];
  totalRevenueAmount: number = 0;
  datePickerConfig: Partial<BsDatepickerConfig>;
  superAdminDashboardRevenueDataBasedonDate: any;
  public selectedDateRange: { startDate: string; endDate: string } | null =
    null;

  public filterProjectBillDates: any
  revenuesProjectsList: any = [];
  revenuTotalProjectRevenueData: any = [];
  constructor(private store: Store, private sharedService: SharedService) {
    this.getDashboardData();
    const today = new Date();
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(today.getMonth() - 6);
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
        minDate: sixMonthsAgo, // Set minimum date to 6 months ago
        maxDate: today // Set maximum date to today
      }
    );
  }
  private _diff: number = 0;
  _days: number = 0;
  _hours: number = 0;
  _minutes: number = 0;
  _seconds: number = 0;
  private _trialEndsAt: any;
  public seriesVisible = true;
  public residentialUnitChart: any;
  selectedProject: Project[] = [];
  selectedRevenuWiseProject: any[] = [];
  dateRange: boolean = false;
  projectsList: Project[] = [];
  communicationStatusGraphData: any = [];
  GatewayInfoGraphData: any = [];
  residentialUnitsData: any = [];
  consumerData: any = [];
  selectedRange = 'Current Month';
  colors = superadmin_graphColors;
  monthColors = monthColors;
  selectedBalance: string = 'total';
  selectedUtilityCommunication = 'All'
  balanceOptions = [
    { value: 'total', label: 'Total' },
    { value: 'postpaid', label: 'Postpaid' },
    { value: 'prepaid', label: 'Prepaid' }
  ];

  ngOnInit(): void {

    this.getStoreInfo();
    // setTimeout(() => {
    //   this.communicationStatusData();
    //   this.gateWayInformationData();
    //   this.getResidentialUnitData();
    //   this.listOfConsumers();
    //   this.getRevenuedata();
    //   this.selectionChange('This Month');
    // }, 500);
  }

  // onUtilityChange(){
  //   alert(this.selectedUtilityCommunication+this.selectedProject)
  //   if(this.selectedProject.length>0){

  //   }
  // }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  onDateRangeSelected(dateRange: (Date | undefined)[] | undefined): void {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0];
      const endDate = dateRange[1];

      // Format the dates to the desired format
      this.selectedDateRange = {
        startDate: formatDate(
          startDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          'en-US'
        ),
        endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
      };

      this.getRevenuedataBasedOnDate();
    } else {
      this.selectedDateRange = {
        startDate: '',
        endDate: '',
      };
      this.superAdminDashboardRevenueDataBasedonDate = []
      // this.getRevenuedataBasedOnDate();
    }
  }

  getStoreInfo() {
    let getSuperadmindashboardResponse$ = this.store.pipe(
      select(getSuperadmindashboardResponse)
    );

    let getSuperadmindashboardRevenueResponse$ = this.store.pipe(
      select(getSuperadmindashboardRevenueResponse)
    );

    let getSuperadmindashboardRevenueOnDateResponse$ = this.store.pipe(
      select(getSuperadmindashboardRevenueOnDateResponse)
    );

    this.getSuperadmindashboardResponse = this.store.pipe(select(getSuperadmindashboardResponse)).subscribe((res) => {
      if (res && res.statusCode == 200) {
        this.superAdminDashboardData = res.data;
        this.projectsList = [
          ...this.getUniqueProjects(this.superAdminDashboardData) as Project[]
        ];


        this.communicationStatusData();
        this.gateWayInformationData();
        this.getResidentialUnitData();
        this.listOfConsumers();
        this.getRevenuedata();
        this.selectionChange('Current Month');

        // console.log(this.projectsList);
      }

    })

    /*   getSuperadmindashboardResponse$.subscribe((res: any) => {
       console.log("")
       
       console.log('superAdminDashboardData', res);
     }); */

    getSuperadmindashboardRevenueResponse$.subscribe((res: any) => {
      if (res && res.statusCode == 200) {
        this.superAdminDashboardRevenueData = res.data;
        // this.processRevenueData(
        //   this.superAdminDashboardRevenueData.monthlyDateWiseResult.slice(-6)
        // );
        this.revenuTotalProjectRevenueData = this.superAdminDashboardRevenueData.monthlyDateWiseResult.slice(-6)
        this.revenuesProjectsList = this.superAdminDashboardRevenueData.projectWiseRechargeData;
        this.revenuData(
          this.superAdminDashboardRevenueData.monthlyDateWiseResult.slice(-6)
        );
        this.totalRevenueAmount =
          this.superAdminDashboardRevenueData.totalRevenueAmount;
      }

      console.log('getSuperadmindashboardRevenueResponse', res);
    });

    getSuperadmindashboardRevenueOnDateResponse$.subscribe((res: any) => {
      if (res && res.statusCode == 200) {
        this.superAdminDashboardRevenueDataBasedonDate = res.data;
      }
      console.log('getSuperadmindashboardRevenueOnDateResponse', res);
    });
  }

  public getColor(index: number): string {
    // console.log(this.monthColors[this.categories[index]]);
    return this.monthColors[this.categories[index]];
  }

  ngOnDestroy() {
    this.store.dispatch(resetSuperAdminDashboardState());
    this.getSuperadmindashboardResponse.unsubscribe();


  }
  onChangeRevenueProject(data: any) {
    console.log(data);

  }
  public revenuData(revenueDataJson: any) {
    // Initialize a map to store revenue data by month
    const monthlyRevenueMap = new Map<string, number>();

    // If no project is selected, use the full data
    if (this.selectedRevenuWiseProject.length === 0) {
      // this.revenuesProjectsList.forEach((revenuProjet:any) => {
      //     if(revenuProjet){
      //       revenueDataJson = revenuProjet.monthlyRecharges.slice(-6);
      //       revenueDataJson.forEach((entry: any) => {
      //         const month = entry.monthName;
      //         const revenueAmount = entry.totalRechargeAmount;

      //         // Accumulate revenue for each month
      //         if (monthlyRevenueMap.has(month)) {
      //           monthlyRevenueMap.set(
      //             month,
      //             monthlyRevenueMap.get(month)! + revenueAmount
      //           );
      //         } else {
      //           monthlyRevenueMap.set(month, revenueAmount);
      //         }
      //       });
      //     }
      // });
      this.revenuTotalProjectRevenueData.forEach((entry: any) => {
        const month = entry.monthName;
        const revenueAmount = entry.totalRechargeAmount;

        // Accumulate revenue for each month
        if (monthlyRevenueMap.has(month)) {
          monthlyRevenueMap.set(
            month,
            monthlyRevenueMap.get(month)! + revenueAmount
          );
        } else {
          monthlyRevenueMap.set(month, revenueAmount);
        }
      });
    } else {
      // Loop through selected projects and accumulate revenue month-wise
      this.selectedRevenuWiseProject.forEach((revenuProjet: any) => {
        if (revenuProjet) {
          revenueDataJson = revenuProjet.monthlyRecharges.slice(-6);
          revenueDataJson.forEach((entry: any) => {
            const month = entry.monthName;
            const revenueAmount = entry.totalRechargeAmount;

            // Accumulate revenue for each month
            if (monthlyRevenueMap.has(month)) {
              monthlyRevenueMap.set(
                month,
                monthlyRevenueMap.get(month)! + revenueAmount
              );
            } else {
              monthlyRevenueMap.set(month, revenueAmount);
            }
          });
        }
      });
    }

    // Convert the map into an array for chart data in the desired JSON format
    this.revenueData = Array.from(monthlyRevenueMap.entries()).map(
      ([month, amount], index) => ({
        category: month,    // Use month as the category
        value: amount.toFixed(2),      // The total revenue for that month
        colorString: monthColors[month] // Color based on the month
      })
    );
    console.log(this.revenueData);
  }


  getUniqueProjects(data: any): Project[] {
    const projectsMap = new Map<string, string>();

    const addProjects = (projectCounts: { projectId: string; projectName: string }[]) => {
      projectCounts.forEach((project) => {
        projectsMap.set(project.projectId, project.projectName);
      });
    };

    if (data.communicationData && data.communicationData.projectCounts) {
      addProjects(data.communicationData.projectCounts);
    }
    if (data.consumersData && data.consumersData.projectCounts) {
      addProjects(data.consumersData.projectCounts);
    }
    if (data.gatewayData && data.gatewayData.projectCounts) {
      addProjects(data.gatewayData.projectCounts);
    }
    if (data.residentialUnitData && data.residentialUnitData.projectCounts) {
      addProjects(data.residentialUnitData.projectCounts);
    }

    // Convert the map to an array of unique projects
    return Array.from(projectsMap, ([projectId, projectName]) => ({
      projectId,
      projectName,
    })) as Project[]; // Assert the type
  }


  getDashboardData() {
    this.store.dispatch(resetSuperAdminDashboardState());
    this.store.dispatch(getSuperAdminDashboard());
  }


  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  clearDate() {
    this.filterProjectBillDates = null; // Clear the selected date
  }

  onChangeProject(data: any) {
    console.log(data)
    // this.selectedProject = data;
    this.communicationStatusData();
    this.gateWayInformationData();
    this.getResidentialUnitData();
    this.listOfConsumers();
  }

  communicationStatusData() {
    // Initialize counts
    let commCount = 0;
    let actualNonCommCount = 0;
    let nonCommInactiveCount = 0;
    let nonCommMCBOffCount = 0;
    this.commStatusTotalCount = 0;

    // Selected utility name (replace this with your actual selected utility logic)
    const selectedUtilityName = this.selectedUtilityCommunication;

    if (this.selectedProject.length === 0) {
      // Use total counts if no project is selected
      if (selectedUtilityName == 'All') {
        this.commStatusTotalCount = this.superAdminDashboardData.communicationData.totalCounts.totalCount;
        commCount = this.superAdminDashboardData.communicationData.totalCounts.commTotalCount;
        actualNonCommCount = this.superAdminDashboardData.communicationData.totalCounts.nonCommActualCount;
        nonCommInactiveCount = this.superAdminDashboardData.communicationData.totalCounts.nonCommInactiveCount;
        nonCommMCBOffCount = this.superAdminDashboardData.communicationData.totalCounts.nonCommMcbOffCount;
      } else {
        this.projectsList.forEach((projectId: any) => {
          const project = this.superAdminDashboardData.communicationData.projectCounts.find(
            (proj: any) => proj?.projectId === projectId?.projectId
          );
          if (project && selectedUtilityName != 'All') {
            // Filter the device counts based on the selected utility name
            project.deviceCounts.forEach((device: any) => {
              if (device.utilityName.toUpperCase() === selectedUtilityName.toUpperCase()) {
                commCount += device.commCount;
                actualNonCommCount += device.actualNonCommuCount;
                nonCommInactiveCount += device.nonCommInActiveCount;
                nonCommMCBOffCount += device.nonCommMCBOffCount;
                this.commStatusTotalCount += device.totalCount; // Ensure this is defined appropriately
              }

            });
          }
        });
      }
    } else {
      // Aggregate counts based on selected projects and utilityName
      this.selectedProject.forEach((projectId: any) => {
        const project = this.superAdminDashboardData.communicationData.projectCounts.find(
          (proj: any) => proj.projectId === projectId
        );
        if (project && selectedUtilityName != 'All') {
          // Filter the device counts based on the selected utility name
          project.deviceCounts.forEach((device: any) => {
            if (device.utilityName.toUpperCase() === selectedUtilityName.toUpperCase()) {
              commCount += device.commCount;
              actualNonCommCount += device.actualNonCommuCount;
              nonCommInactiveCount += device.nonCommInActiveCount;
              nonCommMCBOffCount += device.nonCommMCBOffCount;
              this.commStatusTotalCount += device.totalCount; // Ensure this is defined appropriately
            }

          });
        } else {
          commCount += project.commCount;
          actualNonCommCount += project.actualNonCommuCount;
          nonCommInactiveCount += project.nonCommInActiveCount;
          nonCommMCBOffCount += project.nonCommMCBOffCount;
          this.commStatusTotalCount = project.totalCount + this.commStatusTotalCount;
        }
      });
    }

    // Update the communication status graph data
    this.communicationStatusGraphData = [
      {
        name: "Communicating",
        data: [commCount],
        color: this.colors[1],
        stack: "a"
      },
      {
        name: "Non Communicating - Actual",
        data: [actualNonCommCount],
        color: this.colors[0],
        stack: "b"
      },
      {
        name: "Non Communicating - Inactive",
        data: [nonCommInactiveCount],
        color: this.colors[2],
        stack: "b"
      },
      {
        name: "Non Communicating - MCB Off",
        data: [nonCommMCBOffCount],
        color: this.colors[3],
        stack: "b"
      }
    ];

    console.log(this.communicationStatusGraphData);
  }




  gateWayInformationData() {
    this.gatewayStatusTotalCount = 0;
    let gatewayStatusInactiveCount = 0;

    if (this.selectedProject.length == 0) {
      this.gatewayStatusTotalCount =
        this.superAdminDashboardData.gatewayData.totalCounts.gatewayStatusTotalCount;
      gatewayStatusInactiveCount =
        this.superAdminDashboardData.gatewayData.totalCounts
          .gatewayStatusInactiveCount;
    } else {
      this.selectedProject.forEach((projectId: any) => {
        const project =
          this.superAdminDashboardData.gatewayData.projectCounts.find(
            (proj: any) => proj.projectId === projectId
          );
        if (project) {
          this.gatewayStatusTotalCount += project.totalCount;
          gatewayStatusInactiveCount += project.inactiveCount;
        }
      });
    }

    this.GatewayInfoGraphData = [
      {
        value: this.gatewayStatusTotalCount - gatewayStatusInactiveCount,
        color: this.colors[1],
        name: `Active`
        ,
      },
      {
        value: gatewayStatusInactiveCount,
        color: this.colors[0],
        name: `Inactive`,
      },
    ];
  }

  getResidentialUnitData() {
    this.totalResidentialUnits = 0;
    let occupiedUnits = 0;
    let unoccupiedUnits = 0;

    if (this.selectedProject.length == 0) {
      this.totalResidentialUnits =
        this.superAdminDashboardData.residentialUnitData.totalCounts.totalUnits;
      occupiedUnits =
        this.superAdminDashboardData.residentialUnitData.totalCounts
          .occupiedUnits;
      unoccupiedUnits =
        this.superAdminDashboardData.residentialUnitData.totalCounts
          .unoccupiedUnits;
    } else {
      this.selectedProject.forEach((projectId: any) => {
        const project =
          this.superAdminDashboardData.residentialUnitData.projectCounts.find(
            (proj: any) => proj.projectId === projectId
          );
        if (project) {
          this.totalResidentialUnits += project.totalUnits;
          occupiedUnits += project.occupiedUnits;
          unoccupiedUnits += project.unoccupiedUnits;
        }
      });
    }

    this.residentialUnitsData = [
      {
        name: `Occupied units`,
        value: occupiedUnits,
        color: this.colors[1],
      },
      {
        name: `Unoccupied units`,
        value: unoccupiedUnits,
        color: this.colors[0],
      },
    ];
  }

  listOfConsumers() {
    this.totalConsumers = 0;
    let activeConsumers = 0;
    let inactiveConsumers = 0;

    // Check if 'All Projects' is selected
    if (this.selectedProject.length == 0) {
      /*  this.totalConsumers =
         this.superAdminDashboardData.consumersData.totalCounts.totalConsumers;
       activeConsumers =
         this.superAdminDashboardData.consumersData.totalCounts.activeConsumers;
       inactiveConsumers =
         this.superAdminDashboardData.consumersData.totalCounts
           .inactiveConsumers; */

      this.superAdminDashboardData.ownerTenentCounts.map((item: any) => {
        this.totalConsumers += item.ownerCount + item.tenantCount
        activeConsumers += item.ownerCount
        inactiveConsumers += item.tenantCount
      })

      /* this.totalConsumers =
        this.superAdminDashboardData.ownerTenentCounts.tenantCount + this.superAdminDashboardData.ownerTenentCounts.ownerCount
      activeConsumers =
        this.superAdminDashboardData.ownerTenentCounts.ownerCount
      inactiveConsumers =
        this.superAdminDashboardData.ownerTenentCounts.tenantCount
 */
    } else {
      console.log(this.selectedProject)
      this.selectedProject.forEach((projectId: any) => {
        // Find the project in the consumer data
        const project =
          this.superAdminDashboardData.ownerTenentCounts.find(
            (proj: any) => proj.projectId === projectId
          );
        console.log(project)
        if (project) {
          // Accumulate the counts for the selected projects
          this.totalConsumers += project.ownerCount + project.tenantCount;
          activeConsumers += project.ownerCount;
          inactiveConsumers += project.tenantCount;
        }
      });
    }

    // Update the consumerData array with the calculated values
    this.consumerData = [
      {
        name: `Owner`,
        value: activeConsumers,
        color: this.colors[1],
      },
      {

        name: `Tenant`,
        value: inactiveConsumers,
        color: this.colors[0],
      },
    ];
  }

  getRevenuedata() {
    this.store.dispatch(getSuperAdminDashboardRevenuDetials());
  }

  getRevenuedataBasedOnDate() {
    this.store.dispatch(
      getSuperAdminDashboardRevenuDetials_BasedOn_Date({
        daterange: this.selectedDateRange,
      })
    );
  }

  // revenueData = [
  //   {
  //     data: [1, 4, 6, 4, 8],
  //     color: '#63ABFD',
  //     name: 'Revenue',
  //   },
  // ];
  // public categories: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May'];

  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.value;
  }

  // public residentialUnitsData = [
  //   { name: 'Occupied units', value: 200, color: '#63ABFD' },
  //   { name: 'Unoccupied units', value: 200, color: '#FFA5CB' },
  // ];

  // public consumerData = [
  //   { name: 'Active', value: 800, color: '#63ABFD' },
  //   { name: 'Inactive', value: 200, color: '#FFA5CB' },
  // ];

  public listItems: Array<string> = ['Project 1', 'Project 2', 'Project 3'];


  public months: Array<string> = [
    'Current Month',
    'Last Month',
    'Last Three Months',
    'Last Six Months',
    'Custom Range',
  ];

  projects = [
    {
      projectsName: 'My Home Bhooja',
      revenue: '80,00,000',
      username: 'John doe',
    },
    {
      projectsName: 'My Home Avatar',
      revenue: '80,00,000',
      username: 'Jane doe',
    },
    {
      projectsName: 'Rajapushpa Projects',
      revenue: '80,00,000',
      username: 'Jim beam',
    },
    {
      projectsName: 'Prestige Elites',
      revenue: '80,00,000',
      username: 'Jane doe',
    },
    {
      projectsName: 'Luxury Bhooja Villas',
      revenue: '80,00,000',
      username: 'Jim beam',
    },
  ];

  legend: Legend = {
    position: 'bottom',
  };


  public onSeriesClick(e: SeriesClickEvent): void {
    console.log('Clicked on:', e);
    // alert(`You clicked on ${e.category}: ₹${e.value}`);
  }
  public onLegendItemClick(e: LegendItemClickEvent): void {
    /* Do not hide the series on legend click */
    e.preventDefault();

    /* Hide the series manually */
    this.seriesVisible = !this.seriesVisible;
  }




  getDays(t: number) {
    return Math.floor(t / (1000 * 60 * 60 * 24));
  }

  getHours(t: number) {
    return Math.floor((t / (1000 * 60 * 60)) % 24);
  }

  getMinutes(t: number) {
    return Math.floor((t / 1000 / 60) % 60);
  }

  getSeconds(t: number) {
    return Math.floor((t / 1000) % 60);
  }

  // public selectionChange(value: any): void {
  //   // this.log("selectionChange", value);
  //   console.log(value);
  //   if (value == 'Custom Range') {
  //     this.dateRange = true;
  //   } else {
  //     this.dateRange = false;
  //   }
  // }

  selectionChange(event: any) {
    const selectedOption = event;
    this.dateRange = false;
    const now = new Date();
    let startDate: Date;
    let endDate: Date = now;

    /* switch (selectedOption) {
      case 'This Month':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        break;
      case 'Last Month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case 'Three Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 2, 1);
        break;
      case 'Six Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 5, 1);
        break;
      case 'One Year':
        startDate = new Date(now.getFullYear() - 1, now.getMonth() + 1, 1);
        break;
      case 'Custom Range':
        this.dateRange = true;
        // Implement custom range logic here
        return;
      default:
        startDate = now;
    } */
    this.filterProjectBillDates = null
    switch (selectedOption) {
      case 'Current Month':
        startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        endDate = now; // Current date of the current month
        endDate.setHours(23, 59, 59, 999);
        break;
      case 'Last Month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1); // First day of last month
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999);  // Last day of last month
        break;
      case 'Last Three Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999); // Last day of the previous month
        break;
      case 'Last Six Months':
        startDate = new Date(now.getFullYear(), now.getMonth() - 6, 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0);
        endDate.setHours(23, 59, 59, 999);  // Last day of the previous month
        break;
      /* case 'One Year':
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), 1);
        endDate = new Date(now.getFullYear(), now.getMonth(), 0); // Last day of the previous month
        break; */
      case 'Custom Range':
        this.dateRange = true;
        // Implement custom range logic here
        return;
      default:
        startDate = now;
        endDate = now;
    }

    this.selectedDateRange = {
      startDate: formatDate(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
      endDate: formatDate(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US'),
    };

    this.getRevenuedataBasedOnDate();
    console.log(this.selectedDateRange);
  }
}
