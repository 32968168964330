import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { formatDate } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { process, State } from '@progress/kendo-data-query';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  getBillHistoryExportResponse,
  getBillHistoryResponse,
} from '../../../store/customer/consumer-selector';
import {
  getBillHistory,
  getBillHistoryExport,
  resetBillHistoryState,
} from '../../../store/customer/consumer.action';
import { table_pageSize } from '../../../shared/constants/templatesNames';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../shared/notification.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Helper } from '../../../core/helpers/helper';
@Component({
  selector: 'app-rp-bill-history',

  templateUrl: './rp-bill-history.component.html',
  styleUrl: './rp-bill-history.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class RpBillHistoryComponent {
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  public filterMode: FilterableSettings = 'row';
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  projectId: any;
  consumerId: any;
  currentUser: any;
  public pageSizes = table_pageSize.pageSizes;
  //public pageSizes = true;
  public previousNext = true;
  public billHistoryState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  fromDate: any = [];
  billdate: any = [];
  public sort: SortDescriptor[] = [];
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  exportData: any[] = [];
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public pageSize = 10;
  public skip = 0;
  billHistoryList: any[] = [];
  totalUserCount: number = 0;
  currentPage: number = 1;
  breadCrumbItems: Array<{}> | undefined;
  datePickerConfig: Partial<BsDatepickerConfig>;
  public billHistoryData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  billType: any;
  private billhistorySubscription: Subscription;
  constructor(

    private router: Router,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService,
    private notificationService: NotificationService
  ) {
    this.datePickerConfig = Object.assign({}, {
      dateInputFormat: 'DD/MM/YYYY', // Set to dd/MM/yyyy format
      showWeekNumbers: false,
      isAnimated: true,
      containerClass: 'theme-dark-blue'
    });
    this.breadCrumbItems = [{ label: 'Bill History' }];
    if (history.state.billType != undefined && history.state.billType !== null) {
      this.billType = history.state.billType;
      //  console.log(this.billType)
      // this.residentialunitRequest = history.state.residentialUnitRequest;

    }

    this.billhistorySubscription = this.notificationService.billHistoryUpdate$.subscribe(data => {
      this.updateBillHistoryPage(data);
    });
    this.getbillHistoryStoreInfo();
  }
  clearDate() {
    this.queryString = '';
    this.fromDate = []; // Clear the selected date
    this.getBillHistory();


  }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  isFilter: any;
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.isFilter = localStorage.getItem('filter');
    this.consumerId = this.currentUser?.currentUserRoleDetails?.userId;
    this.projectId = this.currentUser?.project.id;
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    if (this.billType && typeof this.billType === 'object' && Object.keys(this.billType).length > 0) {
      if (this.isFilter == 'true') {
        this.filter = this.sharedService.getFilter();
        this.billHistoryState.filter = this.filter;
        // console.log(this.filter)
        // console.log(this.billHistoryState)
        this.queryString = this.sharedService.createQuery(this.billHistoryState);
      }

      if (this.billType.type == "bills") {
        this.pageSize = 5;
        // console.log(this.pageSize)
      }
      if (this.billType.type.toLowerCase() == "paid") {

        let status = this.billType.type;
        // console.log(status)
        this.billHistoryState = {
          skip: 0,
          take: 10,
          filter: {
            logic: 'and',
            filters: [{ field: 'paymentStatus', operator: 'contains', value: 'UnPaid' }],
          },
        };
        this.queryString = `$filter= contains(paymentStatus,'UnPaid')`;
      }
    }
    this.searchedValue = history.state.info ?? '';
    this.getBillHistory()

  }

  getBillHistory() {
    this.store.dispatch(
      getBillHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,

      })
    );
  }

  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.billHistoryonPageChange({ skip: 0, take: this.pageSize })
      this.getBillHistory()
      /*  this.store.dispatch(
         getBillHistory({
           pageIndex: this.currentPage,
           pageSize: this.pageSize,
           searchTerm: this.searchedValue,
           filter: this.queryString,
           orderBy: this.orderByQuery,
           ProjectId: this.projectId,
           FromDate:
             this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
           ToDate:
             this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
         })
       ); */
    }
  }

  onSearchBillHistory(): void {
    if (this.searchedValue.length > 0) {
      this.getBillHistory()
      /*  this.store.dispatch(
         getBillHistory({
           pageIndex: this.currentPage,
           pageSize: this.pageSize,
           searchTerm: this.searchedValue,
           filter: this.queryString,
           orderBy: this.orderByQuery,
           ProjectId: this.projectId,
           FromDate:
             this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
           ToDate:
             this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
         })
       ); */
    }

  }
  updateBillHistoryPage(data: any) {
    this.searchedValue = data;
    this.getBillHistory()
    /* this.store.dispatch(
      getBillHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    ); */
    // Trigger any additional logic needed to refresh the page
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  private exportSubscription: Subscription = new Subscription();
  getbillHistoryStoreInfo() {
    this.store.dispatch(resetBillHistoryState());
    let getBillHistory$ = this.store.pipe(select(getBillHistoryResponse));
    this.exportSubscription = this.store
      .pipe(select(getBillHistoryExportResponse))
      .subscribe((res: any) => {
        if (res) {
          this.exportData = [];
          if (res.items.length > 0) {
            this.exportData = [...res.items];
            const transformedData = this.exportData?.map((item: any) => ({
              'Bill Number': item.billNo,
              'Transaction Id': item.transactionId,
              'Residential Unit': item.residentialUnit,
              'Bill Date': formatDate(item.billDate, 'dd MMM yyyy hh:mm a', 'en-US'),
              'Payment Date': item.paymentDate == '0001-01-01T00:00:00' ? '-' : formatDate(item.paymentDateitem.paymentDate, 'dd MMM yyyy hh:mm a', 'en-US'),
              'Utility': item.utility,
              'Meter No': item.macAddress,
              'Bill Amount': item.billAmount,
              'Mode of Payment': item.modeOfPayment,
              'Payment Status': item.paymentStatus,
            }));
            this.PdfexportService.downloadExcel(
              transformedData,
              'Bill History'
            );
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    getBillHistory$.subscribe((data: any) => {
      if (data) {
        this.billHistoryList = [...data.items];
        // console.log(data);
        this.pageSize = data.pageSize;
        this.totalUserCount = data.totalCount;
        this.loadGridTable();
      }
    });
  }
  ngOnDestroy() {
    if (this.billhistorySubscription) {
      this.billhistorySubscription.unsubscribe();
    }
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.billHistoryList, state);
    this.billHistoryData = {
      data: this.billHistoryList,
      total: this.totalUserCount,
    };
    this.billHistoryData.data = this.billHistoryData?.data.map((item) => {
      // console.log(new Date(project.currentDate));
      return {
        ...item,
        paymentDate: item.paymentDate == '0001-01-01T00:00:00' ? '-' : item.paymentDate
      };
    });


    this.loaderService.setLoading(false);
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.billHistoryState.sort = sort;
    // console.log(this.billHistoryState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.billHistoryState.sort
    );

    this.orderByQuery = orderByQuery;
    this.getBillHistory()
    /* this.store.dispatch(
      getBillHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    ); */
  }


  public onBillFilterChange(filter: CompositeFilterDescriptor): void {
    this.billHistoryState.filter = filter;
    this.skip = 0;
    //console.log(filter)
    // console.log(this.billHistoryState);
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);
    const queryString = this.sharedService.createQuery(this.billHistoryState);
    this.queryString = this.sharedService.createQuery(this.billHistoryState);
    this.getBillHistory()

    /* this.store.dispatch(
      getBillHistory({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    ); */
    // console.log(queryString);
  }


  filterBasedOnDate(flag: number) {
    //  console.log(this.fromDate)
    /*   if (flag == 0) {
        this.fromDate = [];
        //  this.queryString = `$filter=(billDate ge ${Helper.formatDate(this.fromDate[0])} and billDate le ${Helper.formatDate(this.fromDate[1])})`;
      } */


    if (flag === 0) {
      this.fromDate = [];
      this.queryString = '';
    } else {
      const formattedStartDate = this.fromDate[0].toISOString().split('T')[0];
      const formattedEndDate = this.fromDate[1].toISOString().split('T')[0];

      // Construct the query string with the formatted dates (yyyy-MM-dd)
      this.queryString = `$filter=(billDate ge ${formattedStartDate} and billDate le ${formattedEndDate})`;
    }
    this.store.dispatch(
      getBillHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,


      })
    );
    /* this.store.dispatch(
          getConsumerRechargeHistory({
            pageIndex: this.currentPage,
            pageSize: this.pageSize,
            searchTerm: this.searchedValue,
            filter: this.queryString,
            orderBy: this.orderByQuery,
            ProjectId: this.projectId,
          })
    ) */
    // FromDate:this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',

    //ToDate:  this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',

  }
  /*  selectDates(event: any) {
   
     if (event == undefined) {
       this.fromDate = [];
       this.queryString = ''
       this.store.dispatch(
         getBillHistory({
           pageIndex: this.currentPage,
           pageSize: this.pageSize,
           searchTerm: this.searchedValue,
           filter: this.queryString,
           orderBy: this.orderByQuery,
           ProjectId: this.projectId,
         })
       );
     }
   } */

  protected billHistoryonPageChange({ skip, take }: PageChangeEvent): void {
    // console.log('>>>', skip, take);
    this.selectedRowdata = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    //  console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getBillHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,

      })
    );

    this.loadGridTable();
  }

  protected billHostoryStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGridTable();
  }

  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  onSelect(event: any) {
    // console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        //  console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        //   console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }

  public onFilter(value: Event): void {
    // const inputValue = value;
    // this.searchedValue = value;
    this.billHistoryonPageChange({ skip: 0, take: this.pageSize })
    this.store.dispatch(
      getBillHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,

      })
    );
  }
  exportAll() {
    this.store.dispatch(
      getBillHistoryExport({ ProjectId: this.projectId, criteria: 'All' })
    );
  }
  onExport(type: string) {
    /* if (this.selectedRowdata.length == 0) {
      this.store.dispatch(getBillHistoryExport({ ProjectId: this.projectId, criteria: 'All', }));
    } */
    if (type === 'Excel') {
      if (this.billHistoryData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      }
      else {
        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }

  onViewClick(item: any) {
    this.router.navigate(['/billHistory/billDetail'], {
      state: { billHostorydeatils: item },
    });
  }


  downloadbillsumary(data: any,) {
    //event.stopPropagation();
    var temp = {
      id: data.billNo,
      status: data.paymentStatus,
      // paymentId: data.transactionId
    }
    //downloadBillsPayment

    this.sharedService.downloadBillsPayment(temp).subscribe({
      next: (blob: Blob) => {
        //  const contentDisposition = blob.type === 'application/pdf' ? `attachment; filename="${}"` : null;
        let filename = `${data.billNo}.pdf`; // Default filename

        // If content disposition is available, extract filename
        /* s */
        if (blob) {
          this.loaderService.setLoading(false);
          console.log('Binary data received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

        } else {
          this.loaderService.setLoading(false);
          console.error('Blob is null');
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error occurred:', err);
      },
    });
  }

  downloadBillsPaymentrecceipt(data: any,) {
    //event.stopPropagation();
    var temp = {
      // id: data.billNo,
      //status: data.paymentStatus,
      paymentId: data.transactionId
    }
    //downloadBillsPayment

    this.sharedService.downloadBillsPaymentrecceipt(temp).subscribe({
      next: (blob: Blob) => {
        //  const contentDisposition = blob.type === 'application/pdf' ? `attachment; filename="${}"` : null;
        let filename = `${data.transactionId}.pdf`; // Default filename

        // If content disposition is available, extract filename
        /* s */
        if (blob) {
          this.loaderService.setLoading(false);
          console.log('Binary data received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

        } else {
          this.loaderService.setLoading(false);
          console.error('Blob is null');
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.error('Error occurred:', err);
      },
    });
  }






















}
