import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { ChangeDetectorRef } from '@angular/core';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { process, State } from '@progress/kendo-data-query';
import { usersData } from './users-data';
import { Store, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import {
  getuserlist,
  deleteuserlist,
  getUserStstusChnage,
} from '../../../store/UserList/userlist.action';
import { Subscription } from 'rxjs';
import {
  getAllUserResponse,
  getUsersResponse,
  getuserStatusResponse,
} from '../../../store/UserList/userlist-selector';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import {
  getAlluserlist,
  resetState,
} from '../../../store/UserList/userlist.action';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  Templates,
  table_pageSize,
} from '../../../shared/constants/templatesNames';
import { PdfexportService } from '../../../core/services/pdfexport.service';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { SweetAlertIcon } from '../../../shared/constants/enum';
@Component({
  selector: 'app-rp-users',
  templateUrl: './rp-users.component.html',
  styleUrl: './rp-users.component.scss',
  // encapsulation: ViewEncapsulation.None 

})
export class RpUsersComponent {
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public selectableSettings!: SelectableSettings;
  queryString: string = '';
  orderByQuery: string = '';
  searchedValue: any = '';
  allUsersList: any[] = [];
  duplicateUsersList: any = [];
  displaygrid = true;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  constructor(
    private loaderService: LoaderService,
    private store: Store,
    private router: Router,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService,
    private cdr: ChangeDetectorRef
  ) {
    this.isFilter = localStorage.getItem('filter');
    this.getUsersStoreInfo();
  }
  public usersState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  breadCrumbItems: Array<{}> | undefined;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  usersByIdList: any;
  usersList: any[] = [];
  totalUserCount: number = 0;
  currentPage: number = 1;
  public pageSize = 10;
  public skip = 0;
  public usergridData: GridDataResult = { data: [], total: 0 };

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  //public pageSizes = true;
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;

  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  public onFilter(value: any): void {
    // search
    // this.searchedValue = value;
    // const inputValue = value;
    this.userPageChange({ skip: 0, take: this.pageSize })
    this.store.dispatch(
      getuserlist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  createUser() {
    localStorage.removeItem('filter');
    this.router.navigateByUrl('/users/createUser');
    //outerLink="/users/createUser"
  }
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  isFilter: any
  ngOnInit(): void {
    this.selectableSettings = this.sharedService.selectableSettings;
    this.breadCrumbItems = [{ label: 'Users' }];

    if (this.isFilter == 'true') {
      this.filter = this.sharedService.getFilter();
      this.usersState.filter = this.filter;
      console.log(this.filter)
      console.log(this.usersState)
      this.queryString = this.sharedService.createQuery(this.usersState);
    }
    this.loaderService.setLoading(true);
    this.getUsersData();
  }

  public onValueChange(ev: string): void {

    if (ev.length == 0) {
      this.userPageChange({ skip: 0, take: this.pageSize })
      this.getUsersData();
    }
  }

  onSearchUsers(): void {
    if (this.searchedValue.length > 0) {
      this.getUsersData();
    }

  }

  private exportSubscription: Subscription = new Subscription();
  getUsersStoreInfo() {
    let getUsers$ = this.store.pipe(select(getUsersResponse));
    this.exportSubscription = this.store
      .pipe(select(getAllUserResponse))
      .subscribe((res: any) => {
        this.loaderService.setLoading(false);
        if (res) {
          console.log(res)
          this.allUsersList = [];
          if (res.items.length > 0) {
            this.allUsersList = [...res.items];
            // let exportuserData = this.allUsersList.map(
            //   ({ id, roleId, project, ...rest }) => rest
            // );
            const exportuserData = this.allUsersList.map((item: any) => ({
              'First Name': item.firstName,
              'Last Name': item.lastName,
              'Project': item.projectName,
              'Email': item.email,
              // 'Mobile Number': item.mobile,
              'Mobile Number': this.addcountryCode(item.mobile, item.countryCode),

              'Role': item.role,
              'Status': item.status ? 'Active' : 'Inactive', // Transform boolean status to readable format
            }));
            this.PdfexportService.downloadExcel(exportuserData, 'user');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
          console.log('allusersListL', res, this.allUsersList);
        }
      });
    let getuserrStatusResponse$ = this.store.pipe(
      select(getuserStatusResponse)
    );

    getUsers$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.usersList = [...res.items];
        this.duplicateUsersList = [...this.usersList];
        this.pageSize = res.pageSize;
        this.totalUserCount = res.totalCount;
        this.loadGridTable();
      }
      console.log('usersListL', res, this.usersList);
    });
    getuserrStatusResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        let updatedData = this.usersList.map((user: any) => {
          if (user.id === res.id) {
            return { ...user, status: res.status }; // Update the user's status
          }
          return user; // Return the user unchanged
        });
        this.usersList = updatedData;
        this.loadGridTable();
        console.log('usersByIdList', res, updatedData);
        console.log('usersByIdList', res, this.usersByIdList);
      }
    });
  }

  addcountryCode(item1: any, item2: any) {
    return item2 + item1
  }

  ngOnDestroy() {
    this.store.dispatch(resetState());
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }

  loadGridTable() {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    // Process the data and extract the required properties
    const processedData = process(this.usersList, state);

    // Assign processed data to usergridData
    this.usergridData = {
      data: processedData.data, // Extract the data array from the result
      total: this.totalUserCount, // Extract the total count from the result
    };
    this.usergridData = {
      data: processedData.data.map(item => ({
        ...item,
        statusUpdate: item.status ? 'Active' : 'Inactive', // Create the new key based on status
        //  mobile: item.countryCode + item.mobile
        countryMobile: item.countryCode + item.mobile
      })),
      total: this.totalUserCount, // Extract the total count from the result
    };

    this.loaderService.setLoading(false);
  }

  onUsersFilterChange(filter: CompositeFilterDescriptor): void {
    this.usersState.filter = filter;
    console.log(this.usersState);
    this.skip = 0
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);
    const queryString = this.sharedService.createQuery(this.usersState);
    this.queryString = this.sharedService.createQuery(this.usersState);
    this.store.dispatch(
      getuserlist({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
    console.log(queryString);
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.usersState.sort = sort;
    console.log(this.usersState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.usersState.sort
    );

    this.orderByQuery = orderByQuery;

    this.store.dispatch(
      getuserlist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  getUsersData() {
    this.store.dispatch(
      getuserlist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  callAllUsers() {
    this.store.dispatch(
      getAlluserlist({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }

  protected userPageChange({ skip, take }: PageChangeEvent): void {
    this.selectedRowdata = [];
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getuserlist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
    this.loadGridTable();
  }

  mySelection: any[] = [];

  public isRowSelected = (rowArg: any) => {
    return this.mySelection.some((row: any) => row.id === rowArg.id);
  };

  public selectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelection.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelection.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isRowSelected(row.dataItem)) {
        this.mySelection.push(row.dataItem);
      }
    });

    console.log(this.mySelection);
  }

  ActivationChange(event: any, dataItem: any): void {
    let status = dataItem.status === true ? 'Inactive' : 'Active';
    const originalChecked = dataItem.status;
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        allowOutsideClick: false,
        // title: 'user Active/Inactive',
        text: `Are you sure you want to ${status} this user?`,
        icon: 'warning',
        confirmButtonText: 'Yes, Continue',
        cancelButtonText: 'No, Cancel',
        showCancelButton: true,
        // timer: 20000, // close automatically after timeout
        //timerProgressBar: true,
      })
      .then((result) => {
        console.log(result, 'result');
        if (result.value) {
          this.store.dispatch(
            getUserStstusChnage({ id: dataItem.id, status: event })
          );
        }
        if (result.dismiss === Swal.DismissReason.cancel) {
          this.loaderService.setLoading(true);
          this.displaygrid = false;
          setTimeout(() => {
            this.displaygrid = true;
            this.loaderService.setLoading(false);
          });
        }
      });
  }
  AllExport() {
    this.callAllUsers();
  }
  public onExport(type: string) {
    if (this.usergridData.data.length > 0) {
      if (type === 'Excel') {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      }
      else {
        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
    /*     if (this.selectedRowdata.length == 0) {
          this.callAllUsers();
        } else {
          if (type === 'Excel') {
            setTimeout(() => {
              this.hiddenGrid?.saveAsExcel();
            }, 1000);
          } else if (type === 'Pdf') {
            setTimeout(() => {
              this.hiddenGrid?.saveAsPDF();
            }, 1000);
          }
        } */
  }

  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  onSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }
  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGridTable();
  }

  /* Edit User Event */
  editUser(data: any) {
    let getUsersById$ = this.store.pipe(select(getUsersResponse));
    getUsersById$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.usersByIdList = [...res.items];
      }
      console.log('mastersList', res, this.usersList);
    });

    console.log(data);
    this.router.navigate(['/users/createUser'], { state: { userData: data } });
  }

  /* Delete Recorde Event */
  deleteMaster(dataItem: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        console.log(result.value);
        if (result.value) {
          this.store.dispatch(deleteuserlist({ id: dataItem.id }));
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          /* swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          ); */
        }
      });
  }
}
