import { Helper } from './../../../core/helpers/helper';
import { ProjectData } from './../../../shared/modals/projectModal';
import { SharedService } from './../../../shared/shared.service';
import { LoaderService } from './../../../core/services/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { RemoveEvent } from '@progress/kendo-angular-upload';

import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { process, State } from '@progress/kendo-data-query';
import { gatewayData } from './gateway-data';
import { MonitorData } from './monitor-data';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';

import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Location } from '@angular/common';
import { RpCreateMonitoringComponent } from './rp-Create-Monitoring/rp-Create-Monitoring.component';
import { RpCreateGatewayComponent } from './rp-create-gateway/rp-create-gateway.component';
import {
  getGatewayList,
  deleteGateWay,
  getAllGatewayList,
} from '../../../store/GateWay/gateway.action';
import {
  creategatewayResponse,
  deleteGateWayResponse,
  getAllGateway_Response,
  getGatewayExportResponse,
  getGateWayResponse,
  updateGateWayResponse,
} from '../../../store/GateWay/gateway-selector';
import {
  GetAllMonitoring,
  GetMonitoring,
  deleteMonitoring,
  resetState,
} from '../../../store/Monitoring/monitoring.actions';
import {
  createMonitoringResponse,
  getAllMonitoringResponse,
  getMonitoringResponse,
  updateMonitoringResponse,
} from '../../../store/Monitoring/monitoring-selector';
import { Monitoring } from '../../../store/Monitoring/monitoring.models';
import { deleteMonitoringResponse } from '../../../store/Monitoring/monitoring-selector';
import { Roles, SweetAlertIcon, UtilityType } from '../../../shared/constants/enum';
import { ProjectsService } from '../../../core/services/projects.services';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { projectBillingDates, projectDueDates } from '../../../shared/constants/projectBillingDates';
import {
  getProject,
  resetUpdateProjectResponse,
  updateProject,
} from '../../../store/Projects/projects.actions';
import { getProjectDataSuccessResponse, updateProjectResponse } from '../../../store/Projects/projects-selector';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { Subscription } from 'rxjs';
import { getGatewayExport } from '../../../store/GateWay/gateway.action';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Validations } from '../../../shared/constants/validations';
@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrl: './project-details.component.scss',
})
export class ProjectDetailsComponent implements OnInit {
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  @ViewChild('cdkStepper') stepper: CdkStepper | undefined;
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  @ViewChild('pdfGrid_2') public hiddenGrid_2: GridComponent | undefined;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public gateWayState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  billingInitiatedStatus = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];
  public monitoringDeviceState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  UtilityType = UtilityType;
  //gateway:
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;

  public gatewaygridData: GridDataResult = { data: [], total: 0 };
  public monitoringGridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public sort: SortDescriptor[] = [];
  totalProjectCount: number = 0;
  currentPage: number = 1;
  gatewaypage: number = 1
  gatewayList: any[] = [];
  selectValue!: string[];

  public gridData: unknown[] = gatewayData;
  public gridView!: unknown[];

  public gridDataMonitor: unknown[] = MonitorData;
  public gridMonitorView!: unknown[];
  projectDetails!: ProjectData;
  configData: any;
  projectForm!: UntypedFormGroup;
  monitoringList: any = [];
  totalGateWayCount: number = 0;
  totalMonitoringCount: number = 0;
  currentUserData: any;
  roles = Roles;
  projectName: string | undefined;
  orderByQueryOfGatewat: string = '';
  gateWayQueryString: string = '';
  gateWaySearch: any = '';
  monitoringyQueryString: string = '';
  MonitoringOrderByQuery: string = '';
  monitoringSearch: any = '';
  allGatewayList: any = [];
  allMonitoringList: any = [];
  monitoring_pageSize: number = 10;
  breadCrumbItems: Array<{}> | undefined;
  projectBillingDates = projectBillingDates;
  submitted: boolean = false;
  currentUser: any;
  projectValidations = Validations.Project_ValidationMessages;

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint

  projectDueDates = projectDueDates;
  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private store: Store,
    private sharedService: SharedService,
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private PdfexportService: PdfexportService
  ) {
    this.getAllConfigData();
    this.breadCrumbItems = [
      { label: 'Project', url: 'projects', active: true },
      { label: 'Project Details' },
    ];

    this.projectForm = this.formBuilder.group({
      project_Name: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
          Helper.PatternValidator(/^[a-zA-Z0-9\s\-_&]*$/),
        ],
      ],
      project_Code: ['', [Helper.PatternValidator(/^[a-zA-Z0-9]*$/)]],
      SelectedUtilityIds: [null, Validators.required],
      projectType: [null, Validators.required],
      region: [null, Validators.required],
      address: ['', Validators.required],
      area: [
        '',
        [Validators.required, Helper.PatternValidator(/^[a-zA-Z\s]*$/)],
      ],
      city: [null, Validators.required],
      state: [null, Validators.required],
      pincode: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Helper.PatternValidator(/^[0-9]*$/),
        ],
      ],
      society_name: [
        '',
        [
          Validators.minLength(2),
          Validators.maxLength(20),
          Helper.PatternValidator(/^[a-zA-Z\s]*$/),
        ],
      ],
      ValveCutOffDate: [''],
      RelayCutOffDate: [''],
      merchantId: [
        '',
        [],
      ],
      GSTTINO: [
        '',
        [],
      ],
      accountName: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z\s]*$/)],
      ],
      accountNumber: [
        '',
        [Helper.PatternValidator(/^[0-9]*$/)],
      ],
      bankName: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z\s]*$/)],
      ],
      branchName: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z\s]*$/)],
      ],
      ifscCode: [
        '',
        [Helper.PatternValidator(/^[a-zA-Z0-9]*$/)],
      ],
      StartDate: [null],
      contactNumber: [''],
      Email: [],
      account_address: [''],
      LogoFile: [''],
      cancelled_Cheque_file: [{ value: '', disabled: true }],
      Status: [null, Validators.required],
      IsBillingInitiated: [
        { value: false, },
        Validators.required
      ],
      billingType: [{ value: '', disabled: true }, Validators.required],
      IsWalletEnabled: [{ value: false, disabled: false }],
      BillGenerationTypeId: [null, Validators.required],
      minRechargeAmount: [
        '',
        [Validators.required, Helper.numberWithDecimalsValidator()],
      ],
      minWalletBalance: [
        '',
        [Validators.required, Helper.numberWithDecimalsValidator()],
      ],
      valveCutofflimit: [
        '',
        [, Helper.numberWithDecimalsValidator()],
      ],
      relayCutofflimit: [
        '',
        [, Helper.numberWithDecimalsValidator()],
      ],
      maxRechargeAmount: [
        '',
        [Validators.required, Helper.numberWithDecimalsValidator()],
      ],
      billDate: [{ value: '', disabled: true }, Validators.required],
      DueDate: [{ value: '', disabled: true }],
      requireIndependentDatabase: [false],
      db_password: [{ value: '', disabled: true }, Validators.required],
      db_serverName: [
        { value: '', disabled: true },
        [Validators.required, Helper.PatternValidator(/^[a-zA-Z0-9]*$/)],
      ],
      db_userName: [
        { value: '', disabled: true },
        [Validators.required, Helper.PatternValidator(/^[a-zA-Z0-9]*$/)],
      ],
      gasProvider: [null,],
      electricityProvider: [null,],
      waterProvider: [null,],
      bluetoothDeviceIncluded: [false],
      meterMenu: [false],
      emailNotifications: [false],
      smsNotifications: [false],      
      IsDisplayOldBills:[false],
      meterOnOff: [false],
      IsBillGenerateForEmptyResidentialUnit: [false],
      isGIReadable: [true, Validators.required],
      waterConsumptionLimit: ['', [Helper.numberWithDecimalsValidator(), Validators.required]],
      gasConsumptionLimit: ['', [Helper.numberWithDecimalsValidator(), Validators.required]],
      electricityDGConsumptionLimit: ['', [Helper.numberWithDecimalsValidator(), Validators.required]],
      electricityEBConsumptionLimit: ['', [Helper.numberWithDecimalsValidator(), Validators.required]],
    });


    // this.route.queryParams.subscribe((params: any) => {
    if (history.state.projectDetails) {
      this.projectDetails = JSON.parse(history.state.projectDetails);
      console.log(' this.projectDetails ', this.projectDetails);
      console.log(this.projectDetails.billingType)
      this.projectId = this.projectDetails.id;
      this.projectName = this.projectDetails.name;
      const formattedBillDate = this.formatDate(this.projectDetails.billDate);

      let logo_url = this.projectDetails?.logoUrl
        ? [
          {
            name: this.projectDetails.logoFileName,
            src: this.projectDetails.logoUrl,
            size: this.projectDetails.logoFileSize,
            uid: 1,
          },
        ]
        : '';

      let chequFile_url = this.projectDetails?.societyHead?.fileUrl
        ? [
          {
            name: this.projectDetails?.societyHead?.fileName,
            src: this.projectDetails.societyHead.fileUrl,
            size: this.projectDetails?.societyHead?.fileSize,
            uid: 1,
          },
        ]
        : '';
      if (this.projectDetails.isBillingInitiated == true) {
        this.projectForm.controls['IsBillingInitiated'].disable();
      }
      this.projectForm.patchValue({
        project_Name: this.projectDetails?.name,
        project_Code: this.projectDetails.code,
        projectType: this.projectDetails.projectType?.id,
        region: this.projectDetails.region?.id,
        address: this.projectDetails.address,
        area: this.projectDetails.area,
        state: this.projectDetails.state?.id,
        city: this.projectDetails.city?.id,
        Status: this.projectDetails.status,
        IsBillingInitiated: this.projectDetails?.isBillingInitiated,
        pincode: this.projectDetails.pinCode,

        ValveCutOffDate: this.projectDetails?.valveCutOffDate,
        RelayCutOffDate: this.projectDetails?.relayCutOffDate,
        SelectedUtilityIds: this.projectDetails.selectedUtilities.map(
          (item: any) => item.id
        ),
        society_name: this.projectDetails.societyHead?.name,
        merchantId: this.projectDetails.societyHead?.merchantId,
        GSTTINO: this.projectDetails?.gsttino,
        accountName: this.projectDetails.societyHead?.accountName,
        accountNumber: this.projectDetails.societyHead?.accountNumber,
        bankName: this.projectDetails.societyHead?.bankName,
        branchName: this.projectDetails.societyHead?.branchName,
        ifscCode: this.projectDetails.societyHead?.ifscCode,
        account_address: this.projectDetails.address,
        LogoFile: logo_url,
        cancelled_Cheque_file: chequFile_url,
        contactNumber: this.projectDetails?.contactNumber,
        Email: this.projectDetails?.email,
        StartDate: this.projectDetails?.startDate != null ? this.projectDetails?.startDate : null,
        BillGenerationTypeId: this.projectDetails.billGenerationType?.id,
        billingType: this.projectDetails.billingType?.id,
        minRechargeAmount: this.projectDetails.minRechargeAmount,
        minWalletBalance: this.projectDetails.minWalletBalance,
        valveCutofflimit: this.projectDetails.valveCutofflimit,
        relayCutofflimit: this.projectDetails.relayCutofflimit,
        maxRechargeAmount: this.projectDetails?.maxRechargeAmount,
        billDate: this.projectDetails?.billDate,
        DueDate: this.projectDetails?.dueDate,
        gasProvider: this.projectDetails?.gasProviders?.map(
          (item: any) => item.id
        ),
        electricityProvider: this.projectDetails?.electricityProviders?.map(
          (item: any) => item.id
        ),
        waterProvider: this.projectDetails?.waterProviders?.map(
          (item: any) => item.id
        ),
        bluetoothDeviceIncluded: this.projectDetails.bluetoothDeviceIncluded,
        meterMenu: this.projectDetails.meterMenu,
        emailNotifications: this.projectDetails.emailNotifications,
        smsNotifications: this.projectDetails.smsNotifications,        
      IsDisplayOldBills:this.projectDetails.isDisplayOldBills,
        isGIReadable: this.projectDetails.isGIReadable,
        IsWalletEnabled: this.projectDetails.isWalletEnabled,
        meterOnOff: this.projectDetails?.isMeterOnOff,
        IsBillGenerateForEmptyResidentialUnit: this.projectDetails.isBillGenerateForEmptyResidentialUnit,
        waterConsumptionLimit: this.projectDetails?.waterConsumptionLimit,
        gasConsumptionLimit: this.projectDetails?.gasConsumptionLimit,
        electricityDGConsumptionLimit: this.projectDetails?.electricityDGConsumptionLimit,
        electricityEBConsumptionLimit: this.projectDetails?.electricityEBConsumptionLimit,
      });

      const billingType = this.projectForm.get('billingType');
      const minWalletBalance = this.projectForm.get('minWalletBalance');
      const valveCutofflimit = this.projectForm.get('valveCutofflimit');
      const minRechargeAmount = this.projectForm.get('minRechargeAmount');
      const maxRechargeAmount = this.projectForm.get('maxRechargeAmount');
      const relayCutOfflimit = this.projectForm.get('relayCutofflimit');
      const billDate = this.projectForm.get('billDate');
      const gasProvider = this.projectForm.get('gasProvider');
      const electricityProvider = this.projectForm.get('electricityProvider');
      const waterProvider = this.projectForm.get('waterProvider');
      const emailNotifications = this.projectForm.get('emailNotifications');
      const smsNotifications = this.projectForm.get('smsNotifications');

      const ValveCutOffDate = this.projectForm.get('ValveCutOffDate');
      const RelayCutOffDate = this.projectForm.get('RelayCutOffDate');

      billingType?.disable();
      // minWalletBalance?.disable();
      // valveCutofflimit?.disable();
      // minRechargeAmount?.disable();
      // maxRechargeAmount?.disable();
      billDate?.disable();
      // gasProvider?.disable();
      // electricityProvider?.disable();
      // waterProvider?.disable();
      // emailNotifications?.disable();
      // smsNotifications?.disable();
      if (this.projectDetails?.billingType?.name == 'Postpaid') {
        minWalletBalance?.disable();
        valveCutofflimit?.disable();
        minRechargeAmount?.disable();
        maxRechargeAmount?.disable();
        relayCutOfflimit?.disable();
        ValveCutOffDate?.setValidators([Validators.required]);
        RelayCutOffDate?.setValidators([Validators.required]);
        ValveCutOffDate?.updateValueAndValidity();
        RelayCutOffDate?.updateValueAndValidity();
      }
      // this.updateValidators();
      // if()
    }
    // });
    this.getStoreInfo();
    this.selectableSettings = this.sharedService.selectableSettings;
  }
  public pageSize = 10;
  public skip = 0;

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  get selectedUtilityNames(): string {
    return this.projectDetails?.selectedUtilities
      ? this.projectDetails.selectedUtilities
        .map((utility: any) => utility.name)
        .join(', ')
      : '';
  }
  public onFilter(value: Event): void {
    const inputValue = value;

    this.dataBinding.skip = 0;
  }
  public ongatewayFilter(value: any): void {
    this.gatewayPageChange({ skip: 0, take: this.pageSize })
    this.getAllGatewatList();
  }

  onStepChange(event: StepperSelectionEvent): void {
    const selectedIndex = event.selectedIndex;
    console.log('Selected step index:', selectedIndex);

    // Perform any action based on the selected index
    if (selectedIndex === 0) {
      this.getCurrentUserRegisterdProjectData();
      // Code for when step 0 is selected
    }
  }

  onMonitoringFilter(value: any): void {
    // const inputValue = value;
    // this.monitoringSearch = value;
    this.monitoringPageChange({ skip: 0, take: this.monitoring_pageSize })
    this.getAllMonitoringList();
  }

  isImage(fileUrl: string): boolean {
    const ext = fileUrl.split('.').pop()?.toLowerCase();
    return ext === 'jpg' || ext === 'jpeg' || ext === 'png';
  }


  downloadFile(file: any, filename: any) {
    this.sharedService.downloadUtilityFiles(file, filename);
  }

  isImageFile(fileName: string | undefined): boolean {
    if (!fileName) {
      return false; // Return false if fileName is undefined
    }
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    return imageExtensions.includes(fileExtension || '');
  }

  public onRemove(event: RemoveEvent): void {
    //console.log('File removed:', event.files);
    console.log(this.projectDetails.logoUrl != undefined)
    if (this.projectDetails && this.projectDetails.logoUrl != undefined) {
      this.projectDetails = { ...this.projectDetails, logoUrl: '' };

      // console.log(this.projectDetails)
      // console.log(this.projectDetails.logoUrl)
    }
  }


  updateValidators() {
    const valveCutofflimitControl = this.projectForm.get('valveCutofflimit');
    if (this.isUtilityDisplay(UtilityType.GAS)) {
      // If the utility is displayed, ensure it has the required validator
      valveCutofflimitControl!.setValidators([Validators.required, Helper.numberWithDecimalsValidator()]);
    } else {
      // If the utility is not displayed, remove the required validator
      valveCutofflimitControl!.clearValidators();
    }

    // Update the validity of the control
    valveCutofflimitControl!.updateValueAndValidity();

    const relayCutofflimitControl = this.projectForm.get('relayCutofflimit');

    if (this.isUtilityDisplay(UtilityType.ELECTRICITY)) {
      // If the utility is displayed, ensure it has the required validator
      relayCutofflimitControl!.setValidators([Validators.required, Helper.numberWithDecimalsValidator()]);
    } else {
      // If the utility is not displayed, remove the required validator
      relayCutofflimitControl!.clearValidators();
    }

    // Update the validity of the control
    relayCutofflimitControl!.updateValueAndValidity();

  }


  isDisplay(key: string): boolean {
    const selectedUtilities =
      this.projectForm.get('SelectedUtilityIds')?.value || [];
    return selectedUtilities.some((utility: any) => {
      const matchingUtility = this.configData?.data?.utilities.find(
        (u: any) => u.id === utility
      );
      return matchingUtility?.name === key;
    });
  }

  deleteGateway(dataItem: any) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(deleteGateWay({ id: dataItem.id }));

          /* swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          ); */
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            '',
            SweetAlertIcon.INFO
          );
        }
      });
  }

  onGatewaySortChange(sort: SortDescriptor[]): void {
    this.gateWayState.sort = sort;
    // console.log(this.gateWayState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.gateWayState.sort
    );
    this.orderByQueryOfGatewat = orderByQuery;
    this.getAllGatewatList();
  }

  public onGateWayFilterChange(filter: CompositeFilterDescriptor): void {
    this.gateWayState.filter = filter;
    console.log(this.gateWayState);
    this.gatewaypage = 1;
    this.skip = 0
    const queryString = this.sharedService.createQuery(this.gateWayState);
    this.gateWayQueryString = this.sharedService.createQuery(this.gateWayState);
    this.getAllGatewatList();
    console.log(queryString);
  }

  onMonitoringFilterChange(filter: CompositeFilterDescriptor): void {
    this.monitoringDeviceState.filter = filter;
    console.log(this.monitoringDeviceState);
    this.skip = 0
    const queryString = this.sharedService.createQuery(
      this.monitoringDeviceState
    );
    this.currentPage = 1
    this.monitoringyQueryString = this.sharedService.createQuery(
      this.monitoringDeviceState
    );
    this.getAllMonitoringList();

    console.log(queryString);
  }

  onMonitoringSortChange(sort: SortDescriptor[]): void {
    this.monitoringDeviceState.sort = sort;
    console.log(this.monitoringDeviceState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.monitoringDeviceState.sort
    );
    this.MonitoringOrderByQuery = orderByQuery;
    this.getAllMonitoringList();
  }

  deleteMonitor(dataItem: Monitoring) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: SweetAlertIcon.WARNING,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(
            deleteMonitoring({ id: dataItem.id ? dataItem?.id : '' })
          );

          // swalWithBootstrapButtons.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            '',
            SweetAlertIcon.INFO
          );
        }
      });
  }

  /**
   * Open center modal
   * @param addGatewayModal center modal data
   */
  addGatewayModal(addGatewayModal: any) {
    this.modalRef = this.modalService.show(addGatewayModal);
  }

  projectId: any;
  ngOnInit(): void {

    this.validatingFormBasedOnUtility()

    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.gridView = this.gridData;
    this.gridMonitorView = this.gridDataMonitor;


    this.getUserInfo();
    if (this.currentUserData.currentUserRoleDetails.role == Roles.ADMIN) {
      this.getCurrentUserRegisterdProjectData();
      this.breadCrumbItems = [{ label: 'Project Details' }];
    } else {
      this.breadCrumbItems = [
        { label: 'Project', url: 'projects', active: true },
        { label: 'Project Details' },
      ];
    }
  }


  validatingFormBasedOnUtility() {
    this.projectForm
      .get('SelectedUtilityIds')
      ?.valueChanges.subscribe((selectedUtilities) => {
        // Reset validators
        this.projectForm.get('valveCutofflimit')?.clearValidators();
        // this.projectForm.get('ValveCutofflimit')?.reset();
        this.projectForm.get('relayCutofflimit')?.clearValidators();
        // this.projectForm.get('RelayCutofflimit')?.reset();
        this.projectForm.get('gasProvider')?.clearValidators();
        // this.projectForm.get('gasProvider')?.reset();
        this.projectForm.get('electricityProvider')?.clearValidators();
        // this.projectForm.get('electricityProvider')?.reset();
        this.projectForm.get('waterProvider')?.clearValidators();
        // this.projectForm.get('waterProvider')?.reset();

        if (selectedUtilities) {
          console.log(this.configData?.data?.utilities.some(
            (utility: any) =>
              selectedUtilities.includes(utility.id) &&
              utility.name === 'Electricity'
          ));

          if (
            this.configData?.data?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'Electricity'
            )
          ) {
            this.projectForm
              .get('relayCutofflimit')
              ?.setValidators([
                Validators.required,
                Helper.numberWithDecimalsValidator(),
              ]);
            this.projectForm
              .get('electricityProvider')
              ?.setValidators([Validators.required]);
          }
          if (
            this.configData?.data?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) && utility.name === 'Gas'
            )
          ) {
            this.projectForm
              .get('valveCutofflimit')
              ?.setValidators([
                Validators.required,
                Helper.numberWithDecimalsValidator(),
              ]);
            this.projectForm
              .get('gasProvider')
              ?.setValidators([Validators.required]);
          }
          if (
            this.configData?.data?.utilities.some(
              (utility: any) =>
                selectedUtilities.includes(utility.id) &&
                utility.name === 'Water'
            )
          ) {
            this.projectForm
              .get('waterProvider')
              ?.setValidators([Validators.required]);
          }
        }

        //Update the validity status of the form controls
        this.projectForm.get('valveCutofflimit')?.updateValueAndValidity();
        this.projectForm.get('relayCutofflimit')?.updateValueAndValidity();
        this.projectForm.get('gasProvider')?.updateValueAndValidity();
        this.projectForm.get('electricityProvider')?.updateValueAndValidity();
        this.projectForm.get('waterProvider')?.updateValueAndValidity();
      });
  }


  isUploading: boolean = false;


  public onUpload(): void {
    this.isUploading = true;
  }

  public onSuccess(): void {
    this.isUploading = false;
  }


  ngAfterViewInit(): void {
    const savedStepIndex = localStorage.getItem('selectedTabIndex');
    if (savedStepIndex !== null && this.stepper) {
      this.stepper.selectedIndex = +savedStepIndex;  // Convert string to number
      localStorage.removeItem('selectedTabIndex');  // Optionally clear it after use
    }
  }

  isUtilityDisplay(type: string): boolean {
    const utility = this.currentUser?.projectUtilities?.find(
      (u: any) => u.name === type
    );
    return !!utility; // Returns true if 'Electricity' is found, false otherwise
  }
  billType: any
  getCurrentUserRegisterdProjectData() {
    this.store.dispatch(getProject({ projectId: this.currentUserData.project.id }));
  }
  getCurrentUserRegisterdProjects() {
    this.projectsService
      .gerProjectBasedOnProjectId(this.currentUserData.project.id)
      .subscribe((project) => {
        if (project.statusCode == 200) {
          console.log(project.data)
          console.log(project.billingType)
          this.projectDetails = project.data;
          this.projectId = this.projectDetails.id;
          const formattedBillDate = this.formatDate(
            this.projectDetails.billDate
          );

          let logo_url = this.projectDetails?.logoUrl
            ? [
              {
                name: this.projectDetails.logoFileName,
                src: this.projectDetails.logoUrl,
                size: this.projectDetails.logoFileSize,
                uid: 1,
              },
            ]
            : '';

          let chequFile_url = this.projectDetails?.societyHead?.fileUrl
            ? [
              {
                name: this.projectDetails?.societyHead?.fileName,
                src: this.projectDetails.societyHead.fileUrl,
                size: this.projectDetails?.societyHead?.fileSize,
                uid: 1,
              },
            ]
            : '';

          if (this.projectDetails.isBillingInitiated == true) {
            this.projectForm.controls['IsBillingInitiated'].disable();
          }
          this.projectForm.patchValue({
            project_Name: this.projectDetails.name,
            project_Code: this.projectDetails.code,
            pincode: this.projectDetails.pinCode,
            projectType: this.projectDetails.projectType?.id,
            region: this.projectDetails.region?.id,
            address: this.projectDetails.address,
            area: this.projectDetails.area,
            state: this.projectDetails.state?.id,
            city: this.projectDetails.city?.id,
            Status: this.projectDetails.status,
            IsBillingInitiated: this.projectDetails?.isBillingInitiated,

            ValveCutOffDate: this.projectDetails?.valveCutOffDate,
            RelayCutOffDate: this.projectDetails?.relayCutOffDate,
            SelectedUtilityIds: this.projectDetails.selectedUtilities.map(
              (item: any) => item.id
            ),
            society_name: this.projectDetails.societyHead?.name,

            contactNumber: this.projectDetails.contactNumber,
            Email: this.projectDetails?.email,
            GSTTINO: this.projectDetails?.gsttino,
            merchantId: this.projectDetails.societyHead?.merchantId,
            accountName: this.projectDetails.societyHead?.accountName,
            accountNumber: this.projectDetails.societyHead?.accountNumber,
            bankName: this.projectDetails.societyHead?.bankName,
            branchName: this.projectDetails.societyHead?.branchName,
            ifscCode: this.projectDetails.societyHead?.ifscCode,
            account_address: this.projectDetails.address,
            LogoFile: logo_url,
            cancelled_Cheque_file: chequFile_url,
            BillGenerationTypeId: this.projectDetails.billGenerationType?.id,
            billingType: this.projectDetails.billingType?.id,
            minRechargeAmount: this.projectDetails.minRechargeAmount,
            minWalletBalance: this.projectDetails.minWalletBalance,
            maxRechargeAmount: this.projectDetails.maxRechargeAmount,
            billDate: this.projectDetails.billDate,
            DueDate: this.projectDetails?.dueDate,
            StartDate: this.projectDetails?.startDate != null ? this.projectDetails?.startDate : null,
            gasProvider: this.projectDetails?.gasProviders?.map(
              (item: any) => item.id
            ),
            electricityProvider: this.projectDetails?.electricityProviders?.map(
              (item: any) => item.id
            ),
            waterProvider: this.projectDetails?.waterProviders?.map(
              (item: any) => item.id
            ),
            bluetoothDeviceIncluded:
              this.projectDetails.bluetoothDeviceIncluded,
            meterMenu: this.projectDetails.meterMenu,
            emailNotifications: this.projectDetails.emailNotifications,
            smsNotifications: this.projectDetails.smsNotifications,                  
             IsDisplayOldBills:this.projectDetails.isDisplayOldBills,
            isGIReadable: this.projectDetails.isGIReadable,
            IsBillGenerateForEmptyResidentialUnit: this.projectDetails.isBillGenerateForEmptyResidentialUnit,
            IsWalletEnabled: this.projectDetails.isWalletEnabled,
            relayCutofflimit: this.projectDetails.relayCutofflimit,
            valveCutofflimit: this.projectDetails.valveCutofflimit,
            meterOnOff: this.projectDetails?.isMeterOnOff,
            waterConsumptionLimit: this.projectDetails?.waterConsumptionLimit,
            gasConsumptionLimit: this.projectDetails?.gasConsumptionLimit,
            electricityDGConsumptionLimit: this.projectDetails?.electricityDGConsumptionLimit,
            electricityEBConsumptionLimit: this.projectDetails?.electricityEBConsumptionLimit,
          });

          this.getAllGatewatList();
          this.getAllMonitoringList();
        }
        const minWalletBalance = this.projectForm.get('minWalletBalance');
        const valveCutofflimit = this.projectForm.get('valveCutofflimit');
        const minRechargeAmount = this.projectForm.get('minRechargeAmount');
        const maxRechargeAmount = this.projectForm.get('maxRechargeAmount');
        const relayCutOfflimit = this.projectForm.get('relayCutofflimit');
        if (this.projectDetails?.billingType?.name == 'Postpaid') {
          minWalletBalance?.disable();
          valveCutofflimit?.disable();
          minRechargeAmount?.disable();
          maxRechargeAmount?.disable();
          relayCutOfflimit?.disable();
        }
      });
  }
  getUserInfo() {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      // Parse the data to a JavaScript object
      this.currentUserData = JSON.parse(currentUserString);
      console.log('###########333', this.currentUserData);
      // if (this.currentUserData.project == null) {
      //   this.currentUserData.project = {};
      //   this.currentUserData.project.id = this.projectId;
      //   this.currentUserData.project.name = this.projectName;
      //   localStorage.setItem(
      //     'currentUser',
      //     JSON.stringify(this.currentUserData)
      //   );
      // }
    }
  }

  formatDate(isoString: any) {
    const date = new Date(isoString);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }



  formatBillingDate = (date: any): string => {
    if (date && typeof date === 'string') {
      return this.getOrdinalSuffix(date) + ' of every month';
    }
    return date;
  };

  formatDueDate = (date: any): string => {
    return this.projectDueDates.find((item) => item.id === Number(date))?.name || 'N/A';
  };



  getOrdinalSuffix = (day: any): string => {
    if (
      day === '2 days before calendar' ||
      day === '1 day before calendar' ||
      day === 'Last day of the month'
    ) {
      return day;
    }

    if (day === '1' || day === '21' || day === '31') return `${day}st`;
    if (day === '2' || day === '22') return `${day}nd`;
    if (day === '3' || day === '23') return `${day}rd`;
    return `${day}th`;
  };

  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data;
      }
    });
  }
  private exportSubscription: Subscription = new Subscription();
  gatewayResponse: any[] = [];
  getStoreInfo() {
    this.store.dispatch(resetState());
    let getGateway$ = this.store.pipe(select(getGateWayResponse));
    let deletegateway$ = this.store.pipe(select(deleteGateWayResponse));
    let getMonitoringResponse$ = this.store.pipe(select(getMonitoringResponse));
    let creategatewayResponse$ = this.store.pipe(select(creategatewayResponse));
    let getProjectDataSuccessResponse$ = this.store.pipe(select(getProjectDataSuccessResponse));


    this.exportSubscription = this.store
      .pipe(select(getGatewayExportResponse))
      .subscribe((res: any) => {
        if (res) {
          if (res.items.length > 0) {
            const transformedData = res.items.map((item: any) => ({
              GatewaySerialNumber: item.serialNumber,
              Gateway: item.gatewayProvider.name,

              // sttaus: item.isActive,
            }));
            this.PdfexportService.downloadExcel(transformedData, 'gateway');
            this.loaderService.setLoading(false);
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });
    let updateMonitoringResponse$ = this.store.pipe(
      select(updateMonitoringResponse)
    );
    let updategateWayResponse$ = this.store.pipe(select(updateGateWayResponse));

    let deleteMonitoringResponse$ = this.store.pipe(
      select(deleteMonitoringResponse)
    );

    let createMonitoringResponse$ = this.store.pipe(
      select(createMonitoringResponse)
    );
    let getAllGateway_Response$ = this.store.pipe(
      select(getAllGateway_Response)
    );

    this.exportSubscription = this.store
      .pipe(select(getAllMonitoringResponse))
      .subscribe((res: any) => {
        if (res) {
          this.loaderService.setLoading(false);
          if (res.items.length > 0) {
            this.allMonitoringList = [...res.items];
            const transformedData = this.allMonitoringList.map((item: any) => ({
              Utility: item.utility.name,
              MeterNumber: item.meterNumber,
            }));
            this.PdfexportService.downloadExcel(transformedData, 'monitoring');
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    let updateProjectResponse$ = this.store.pipe(select(updateProjectResponse));

    updateProjectResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        console.log(res);
        this.getCurrentUserRegisterdProjectData();
      }
    });

    getProjectDataSuccessResponse$.subscribe((project: any) => {
      this.loaderService.setLoading(false);
      if (project) {

        //console.log(project);
        if (project.statusCode == 200) {
          this.projectDetails = project.data;
          this.projectId = this.projectDetails.id;
          // console.log(this.projectDetails.billingType)
          const formattedBillDate = this.formatDate(
            this.projectDetails.billDate
          );

          let logo_url = this.projectDetails?.logoUrl
            ? [
              {
                name: this.projectDetails.logoFileName,
                src: this.projectDetails.logoUrl,
                size: this.projectDetails.logoFileSize,
                uid: 1,
              },
            ]
            : '';

          let chequFile_url = this.projectDetails?.societyHead?.fileUrl
            ? [
              {
                name: this.projectDetails?.societyHead?.fileName,
                src: this.projectDetails.societyHead.fileUrl,
                size: this.projectDetails?.societyHead?.fileSize,
                uid: 1,
              },
            ]
            : '';
          if (this.projectDetails.isBillingInitiated == true) {
            this.projectForm.controls['IsBillingInitiated'].disable();
          }
          this.projectForm.patchValue({
            project_Name: this.projectDetails.name,
            project_Code: this.projectDetails.code,
            pincode: this.projectDetails.pinCode,
            projectType: this.projectDetails.projectType?.id,
            region: this.projectDetails.region?.id,
            address: this.projectDetails.address,
            contactNumber: this.projectDetails.contactNumber,
            Email: this.projectDetails?.email,
            area: this.projectDetails.area,
            state: this.projectDetails.state?.id,
            city: this.projectDetails.city?.id,
            Status: this.projectDetails.status,

            IsBillingInitiated: this.projectDetails?.isBillingInitiated,
            StartDate: this.projectDetails?.startDate != null ? this.projectDetails?.startDate : null,
            ValveCutOffDate: this.projectDetails?.valveCutOffDate,
            RelayCutOffDate: this.projectDetails?.relayCutOffDate,
            SelectedUtilityIds: this.projectDetails.selectedUtilities.map(
              (item: any) => item.id
            ),
            society_name: this.projectDetails.societyHead?.name,
            GSTTINO: this.projectDetails?.gsttino,
            merchantId: this.projectDetails.societyHead?.merchantId,
            accountName: this.projectDetails.societyHead?.accountName,
            accountNumber: this.projectDetails.societyHead?.accountNumber,
            bankName: this.projectDetails.societyHead?.bankName,
            branchName: this.projectDetails.societyHead?.branchName,
            ifscCode: this.projectDetails.societyHead?.ifscCode,
            account_address: this.projectDetails.address,
            LogoFile: logo_url,
            cancelled_Cheque_file: chequFile_url,
            BillGenerationTypeId: this.projectDetails.billGenerationType?.id,
            billingType: this.projectDetails.billingType?.id,
            minRechargeAmount: this.projectDetails.minRechargeAmount,
            minWalletBalance: this.projectDetails.minWalletBalance,
            maxRechargeAmount: this.projectDetails.maxRechargeAmount,
            billDate: this.projectDetails.billDate,
            DueDate: this.projectDetails?.dueDate,
            gasProvider: this.projectDetails?.gasProviders?.map(
              (item: any) => item.id
            ),
            electricityProvider: this.projectDetails?.electricityProviders?.map(
              (item: any) => item.id
            ),
            waterProvider: this.projectDetails?.waterProviders?.map(
              (item: any) => item.id
            ),
            bluetoothDeviceIncluded:
              this.projectDetails.bluetoothDeviceIncluded,
            meterMenu: this.projectDetails.meterMenu,
            emailNotifications: this.projectDetails.emailNotifications,
            smsNotifications: this.projectDetails.smsNotifications,      
            IsDisplayOldBills:this.projectDetails.isDisplayOldBills,
            isGIReadable: this.projectDetails.isGIReadable,
            IsBillGenerateForEmptyResidentialUnit: this.projectDetails.isBillGenerateForEmptyResidentialUnit,
            IsWalletEnabled: this.projectDetails.isWalletEnabled,
            relayCutofflimit: this.projectDetails.relayCutofflimit,
            valveCutofflimit: this.projectDetails.valveCutofflimit,
            meterOnOff: this.projectDetails?.isMeterOnOff,
            waterConsumptionLimit: this.projectDetails?.waterConsumptionLimit,
            gasConsumptionLimit: this.projectDetails?.gasConsumptionLimit,
            electricityDGConsumptionLimit: this.projectDetails?.electricityDGConsumptionLimit,
            electricityEBConsumptionLimit: this.projectDetails?.electricityEBConsumptionLimit,
          });

          this.getAllGatewatList();
          this.getAllMonitoringList();
          const minWalletBalance = this.projectForm.get('minWalletBalance');
          const valveCutofflimit = this.projectForm.get('valveCutofflimit');
          const minRechargeAmount = this.projectForm.get('minRechargeAmount');
          const maxRechargeAmount = this.projectForm.get('maxRechargeAmount');
          const relayCutOfflimit = this.projectForm.get('relayCutofflimit');
          if (this.projectDetails?.billingType?.name == 'Postpaid') {
            minWalletBalance?.disable();
            valveCutofflimit?.disable();
            minRechargeAmount?.disable();
            maxRechargeAmount?.disable();
            relayCutOfflimit?.disable();
          }
        }
      }
    });



    getGateway$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.gatewayList = res.items;
        this.pageSize = res.pageSize;
        this.totalGateWayCount = res.totalCount;
      } else {
        this.gatewayList = [];
      }
      this.loadGatewayTable();
      console.log('gatewayList-->', res, this.gatewayList);
    });

    getAllGateway_Response$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.allGatewayList = res.items;
      }
      console.log('gatewayList-->', res, this.gatewayList);
    });

    deletegateway$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.getAllGatewatList();
        this.loadGatewayTable();
        console.log('projectsList', res, this.gatewayList);
      }
    });

    getMonitoringResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.monitoringList = res.items;
        // res.items.forEach((item: any) => {
        //   this.monitoringList.push({
        //     id: item.id,
        //     utilityId: item.utility.id,
        //     name: item.utility.name,
        //     meterNumber: item.meterNumber,
        //   });
        // });
        //this.monitoringList = [...res.items];
        //this.gatewayList = [...res.items];
        this.monitoring_pageSize = res.pageSize;
        this.totalMonitoringCount = res.totalCount;
      } else {
        this.monitoringList = [];
      }
      this.loadMonitoringTable();
      console.log('gatewayList-->', res, this.monitoringList);
    });

    updategateWayResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.getAllGatewatList();
      }
      console.log('update Monitoring', res);
    });

    createMonitoringResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.getAllMonitoringList();
      }
      console.log('create Monitoring', res);
    });

    updateMonitoringResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.getAllMonitoringList();
      }
      console.log('update Monitoring', res);
    });

    deleteMonitoringResponse$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.getAllMonitoringList();
      }
      console.log('deleting Monitoring', res);
    });
  }

  public gatewatValueChange(ev: string): void {
    if (ev.length == 0) {
      this.gatewayPageChange({ skip: 0, take: this.pageSize })
      this.getAllGatewatList();
    }
  }

  onSearchGateWayDevices(): void {
    if (this.gateWaySearch.length > 0) {
      this.getAllGatewatList();
    }
  }

  public monitoringValueChange(ev: string): void {

    if (ev.length == 0) {
      this.monitoringPageChange({ skip: 0, take: this.monitoring_pageSize })
      this.getAllMonitoringList();
    }
  }
  onSearchMonitoringDevices(): void {
    if (this.monitoringSearch.length > 0) {
      this.getAllMonitoringList();
    }
  }

  ngOnDestroy() {
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }

  private loadGatewayTable(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.gatewayList, state);

    this.gatewaygridData = {
      data: processedData.data,
      total: this.totalGateWayCount,
    };
  }

  protected gatewayPageChange({ skip, take }: PageChangeEvent): void {
    this.selectedRowdata = [];
    console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.gatewaypage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getAllGatewatList();
    this.loadGatewayTable();
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGatewayTable();
  }

  getAllGatewatList() {
    this.store.dispatch(
      getGatewayList({
        pageIndex: this.gatewaypage,
        pageSize: this.pageSize,
        id: this.projectId,
        searchTerm: this.gateWaySearch,
        filter: this.gateWayQueryString,
        orderBy: this.orderByQueryOfGatewat,
      })
    );
  }
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  getTotalGatewatList() {
    this.store.dispatch(
      getAllGatewayList({
        pageIndex: 1,
        pageSize: this.totalGateWayCount,
        id: this.projectId,
        searchTerm: this.gateWaySearch,
        filter: this.gateWayQueryString,
        orderBy: this.orderByQueryOfGatewat,
      })
    );
  }

  addNewGateWay() {
    let projectId = this.projectId;
    this.modalRef = this.modalService.show(RpCreateGatewayComponent, {
      initialState: { projectId },
      ...this.config,
    });
  }
  editGateWay(data: any) {
    let gatewayUpdateData = data;
    this.modalRef = this.modalService.show(RpCreateGatewayComponent, {
      initialState: { gatewayUpdateData },
      ...this.config,
    });
  }
  addMoniterModal() {
    let projectId = this.projectId;
    this.modalRef = this.modalService.show(RpCreateMonitoringComponent, {
      initialState: { projectId },
      ...this.config,
    });
  }

  getAllMonitoringList() {
    this.store.dispatch(
      GetMonitoring({
        pageIndex: this.currentPage,
        pageSize: this.monitoring_pageSize,
        projectid: this.projectId,
        searchTerm: this.monitoringSearch,
        filter: this.monitoringyQueryString,
        orderBy: this.MonitoringOrderByQuery,
      })
    );
  }

  getTotalMonitoringList() {
    this.store.dispatch(
      GetAllMonitoring({
        pageIndex: 1,
        pageSize: undefined,
        projectid: this.projectId,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }
  exportGatewayAll() {
    this.store.dispatch(
      getGatewayExport({
        pageIndex: 1,
        pageSize: undefined,
        id: this.projectId,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }

  protected monitoringPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.MonitoringselectedRowdata = [];
    this.skip = skip;
    this.monitoring_pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getAllMonitoringList();
    this.loadMonitoringTable();
  }

  protected supplierDataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadMonitoringTable();
  }

  private loadMonitoringTable(): void {
    const state: State = {
      take: this.monitoring_pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.monitoringList, state);

    this.monitoringGridData = {
      data: processedData.data,
      total: this.totalMonitoringCount,
    };
  }

  updateMonitoring(data: Monitoring) {
    let monitoringData = data;
    this.modalRef = this.modalService.show(RpCreateMonitoringComponent, {
      initialState: { monitoringData },
      ...this.config,
    });
  }

  gotoMappedList(dataItem: any) {
    if (this.stepper) {
      localStorage.setItem('selectedTabIndex', this.stepper.selectedIndex.toString());
    }
    const serializedData = JSON.stringify(dataItem);
    this.router.navigate(['/projectDetails/mapping'], {
      state: { monitoringData: serializedData },
    });
  }
  MonitoringselectedRowdata: any[] = [];
  monitoringDeselectedRowdata: any = [];
  /* monitoringOnSelect */
  monitoringOnSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.MonitoringselectedRowdata.push(element.dataItem);
        console.log(
          ' MonitoringselectedRowdata Values : ',
          this.MonitoringselectedRowdata
        );
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.monitoringDeselectedRowdata.push(element.dataItem);
        console.log(
          'deselectedRowdata Values : ',
          this.monitoringDeselectedRowdata
        );
      });

      if (this.monitoringDeselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.monitoringDeselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.MonitoringselectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.MonitoringselectedRowdata = filteredArray;
      }
    }
  }

  /* gateway on Select */
  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  gatewayOnSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }

  public gatewayonExport(type: string) {
    // if (this.selectedRowdata.length == 0) {
    //   this.getTotalGatewatList();
    // }
    if (type === 'Excel') {
      if (this.gatewaygridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
  exportAll() {
    this.getTotalMonitoringList();
  }

  public monitoringonExport(type: string) {
    // if (this.MonitoringselectedRowdata.length == 0) {
    //   this.getTotalMonitoringList();
    // }
    if (type === 'Excel') {
      if (this.monitoringGridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid_2?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid_2?.saveAsPDF();
      }, 1000);
    }
  }
  parsing(data: any): number {
    return parseInt(data, 10);
  }
  findInvalidControls(form: any): { [key: string]: any }[] {
    const invalidControls = [];
    const controls = form.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        invalidControls.push({
          controlName: name,
          controlErrors: controls[name].errors,
        });
      }
    }

    return invalidControls;
  }


  updateProject() {
    const invalidControls = this.findInvalidControls(this.projectForm);
    console.log('Invalid controls:', invalidControls);
    console.log(this.projectForm?.value);
    this.submitted = true;
    if (this.projectForm.valid) {
      if (
        this.parsing(this.projectForm.value.minRechargeAmount) >
        this.parsing(this.projectForm.value.minRechargeAmount)
      ) {
        return;
      }
      this.submitted = false;
      this.loaderService.setLoading(true);
      // Process form data (send to backend, etc.)
      console.log(this.projectForm.value);

      console.log(this.projectForm.value);
      if (this.projectDetails.id) {
        this.store.dispatch(
          updateProject({
            projectData: this.projectForm.getRawValue(),
            id: this.projectDetails.id,
          })
        );
      }
    }
  }
}
