import { SharedService } from './../../../shared/shared.service';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { Store, select } from '@ngrx/store';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import {
  getAllResidentialUnit_Response,
  getResidentialUnitResponse,
} from '../../../store/residential-unit/residentialUnit-selector';
import { LoaderService } from '../../../core/services/loader.service';
import {
  getAllResidentialUnitlist,
  getResidentialUnitlist,
  resetState,
} from '../../../store/residential-unit/residentialUnit.action';

import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';

import {
  table_pageSize,
  Templates,
} from '../../../shared/constants/templatesNames';
import { RpBulkUpoadComponent } from '../../../shared/components/rp-bulkupload/rp-bulkupload.component';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { GridFilterServiceService } from '../../../core/services/grid-filter-service.service';
@Component({
  selector: 'app-rp-residential-unit',
  templateUrl: './rp-residential-unit.component.html',
  styleUrl: './rp-residential-unit.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class RpResidentialUnitComponent {
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;

  modalRef!: BsModalRef;
  public gridData: unknown[] = [];
  public gridView!: unknown[];

  public residentialState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public pageSize = 10;
  public skip = 0;
  breadCrumbItems: Array<{}> | undefined;
  totalUserCount: number = 0;
  currentPage: number = 1;
  public residentialunitgridData: GridDataResult = { data: [], total: 0 };
  residentilaunitList: any[] = [];
  currentUserData: any;
  projectId: any;
  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public mode: SelectableMode = 'multiple';
  public drag = false;
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  //public pageSizes = true;
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  filterableOptions = {
    showOperators: true,
    operator: 'contains', // or any other default operator
    operators: {
      string: [
        { text: 'Contains', value: 'contains' },
        { text: 'Does not contain', value: 'doesnotcontain' },
        // add more operators as needed
      ]
    }
  };
  // file upload

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  orderByQuery: string = '';
  queryString: string = '';
  searchedResidentialunit: any = '';
  allResidentilaunitList: any[] = [];
  residentialunitRequest = Object.assign({});
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private PdfexportService: PdfexportService,
    private gridFilterServiceService: GridFilterServiceService
  ) {
    const currentUserString = localStorage.getItem('currentUser');
    this.isFilter = localStorage.getItem('filter');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
    }
    if (history.state.residentialUnitRequest != undefined && history.state.residentialUnitRequest !== null) {
      this.residentialunitRequest = history.state.residentialUnitRequest;

    }
    this.breadCrumbItems = [{ label: 'Residential units' }];
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    this.getResidentailStoreInfo();
  }

  ngAfterViewInit(): void {
    // console.log(this.residentialunitRequest)


  }

  ngOnInit(): void {
    // console.log(this.sharedService.getFilter())
    // console.log(this.residentialunitRequest)

    //  console.log(this.isFilter)
    if (this.isFilter == 'true') {
      // console.log(this.sharedService.getFilter())
      this.filter = this.sharedService.getFilter();
      //  console.log(this.filter)
      this.residentialState.filter = this.filter;
      // console.log(this.filter)
      this.queryString = this.sharedService.createQuery(this.residentialState);
    } else {
      if (this.residentialunitRequest && typeof this.residentialunitRequest === 'object' && Object.keys(this.residentialunitRequest).length > 0) {
        let status = this.residentialunitRequest.residentialUnitStatus;
        console.log(status)
        if (status && (status.toLowerCase() === 'owner' || status.toLowerCase() === 'tenant')) {
          this.residentialState = {
            skip: 0,
            take: 10,
            filter: {
              logic: 'and',
              filters: [
                {
                  field: 'currentOccupiedPerson',
                  operator: 'eq',
                  value: status
                }
              ],
            },
          };
          if (status == 'Owner') {
            this.queryString = `$filter=currentOccupiedPerson eq '${status}'`;
          } else {
            this.queryString = `$filter=currentOccupiedPerson eq '${status}'`;
          }
          // this.queryString = `currentOccupiedPerson eq ${status}`;

          // console.log(this.queryString)

        } else {
          this.residentialState = {
            skip: 0,
            take: 10,
            filter: {
              logic: 'and',
              filters: [
                {
                  field: 'occupant',
                  operator: status == 'Occupied units' ? 'eq' : 'ne',
                  value: status,
                },
              ],
            },
          };
          if (status == 'Occupied units') {
            this.queryString = `$filter=occupant ne ''`;
          } else {
            this.queryString = `$filter=occupant eq ''`;
          }
        }
        //let Filterquery = status == 'Occupied units' ? `$filter: occupant eq ''` : `$filter: occupant ne  ' '`;

      }

    }
    this.getResidentialUnitlist();
  }
  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.residentialPageChange({ skip: 0, take: this.pageSize })
      this.getResidentialUnitlist()
    }
  }

  onSearchResidentialUnits(): void {
    if (this.searchedResidentialunit.length > 0) {
      this.getResidentialUnitlist()
    }
  }


  getResidentialUnitlist() {
    this.store.dispatch(
      getResidentialUnitlist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        projectId: this.projectId,
        searchTerm: this.searchedResidentialunit,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  getAllResidentialUnitlist() {
    this.store.dispatch(
      getAllResidentialUnitlist({
        pageIndex: 1,
        pageSize: undefined,
        projectId: this.projectId,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }

  onSortChange(sort: SortDescriptor[]): void {
    this.residentialState.sort = sort;
    console.log(this.residentialState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.residentialState.sort
    );

    this.orderByQuery = orderByQuery;

    this.getResidentialUnitlist();
  }

  openBulkUpload() {
    let pageData = {
      title: Templates.RESIDENTIALUNITS.id,
      projectId: this.projectId,
    };
    this.modalRef = this.modalService.show(RpBulkUpoadComponent, {
      initialState: { pageData },
      ...this.config,
    });
    // this.modalRef = this.modalService.show(RpUserProfileComponent, { initialState: { profileData }, class: 'modal-lg' });

    this.modalRef.content.output.subscribe((response: any) => {
      console.log('Profile data received in header:', response);
      if (response == 'renderNewData') {
        this.getResidentialUnitlist();
      }
      // Handle the received profile data here
    });
  }

  openBulkUploadMeters() {
    let pageData = {
      title: Templates.RESIDENTIALUNITS.id,
      projectId: this.projectId,
      isFlag: 'metersBulkupload'

    };
    this.modalRef = this.modalService.show(RpBulkUpoadComponent, {
      initialState: { pageData },
      ...this.config,
    });

    this.modalRef.content.output.subscribe((response: any) => {
      console.log('Profile data received in header:', response);
      if (response == 'renderNewData') {
        this.getResidentialUnitlist();
      }

    });

  }
  openBulkUploadConsumers() {
    let pageData = {
      title: Templates.RESIDENTIALUNITS.id,
      projectId: this.projectId,
      isFlag: 'consumersBulkupload'

    };
    this.modalRef = this.modalService.show(RpBulkUpoadComponent, {
      initialState: { pageData },
      ...this.config,
    });

    this.modalRef.content.output.subscribe((response: any) => {
      console.log('Profile data received in header:', response);
      if (response == 'renderNewData') {
        this.getResidentialUnitlist();
      }

    });
  }



  isFilter: any
  public onresidentialFilterChange(filter: CompositeFilterDescriptor): void {
    this.residentialState.filter = filter;
    //  console.log(filter)
    this.currentPage = 1;
    this.skip = 0;
    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this.sharedService.setFilter(filter);
    // console.log(this.filter);
    const queryString = this.sharedService.createQuery(this.residentialState);
    this.queryString = this.sharedService.createQuery(this.residentialState);
    this.getResidentialUnitlist();
    // console.log(queryString);
  }
  exportAll() {
    this.getAllResidentialUnitlist();
  }
  public onExport(type: string) {
    if (type === 'Excel') {
      if (this.residentialunitgridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }
  private exportSubscription: Subscription = new Subscription();
  private getResidentialUnit: Subscription = new Subscription();

  getResidentailStoreInfo() {
    this.store.dispatch(resetState());

    this.getResidentialUnit = this.store
      .pipe(select(getResidentialUnitResponse))
      .subscribe((res: any) => {
        if (res) {
          this.loaderService.setLoading(false);
          this.residentilaunitList = [...res.items];
          this.pageSize = res.pageSize;
          this.totalUserCount = res.totalCount;
          this.loadGridTable();
        }
        console.log('consumerList', res, this.residentilaunitList);
      });

    this.exportSubscription = this.store
      .pipe(select(getAllResidentialUnit_Response))
      .subscribe((res: any) => {
        if (res) {
          this.allResidentilaunitList = [];
          if (res.items.length > 0) {
            this.allResidentilaunitList = [...res.items];
            const transformedData = this.allResidentilaunitList.map(
              (item: any) => ({
                'Flat / House No': item.flatNo,
                'Floor': item.floor,
                'Tower': item.tower,
                'Block': item.block,
                'Occupant': item.occupant,
                'current Occupied Person': item.currentOccupiedPerson
              })
            );
            this.PdfexportService.downloadExcel(
              transformedData,
              'residentialUnit'
            );
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });
  }

  ngOnDestroy() {
    let filter: CompositeFilterDescriptor = {
      filters: [],
      logic: 'and'
    };
    //  this.sharedService.setFilter(filter);
    this.allResidentilaunitList = [];
    this.exportSubscription.unsubscribe();
    this.getResidentialUnit.unsubscribe(); // Or set it to [] if you prefer an empty array
  }

  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.residentilaunitList, state);
    this.residentialunitgridData = {
      data: this.residentilaunitList,
      total: this.totalUserCount,
    };
    this.loaderService.setLoading(false);
  }

  protected residentialPageChange({ skip, take }: PageChangeEvent): void {
    //console.log('>>>', skip, take);
    this.selectedRowdata = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getResidentialUnitlist();

    this.loadGridTable();
  }

  protected residentialStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGridTable();
  }

  public onFilter(value: any): void {
    this.residentialPageChange({ skip: 0, take: this.pageSize })
    // this.getResidentialUnitlist();
  }

  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  onSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }

  deleteUnit() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }

  /**
   * Open center modal
   * @param bulkUploadModal
   */
  bulkUploadModal(bulkUploadModal: any) {
    this.modalRef = this.modalService.show(bulkUploadModal);
  }

  residentilaunitEdit(dataItem: any): void {
    let data = dataItem;
    this.router.navigate(['/residentialUnit/createResidentialUnit'], {
      state: { residentialData: data },
    });
  }
}
