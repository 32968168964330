import { Component, ViewChild } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { rechargeData } from './recharge-data';
import { walletData } from './wallet-data';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { saveAs } from 'file-saver';

import * as XLSX from 'xlsx';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../../shared/shared.service';
import { Router } from '@angular/router';
import {
  getAllRechargeHistoryResponse,
  getAllWalletHistoryResponse,
  getWalletHistoryResponse,
  getrechargeHistoryResponse,
  walletPyByCashResponse,
} from '../../../store/Wallet/wallet-selector';
import {
  getWalletHistorylist,
  getRechargeHistorylist,
  getAllWalletHistorylist,
  getAllRechargeHistorylist,
  resetState,
  getwalletPyByCash,
} from '../../../store/Wallet/wallet.action';
import { formatDate } from '@angular/common';  // <-- Import formatDate from common
import {
  process,
  State,
  toODataString,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Helper } from '../../../core/helpers/helper';

import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Validations } from '../../../shared/constants/validations';
import { WalletService } from '../../../core/services/wallet.services';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-rp-wallet-details',
  templateUrl: './rp-wallet-details.component.html',
  styleUrl: './rp-wallet-details.component.scss',
})
export class RpWalletDetailsComponent {
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  prifileValidations = Validations;
  selectValue!: string[];
  breadCrumbItems: Array<{}> | undefined;
  public gridData: unknown[] = rechargeData;
  public gridView!: unknown[];

  public gridDataWallet: unknown[] = walletData;
  public gridWalletView!: unknown[];
  walletDetails: any[] = [];
  getWalletHistoryList: any = [];

  public pageSize = 10;
  public wallet_historyPageSize = 10;
  public skip = 0;
  totalwallethistoryCount: number = 0;
  datePickerConfig: Partial<BsDatepickerConfig>;
  currentPage: number = 1;
  public sort: SortDescriptor[] = [];
  public walletHistorygridData: GridDataResult = { data: [], total: 0 };
  public rechargeHistorygridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public rechargeHisoryState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public walletHisoryState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  currentUser: any;
  walletId: any;
  mySelectionWallet: any = [];
  mySelectionRecharge: any = [];
  @ViewChild('walletGrid') public hiddenwalletGrid: GridComponent | undefined;
  @ViewChild('recharge') public hiddenrechargeGrid: GridComponent | undefined;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  getRechargeHistoryList: any = [];
  rechargepageSize = 0;
  totalrechargehistoryCount = 0;
  rechargeQueryString: string = '';
  rechargeOrderByQuery: string = '';
  rechargeSearch: any = '';
  walletQueryString: string = '';
  walletOrderByQuery: string = '';
  walletSearch: any = '';
  getAllWalletHistoryList: any[] = [];
  getAllRechargeHistoryList: any[] = [];
  public paybycashform!: FormGroup;
  currentUserData: any;
  regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
  projectId: any;
  max_amount: any;
  min_amount: any;
  billingType: any
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private store: Store,
    private sharedService: SharedService,
    private router: Router,
    private walletService: WalletService,
    private PdfexportService: PdfexportService
  ) {
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
      }
    );

    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    this.projectId = this.currentUserData?.project.id;
    this.billingType = this.currentUserData?.project?.billingType;
    let walletTemp = this.sharedService.getWalletByIdDetails();
    if (Object.keys(walletTemp).length > 0) {
      this.walletDetails = [];
      this.walletDetails.push(walletTemp);
      console.log(this.walletDetails)
      this.getRechargeHistory();
      this.sharedService.setwalletHistoryById(this.walletDetails[0].id);
      this.max_amount = this.walletDetails[0].maxRechargeAmount;
      this.min_amount = this.walletDetails[0].minRechargeAmount;
      this.paybycashform = this.formBuilder.group({
        amount: ['', [Validators.required, Validators.pattern(this.regexp), Validators.min(this.min_amount), Validators.max(this.max_amount)]],
        reason: ['', [Validators.required]],
      });

      this.getStoreInfo();
      this.breadCrumbItems = [
        { label: 'Wallet', url: 'wallet', active: true },
        {
          label: 'Wallet Details',
        },
      ];
    }

    // });


    /* if (state && state['walletViewDetails']) {
      this.walletDetails = state['walletViewDetails']; // Use state if available
    } */

    /* if (state && state['walletViewDetails']) {
      this.walletDetails = [];
      this.walletDetails.push(state['walletViewDetails']);
      console.log(this.walletDetails)
      this.getRechargeHistory();
      this.sharedService.setwalletHistoryById(this.walletDetails[0].id);
      this.max_amount = this.walletDetails[0].maxRechargeAmount;
      this.min_amount = this.walletDetails[0].minRechargeAmount;
      this.paybycashform = this.formBuilder.group({
        amount: ['', [Validators.required, Validators.pattern(this.regexp), Validators.min(this.min_amount), Validators.max(this.max_amount)]],
        reason: ['', [Validators.required]],
      });

      this.getStoreInfo();
      this.breadCrumbItems = [
        { label: 'Wallet', url: 'wallet', active: true },
        {
          label: 'Wallet Details',
        },
      ];
    } */

  }
  getStoreInfo() {
    let getWalletHistoryResponse$ = this.store.pipe(
      select(getWalletHistoryResponse)
    );

    let walletPyByCashResponse$ = this.store.pipe(
      select(walletPyByCashResponse)
    );

    let getAllRechargeHistoryResponse$ = this.store.pipe(
      select(getAllRechargeHistoryResponse)
    );
    let getAllWalletHistoryResponse$ = this.store.pipe(
      select(getAllWalletHistoryResponse)
    );

    let getrechargeHistoryResponse$ = this.store.pipe(
      select(getrechargeHistoryResponse)
    );
    getWalletHistoryResponse$.subscribe((res: any) => {
      if (res) {
        this.getWalletHistoryList = [...res.items];
        this.wallet_historyPageSize = res.pageSize;
        this.totalwallethistoryCount = res.totalCount;
      }
      this.loadWalletHistory();
      console.log('wallets hisotyr list', res);
    });

    walletPyByCashResponse$.subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.walletDetails = this.walletDetails.map((item: any) => ({
          ...item,
          currentBalance: res?.currentBalance,
          residentialUnit: res?.residentialUnit,
          consumer: res?.consumer,
          lastRechargeAmount: res?.lastRechargeAmount
        }));
        this.getRechargeHistory()

        //this.walletDetails = {};
        /*  this.walletDetails.currentBalance = res?.currentBalance
         this.walletDetails.residentialUnit = res?.residentialUnit
         this.walletDetails.consumer = res?.consumer
         this.walletDetails.lastRechargeAmount = res?.lastRechargeAmount */

        //this.walletDetails = res;
        // console.log(this.walletDetails);
      }
      // this.loadWalletHistory();
      console.log('wallets hisotyr list', res);
    });
    getAllWalletHistoryResponse$.subscribe((res: any) => {
      if (res) {
        this.getAllWalletHistoryList = [...res.items];
        //console.log(this.getAllWalletHistoryList)

        console.log(this.getAllWalletHistoryList)
        if (this.billingType == 'Postpaid') {
          this.billHistoryExport(this.getAllWalletHistoryList)
        } else {
          this.export(this.getAllWalletHistoryList);
        }

        /*  this.getAllWalletHistoryList = [...res.items];
         if (this.getAllWalletHistoryList.length > 0) {
           const transformedData = this.getAllWalletHistoryList.map(
             (item: any) => ({
               'Residential Unit': item.residentialUnit,
               Utility: item.utility,
               'Meter Number': item.meterNo, 
               Consumption: item.consumption, 
               'Unit Rate': item.unitRate, 
             })
           );
           this.PdfexportService.downloadExcel(
             transformedData,
             'wallets hisoty'
           );
         } else {
           this.sharedService.showMessageDialog(
             'No data to export',
             '',
             SweetAlertIcon.ERROR
           );
         } */
      }
      console.log('all wallet hisotyr list', res);
    });

    getrechargeHistoryResponse$.subscribe((res: any) => {
      if (res) {
        this.getRechargeHistoryList = [...res.items];
        this.rechargepageSize = res.pageSize;
        this.totalrechargehistoryCount = res.totalCount;
      }
      this.loadRechargeHistory();
      console.log('wallets hisotyr list', res);
    });
    getAllRechargeHistoryResponse$.subscribe((res: any) => {
      if (res) {
        this.getAllRechargeHistoryList = [...res.items];
        if (this.getAllRechargeHistoryList.length > 0) {
          const transformedData = this.getAllRechargeHistoryList.map(
            (item: any) => ({
              'OrderId': item.orderId,
              Date: this.sharedService.dateFormateChnage(item.date),
              'Recharge Status': item.rechargeStatus, // Placeholder for region.name
              'Reason': item.reason, // Placeholder for status
              'Recharge Amount': item.rechargeAmount, // Placeholder for bill date
              'Current Balance': item.currentBalance,
              'Payment Reference Number': item.paymentRefNumber,
            })
          );
          this.PdfexportService.downloadExcel(
            transformedData,
            'Recharge Hisoty'
          );
        } else {
          this.sharedService.showMessageDialog(
            'No data to export',
            '',
            SweetAlertIcon.ERROR
          );
        }
      }
      console.log('getAllRechargeHistoryResponse hisotyr list', res);
    });

    //walletPyByCashResponse
  }

  dateFormateChnage(inputDate: any) {
    const date = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour format with AM/PM
    };
    const formattedDate = date.toLocaleString('en-GB', options).replace(',', '').replace(' ', ' ');
    return formattedDate
  }

  billHistoryExport(data: any): void {

    const workbook = XLSX.utils.book_new();
    const masterDetailData: any[] = [];
    if (data.length > 0) {
      data.forEach(
        (item: any) => {
          masterDetailData.push({
            'Date': formatDate(item.paidDate, 'dd MMM yyyy', 'en-US'),
            'Amount Deducted': item.amountDeducted,
            "Opening Balance": item.openingBalance,
            "Closeing Balance": item.closingBalance,
            'Residential Unit': '',
            'Bill No': '',
            'utility': '',
            'Device Sr No': '',
            'Mac Address': '',
            'Bill Date': '',
            'Payment Date': '',
            'Bill Aamount': '',
            'Mode Of Payment': '',
            'Payment Status': '',
          });

          item.walletDtls.forEach(
            (detail: any) => {
              masterDetailData.push({
                'Date': '',
                'Amount Deducted': '',
                "Opening Balance": '',
                "Closeing Balance": '',
                'Bill No': detail.billNo,
                'Residential Unit': detail.residentialUnit,
                'Bill Date': formatDate(detail.billDate, 'dd MMM yyyy hh:mm a', 'en-US'),
                'Payment Date': formatDate(detail.paymentDate, 'dd MMM yyyy hh:mm a', 'en-US'),
                'utility': detail.utility,
                'Device Sr No': detail.meterNo,
                'Mac Address': detail.macAddress,
                'Bill Aamount': detail.amount,
                'Mode Of Payment': detail.paymentMode,
                'Payment Status': detail.paymentStatus,
              });
            }
          );
        }
      );
      console.log(masterDetailData)
      const worksheet = XLSX.utils.json_to_sheet(masterDetailData, {

      });

      const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })];
        if (cell) {
          cell.s = { font: { bold: true } };
        }
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, 'WalletHistory');
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        'WalletHistory.xlsx'
      );
    } else {
      this.sharedService.showMessageDialog(
        'No data to export',
        '',
        SweetAlertIcon.ERROR
      );
    }
  }
  export(data: any): void {
    const workbook = XLSX.utils.book_new();
    const masterDetailData: any[] = [];
    if (data.length > 0) {
      data.forEach(
        (item: any) => {
          masterDetailData.push({
            'Date': item.paidDate ? formatDate(item.paidDate, 'dd MMM yyyy', 'en-US') : '',
            'Amount Deducted': item.totalAmountDeducted,
            'Opening Balance': item.openingBalance,
            "Closing Balance": item.closingBalance,
            // 'Residential Unit': '',
            // 'Bill No': '',
            'Utility': '',
            'Mac Address': '',
            'Meter No': '',
            'Consumption': '',
            'Consumption Unit EB': '',
            'Consumption Unit DG': '',
            'UnitRate': '',
            //'UnitRate EB': '',
            //'UnitRate DG': '',
            'Available Balance': '',
            'Initial Reading': '',
            'Final Reading': '',
            'Initial MeterReading EB': '',
            'Initial Meter Reading DG': '',
            'Final MeterReading EB': '',
            'Final Meter Reading DG': '',
            'Initial Reading Date': '',
            'Final Reading Date': '',
            'Service Name': '',
            'Gst Amount': '',
            'Gst percentage': '',
          });

          item.walletDtls.forEach(
            (detail: any) => {
              masterDetailData.push({
                'Date': '',
                'Amount Deducted': '',
                'Opening Balance': '',
                'Closing Balance': '',
                //'Residential Unit': detail.residentialUnit,
                // 'Bill No': detail.billNo,
                'Mac Address': detail.macAddress,
                'Utility': detail.utility,
                'Device Sr No': detail.macAddress,
                'Meter No': detail.meterNo,
                'Consumption': detail.consumptionUnits,
                'Consumption Unit EB': detail.consumptionUnitsEB != undefined ? detail.consumptionUnitsEB : '',
                'Consumption Unit DG': detail.consumptionUnitsDG != undefined ? detail.consumptionUnitsDG : '',
                'UnitRate': detail.unitRate,
                'UnitRate EB': detail.utility == 'Electricity' ? detail.unitRateEB : '-',
                'UnitRate DG': detail.utility == 'Electricity' ? detail.unitRateDG : '-',
                'Available Balance': detail.availableBalance,
                'Initial Reading': detail.initialMeterReading,
                'Final Reading': detail.finalMeterReading,
                'InitialMeter Reading EB': detail.initialMeterReadingEB,
                'Initial Meter Reading DG': detail.initialMeterReadingDG,
                'FinalMeter Reading EB': detail.finalMeterReadingEB,
                'FinalMeter Reading DG': detail.finalMeterReadingDG,
                'Initial Reading Date': formatDate(item.previousReadingDate, 'dd/MM/YYYY', 'en-US'),
                'Final Reading Date': formatDate(item.finalReadingDate, 'dd/MM/YYYY', 'en-US'),
                'service Name': detail.serviceName,
                'Gst Amount': detail.gstAmount,
                'Gst Percentage': detail.gstPer,
              });
            }
          );
        }
      );

      console.log(masterDetailData)
      const worksheet = XLSX.utils.json_to_sheet(masterDetailData, {

      });


      const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: col })];
        if (cell) {
          cell.s = { font: { bold: true } };
        }
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, 'WalletHistory');
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        'WalletHistory.xlsx'
      );
    } else {
      this.sharedService.showMessageDialog(
        'No data to export',
        '',
        SweetAlertIcon.ERROR
      );
    }
  }

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  submitted: boolean = false;
  rechargeByCash() {
    let amount = this.paybycashform.value.amount;
    let currentBalance = this.walletDetails[0].currentBalance;
    this.submitted = true;
    if (this.paybycashform.invalid) {
      return true;
    } else {
      let request = {
        amount: amount,
        reason: this.paybycashform.value.reason,
        consumerId: this.walletDetails[0].consumerId,
        projectId: this.projectId,
      };
      if (currentBalance < 0) {
        // Ensure the recharge amount is greater than or equal to the absolute value of the negative balance
        if (amount && amount >= Math.abs(currentBalance)) {
          // Proceed with the recharge operation
          // Add your logic here for recharge
          this.store.dispatch(getwalletPyByCash({ cashDeatils: request }));
          this.paybycashform.reset();
          this.submitted = false;

        } else {
          // Handle the case where the recharge amount is too small
          // You might want to show an error or validation message
          this.sharedService.showMessageDialog('Failure', 'Recharge amount must be greater than or equal to the negative Wallet balance.', SweetAlertIcon.ERROR);
          console.log('Recharge amount must be greater than or equal to the negative balance.');
        }
      } else {
        // Handle the case where the balance is positive or zero
        // Add your logic for positive balance
        this.store.dispatch(getwalletPyByCash({ cashDeatils: request }));
        this.paybycashform.reset();
        this.submitted = false;
      }

      // console.log(this.walletDetails);


      return;
    }
  }
  get paybycashformControls() {
    return this.paybycashform.controls;
  }
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }




  fromDate: any[] = [];
  filterBasedOnDate(flag: any) {
    if (flag == 0) {
      this.fromDate = [];
      this.rechargeQueryString = '';
    } else {
      if (this.fromDate.length === 2) {
        const startDate = this.fromDate[0];
        const endDate = this.fromDate[1];

        if (startDate && endDate) {
          // Format the dates to yyyy-MM-dd
          const formattedStartDate = startDate.toISOString().split('T')[0];
          const formattedEndDate = endDate.toISOString().split('T')[0];

          // Construct the filter query with only the date
          this.rechargeQueryString = `$filter=(date ge ${formattedStartDate} and date le ${formattedEndDate})`;
        } else {
          console.log('Invalid date range');
        }
      } else {
        console.log('Invalid date selection');
      }
    }

    this.getRechargeHistory();
  }


  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  clearDate() {
    this.fromDate = [];// Clear the selected date
  }


  rechargeExportAll() {
    this.getAllRechargeHistory();
  }
  walletsExportAll() {
    this.getAllWalletHistory();
  }
  ngOnDestroy() {
    this.store.dispatch(resetState());

    this.walletDetails = [];
  }
  ngOnInit(): void { }

  getWalletHistory(): void {

    this.store.dispatch(
      getWalletHistorylist({
        pageIndex: this.currentPage,
        pageSize: this.wallet_historyPageSize,
        searchTerm: this.walletSearch,
        filter: this.walletQueryString,
        orderBy: this.walletOrderByQuery,
        walletId: this.walletDetails[0].id,
      })
    );
  }

  getAllWalletHistory(): void {
    this.store.dispatch(
      getAllWalletHistorylist({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
        walletId: this.walletDetails[0].id,
      })
    );
  }

  getRechargeHistory(): void {
    this.store.dispatch(
      getRechargeHistorylist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.rechargeSearch,
        filter: this.rechargeQueryString,
        orderBy: this.rechargeOrderByQuery,
        walletId: this.walletDetails[0].id,
      })
    );
  }
  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.rechargeConfigPageChange({ skip: 0, take: this.pageSize })
      this.getRechargeHistory()
    }
  }
  onSearchRechargeHistory(): void {
    if (this.rechargeSearch.length > 0) {
      this.getRechargeHistory()
    }
  }

  getAllRechargeHistory(): void {
    this.store.dispatch(
      getAllRechargeHistorylist({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
        walletId: this.walletDetails[0].id,
      })
    );
  }

  private loadWalletHistory(): void {
    const state: State = {
      take: this.wallet_historyPageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.getWalletHistoryList, state);

    this.walletHistorygridData = {
      data: processedData.data,
      total: this.totalwallethistoryCount,
    };
  }

  private loadRechargeHistory(): void {
    const state: State = {
      take: this.rechargepageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.getRechargeHistoryList, state);

    this.rechargeHistorygridData = {
      data: processedData.data,
      total: this.totalrechargehistoryCount,
    };

    this.rechargeHistorygridData.data = this.rechargeHistorygridData.data.map((item: any) => {
      return {
        ...item,
        dateFormate: item.date != null ? this.sharedService.dateFormateChnage(item.date) : '-'
      }

    })
  }

  onWalletSortChange(sort: SortDescriptor[]): void {
    this.walletHisoryState.sort = sort;
    console.log(this.walletHisoryState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.walletHisoryState.sort
    );

    this.walletOrderByQuery = orderByQuery;

    this.getWalletHistory();
  }

  public onWalletHistoryFilterChange(filter: CompositeFilterDescriptor): void {
    this.walletHisoryState.filter = filter;
    console.log(this.walletHisoryState);
    const queryString = this.sharedService.createQuery(this.walletHisoryState);
    this.walletQueryString = this.sharedService.createQuery(
      this.walletHisoryState
    );
    this.getWalletHistory();
    console.log(queryString);
  }

  onRechargeSortChange(sort: SortDescriptor[]): void {
    this.rechargeHisoryState.sort = sort;
    console.log(this.rechargeHisoryState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.rechargeHisoryState.sort
    );

    this.rechargeOrderByQuery = orderByQuery;

    this.store.dispatch(
      getRechargeHistorylist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.rechargeSearch,
        filter: this.rechargeQueryString,
        orderBy: this.rechargeOrderByQuery,
        walletId: this.walletDetails[0].id,
      })
    );
  }

  public onRechargeHistoryFilterChange(
    filter: CompositeFilterDescriptor
  ): void {
    this.rechargeHisoryState.filter = filter;
    this.skip = 0
    console.log(this.rechargeHisoryState);
    const queryString = this.sharedService.createQuery(
      this.rechargeHisoryState
    );
    this.rechargeQueryString = this.sharedService.createQuery(
      this.rechargeHisoryState
    );
    this.store.dispatch(
      getRechargeHistorylist({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: this.rechargeSearch,
        filter: this.rechargeQueryString,
        orderBy: this.rechargeOrderByQuery,
        walletId: this.walletDetails[0].id,
      })
    );
    console.log(queryString);
  }

  public isMasterRowSelected = (rowArg: any) => {
    return this.mySelectionWallet.some((row: any) => row.id === rowArg.id);
  };

  public isRechargeRowSelected = (rowArg: any) => {
    return this.mySelectionRecharge.some((row: any) => row.id === rowArg.id);
  };

  public walletHistoryselectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionWallet.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionWallet.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isMasterRowSelected(row.dataItem)) {
        this.mySelectionWallet.push(row.dataItem);
      }
    });

    console.log(this.mySelectionWallet);
  }

  public rechargeHistoryselectionChanged(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionRecharge.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionRecharge.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isRechargeRowSelected(row.dataItem)) {
        this.mySelectionRecharge.push(row.dataItem);
      }
    });

    console.log(this.mySelectionRecharge);
  }

  private createQuery(state: State): string {
    // Implement this method to create a query string from the state object
    // This should convert the state to your API's query parameters
    // Example implementation
    const queryStr = `${toODataString(state)}&$count=true`;
    console.log(queryStr);

    let query = `skip=${state.skip}&take=${state.take}`;

    if (state.filter) {
      const filters = state.filter.filters
        .map((f) => {
          const field = (f as any).field;
          const value = (f as any).value;
          return `${field}=${value}`;
        })
        .join('&');
      query += `&${filters}`;
    }

    return query;
  }

  protected walletConfigPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.mySelectionWallet = [];
    this.skip = skip;
    this.wallet_historyPageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.getWalletHistory();
    this.loadWalletHistory();
  }

  protected rechargeConfigPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.mySelectionRecharge = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getRechargeHistorylist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.rechargeSearch,
        filter: this.rechargeQueryString,
        orderBy: this.rechargeOrderByQuery,
        walletId: this.walletDetails[0].id,
      })
    );
    this.loadRechargeHistory();
  }

  public onWalletDataSearchFilter(value: any): void {
    const inputValue = value;
    this.walletSearch = value;
    this.getWalletHistory();
  }

  public onRechargeDataSearchFilter(value: any): void {
    this.rechargeConfigPageChange({ skip: 0, take: this.pageSize })
    this.store.dispatch(
      getRechargeHistorylist({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.rechargeSearch,
        filter: this.rechargeQueryString,
        orderBy: this.rechargeOrderByQuery,
        walletId: this.walletDetails[0].id,
      })
    );
  }
  public onRechargeExport(type: string) {
    // if (this.mySelectionRecharge.length == 0) {
    //   this.getAllRechargeHistory();
    // }
    if (type === 'Excel') {
      if (this.rechargeHistorygridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenrechargeGrid?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.ERROR
        );
      }

    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenrechargeGrid?.saveAsPDF();
      }, 1000);
    }
  }
  public onPdfExport(type: string) {
    // if (this.mySelectionWallet.length == 0) {
    //   this.getAllWalletHistory();
    // }
    if (type === 'Excel') {
      setTimeout(() => {
        this.hiddenwalletGrid?.saveAsExcel();
      }, 1000);
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenwalletGrid?.saveAsPDF();
      }, 1000);
    }
  }

  protected walletDataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadWalletHistory();
  }
  protected rechargeDataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadRechargeHistory();
  }
}
