<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                <span class="breadcrumb m-0">
                    <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
                        <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
                    </button>
                </span>
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div class="page-title-right">
                    <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
                </div>
            </div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ng-template #template let-anchor>
                        <span>{{ anchor.nativeElement.innerText }}</span>
                    </ng-template>

                    <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                        (mouseover)="showTooltip($event)">
                        <kendo-grid *ngIf="displaygrid" [data]="consmergridData" scrollable="scrollable"
                            [navigable]="true" kendoGridFocusable [pageSize]="pageSize" [sortable]="true"
                            [selectable]="selectableSettings" [pageable]="{
                                                buttonCount: buttonCount,
                                                info: info,
                                                type: type,
                                                pageSizes: pageSizes,
                                                previousNext: previousNext
                                              }" [reorderable]="true" filterable="menu" [skip]="skip"
                            [filter]="consumersState.filter" [sort]="consumersState.sort"
                            [columnMenu]="{ filter: true }" (filterChange)="onprojectFilterChange($event)"
                            (sortChange)="onSortChange($event)" [resizable]="true"
                            (pageChange)="consumerPageChange($event)" (selectionChange)="onSelect($event)"
                            (dataStateChange)="consumerStateChange($event)">

                            <ng-template kendoGridToolbarTemplate>
                                <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                                    [(ngModel)]="searchedValue" (valueChange)="onFilter($event)"></kendo-textbox> -->
                                <div class="search_icon">
                                    <kendo-textbox [style.width.px]="250" [clearButton]="true"
                                        (valueChange)="onValueChange($event)" (keydown.enter)="onSearchConsumers()"
                                        [style.height.px]="35" [(ngModel)]="searchedValue"
                                        placeholder="Search in all columns...">
                                    </kendo-textbox>

                                    <button class="btn btn-primary btn-md border-left-none" (click)="onFilter($event)">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                <kendo-grid-spacer></kendo-grid-spacer>
                                <button class="btn btn-outline-primary btn-sm" (click)="openBulkUpload()">Bulk
                                    Upload </button>
                                <button class="btn btn-outline-primary btn-sm "
                                    [routerLink]="'/consumers/createConsumer'">Add
                                    Consumer </button>

                                <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                                    (click)="onExport('Excel')">
                                    <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                                </button>

                            </ng-template>

                            <!-- Pdf   start-->
                            <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                                <kendo-grid
                                    [kendoGridBinding]="selectedRowdata.length>0 ? selectedRowdata:consmergridData.data"
                                    #pdfGrid>
                                    <kendo-grid-column field="firstName" title="First Name"></kendo-grid-column>
                                    <kendo-grid-column field="lastName" title="Last Name"></kendo-grid-column>
                                    <kendo-grid-column field="countryMobile" title="Mobile Number"></kendo-grid-column>
                                    <kendo-grid-column field="status" title="Status"></kendo-grid-column>
                                    <kendo-grid-column field="primary_Email" title="Email"></kendo-grid-column>
                                    <kendo-grid-column field="multiflats"
                                        title="Occupied Residential Unit"></kendo-grid-column>


                                    <kendo-grid-excel fileName="consumer.xlsx"></kendo-grid-excel>
                                </kendo-grid>
                            </div>
                            <!-- Pdf Edrt-->

                            <kendo-grid-excel fileName="Categories.xlsx"
                                [fetchData]="selectedRowdata.length>0 ? selectedRowdata:consmergridData.data"></kendo-grid-excel>

                            <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="60"
                                class="no-tooltip"></kendo-grid-checkbox-column>

                            <!--  <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                                class="no-tooltip"></kendo-grid-checkbox-column> -->
                            <kendo-grid-column field="firstName" title="First Name">
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                                    </kendo-grid-string-filter-menu>
                                    <small style="color:brown">Search is case-sensitive.</small>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="lastName" title="Last Name">
                                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column">
                                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter">
                                    </kendo-grid-string-filter-menu>
                                    <small style="color:brown">Search is case-sensitive.</small>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="phoneNumber" title="Mobile Number">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    {{dataItem.countryCode}}{{dataItem.phoneNumber}}
                                </ng-template>

                            </kendo-grid-column>
                            <kendo-grid-column field="primary_Email" title="Email">

                            </kendo-grid-column>
                            <!-- <kendo-grid-column field="residentialUnit" title="Occupied Residential Unit"
                                [hidden]="true"></kendo-grid-column> -->


                            <kendo-grid-column field="isActive" title="Status" [width]="100" filter="boolean">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <ui-switch color="rgb(100, 189, 99)" [checked]="dataItem.isActive"
                                            uncheckedLabel="Inactive" checkedLabel="Active" size="small" class="me-1"
                                            (change)="ActivationChange($event,dataItem)"></ui-switch>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>



                            <kendo-grid-command-column title="" [columnMenu]="false" [width]="90">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="d-flex">
                                        <!-- Edit Consumer Icon -->
                                        <span kendoTooltip title="Go to Consumer Edit"
                                            (click)="goToEditConsumer(dataItem)" style="cursor: pointer;">
                                            <i class="fas fa-edit text-primary"></i>
                                        </span>

                                        <!-- Delete Consumer Icon -->
                                        <span kendoTooltip title="Delete Consumer" (click)="deleteConsumer(dataItem)"
                                            class="ms-3" style="cursor: pointer;">
                                            <i class="fas fa-trash-alt text-danger"></i>
                                        </span>
                                    </div>
                                </ng-template>
                            </kendo-grid-command-column>
                            <ng-template kendoGridDetailTemplate let-dataItem="dataItem">
                                <app-multiflats-expended
                                    [flatsArry]="dataItem.residentialUnitList"></app-multiflats-expended>
                            </ng-template>

                        </kendo-grid>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Bulk upload modal -->
<div bsModal #bulkUploadModal="bs-modal" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary text-white">
                <h5 class="modal-title">Bulk Upload</h5>
                <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"
                    (click)="bulkUploadModal?.hide()"></button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal">
                    <div class="mb-3">
                        <button type="button" class="btn btn-light btn-label waves-light"><i
                                class="mdi mdi-download  label-icon "></i> Download Template</button>
                    </div>
                    <label>Upload Template</label>
                    <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl" [batch]="true">
                    </kendo-upload>

                </form>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" >Close</button> -->
                <button type="button" class="btn btn-primary waves-effect waves-light">Upload</button>
            </div>
        </div><!-- /.modal -->
    </div>
</div>
<!-- Bulk upload modal End  -->