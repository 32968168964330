import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
//import { rechargeData } from '../../consumers/rp-wallet/recharge-data';
import { Store, select } from '@ngrx/store';
import { process, State } from '@progress/kendo-data-query';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';

import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';

import { Router } from '@angular/router';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  getConsumersRechargeHistoryExportResponse,
  getConsumersRechargeHistoryResponse,
} from '../../../store/customer/consumer-selector';
import { response } from 'express';
import {
  getConsumerRechargeHistory,
  getConsumerRechargeHistoryExport,
  resetrechargeHistoryState,
} from '../../../store/customer/consumer.action';
import { table_pageSize } from '../../../shared/constants/templatesNames';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { RpAlertsComponent } from '../../../shared/components/rp-alerts/rp-alerts.component';
import { formatDate } from '@angular/common';  // <-- Import formatDate from common
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-rp-recharge-history',
  templateUrl: './rp-recharge-history.component.html',
  styleUrl: './rp-recharge-history.component.scss',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class RpRechargeHistoryComponent {
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  public checkboxOnly = true;
  datePickerConfig: Partial<BsDatepickerConfig>;
  public mode: SelectableMode = 'multiple';
  public drag = false;
  public selectableSettings!: SelectableSettings;
  paymentlaertModal!: BsModalRef;
  projectId: any;
  consumerId: any;
  currentUser: any;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  public pageSizes = table_pageSize.pageSizes;
  //public pageSizes = true;
  breadCrumbItems: Array<{}> | undefined;
  public previousNext = true;
  public consumerRechargesState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public sort: SortDescriptor[] = [];
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: string = '';

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  public pageSize = 10;
  public skip = 0;

  totalUserCount: number = 0;
  currentPage: number = 1;
  public consmergridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public gridData: unknown[] = [];
  fromDashboardRecharge: any;
  private rechargeSubscription: Subscription;
  constructor(
    private store: Store,
    private modalService: BsModalService,
    private router: Router,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private pdfexportService: PdfexportService,
    private notificationService: NotificationService
  ) {
    this.datePickerConfig = Object.assign(
      {},
      {
        dateInputFormat: 'YYYY-MM-DD', // Customize as needed
        showWeekNumbers: false,
        isAnimated: true,
        containerClass: 'theme-dark-blue',
      }
    );
    this.breadCrumbItems = [{ label: 'Recharge history' }];
    if (history.state.Recharge != undefined && history.state.Recharge !== null) {
      this.fromDashboardRecharge = history.state.Recharge;
      console.log(this.fromDashboardRecharge)
    }

    this.rechargeSubscription = this.notificationService.rechargeUpdate$.subscribe(data => {
      this.updateRechargePage(data);
    });


    this.getConsumerStoreInfo();
  }
  public onValueChange(ev: string): void {


    if (ev.length == 0) {
      this.rechargePageChange({ skip: 0, take: this.pageSize })
      this.store.dispatch(
        getConsumerRechargeHistory({
          pageIndex: this.currentPage,
          pageSize: this.pageSize,
          searchTerm: this.searchedValue,
          filter: this.queryString,
          orderBy: this.orderByQuery,
          ProjectId: this.projectId,
        })
      );
    }
  }

  onSearchRechargeHistory(): void {
    if (this.searchedValue.length > 0) {
      this.store.dispatch(
        getConsumerRechargeHistory({
          pageIndex: this.currentPage,
          pageSize: this.pageSize,
          searchTerm: this.searchedValue,
          filter: this.queryString,
          orderBy: this.orderByQuery,
          ProjectId: this.projectId,
        })
      );
    }
  }

  clearSearch() {
    this.searchedValue = '';
    this.onFilter(null);
  }
  public onFilter(value: any): void {
    // const inputValue = value;
    // this.searchedValue = value;
    this.rechargePageChange({ skip: 0, take: this.pageSize })
    this.store.dispatch(
      getConsumerRechargeHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }
  exportData: any[] = [];
  rechargeHistory: any[] = [];
  private exportSubscription: Subscription = new Subscription();
  getConsumerStoreInfo() {
    let getConsumersRechargeHistory$ = this.store.pipe(
      select(getConsumersRechargeHistoryResponse)
    );
    this.exportSubscription = this.store
      .pipe(select(getConsumersRechargeHistoryExportResponse))
      .subscribe((res: any) => {
        if (res) {
          this.exportData = [];
          if (res.items.length > 0) {
            console.log(res);
            this.exportData = [...res.items];
            console.log(this.exportData[0].date)
            const transformedData = this.exportData?.map((item: any) => ({
              'Date': formatDate(item.date, 'dd MMM yyyy HH:mm:ss', 'en-US'),
              'Recharge Amount': item.rechargeAmount,
              'Current Balance': item.currentBalance,
              'Payment Ref No': item.paymentRefNumber,
              "Mode of Payment": item.mode,
              'Recharge Status': item.rechargeStatus,
            }));

            this.pdfexportService.downloadExcel(
              transformedData,
              'Recharge History'
            );
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    getConsumersRechargeHistory$.subscribe((res: any) => {
      console.log(res);
      if (res) {
        this.rechargeHistory = [...res.items];
        console.log(this.rechargeHistory);
        this.pageSize = res.pageSize;
        this.totalUserCount = res.totalCount;
        this.loadGridTable();
      }
    });

    /* this.export$.subscribe((res: any) => {
     
    }) */
  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();
      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }


  openCalendar() {
    // Trigger the click event on the input field to open the datepicker
    const dateInput = document.querySelector('.form-control.date');
    dateInput?.dispatchEvent(new Event('click'));
  }

  formatDateRange(dateRange: (Date | null)[]): string {
    if (dateRange && dateRange.length === 2 && dateRange[0] && dateRange[1]) {
      const startDate = formatDate(dateRange[0], 'dd/MM/YYYY', 'en-US');
      const endDate = formatDate(dateRange[1], 'dd/MM/YYYY', 'en-US');
      return `${startDate} to ${endDate}`;
    }
    return '';  // Return empty string if the date range is not valid
  }

  clearDate() {
    this.fromDate = [];// Clear the selected date
    this.queryString = ''
    this.store.dispatch(
      getConsumerRechargeHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }

  ngOnDestroy() {
    if (this.rechargeSubscription) {
      this.rechargeSubscription.unsubscribe();
    }
    this.store.dispatch(resetrechargeHistoryState());
    //this.store.dispatch(resetrechargeExportInfoState()); // Optionally reset state
    //  this.exportData = [];
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }
  ngOnInit(): void {
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.consumerId = this.currentUser?.currentUserRoleDetails?.userId;
    this.projectId = this.currentUser?.project.id;
    this.searchedValue = history.state.info ?? '';



    if (this.fromDashboardRecharge && typeof this.fromDashboardRecharge === 'object' && Object.keys(this.fromDashboardRecharge).length > 0) {
      this.pageSize = 5;
      console.log(this.fromDashboardRecharge)
    }

    this.store.dispatch(
      getConsumerRechargeHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }

  updateRechargePage(data: any) {
    this.searchedValue = data;
    this.store.dispatch(
      getConsumerRechargeHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
    // Trigger any additional logic needed to refresh the page
  }

  exportAll() {
    this.store.dispatch(
      getConsumerRechargeHistoryExport({
        ProjectId: this.projectId,
        criteria: 'All',
      })
    );
  }
  public onExport(type: string) {
    if (type === 'Excel') {
      if (this.consmergridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
  }

  fromDate: any = [];

  filterBasedOnDate(flag: number) {
    if (flag === 0) {
      this.fromDate = [];
      this.queryString = '';
    } else {
      const formattedStartDate = this.fromDate[0].toISOString().split('T')[0];
      const formattedEndDate = this.fromDate[1].toISOString().split('T')[0];

      // Construct the query string with the formatted dates (yyyy-MM-dd)
      this.queryString = `$filter=(date ge ${formattedStartDate} and date le ${formattedEndDate})`;

      // const adjustedStartDate = new Date(this.fromDate[0]);
      // adjustedStartDate.setDate(adjustedStartDate.getDate() - 1);

      // const adjustedEndDate = new Date(this.fromDate[1]);
      // adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

      // // Format the adjusted dates using formatDate
      // const formattedStartDate = formatDate(adjustedStartDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US');
      // const formattedEndDate = formatDate(adjustedEndDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'en-US');

      // // Construct the query string with the adjusted and formatted dates
      // this.queryString = `$filter=(date ge ${formattedStartDate} and date le ${formattedEndDate})`;

      //this.queryString = `$filter=(date ge ${this.fromDate[0].toISOString()} and date ge ${this.fromDate[1].toISOString()})`;
    }



    this.store.dispatch(
      getConsumerRechargeHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }

  viewRechargeDetails(data: any) {
    this.loaderService.setLoading(true);
    console.log(data);
    let paymentData = { ...data }; // Clone the object to ensure it's a fresh copy
    paymentData['lastRechargeAmount'] = paymentData.rechargeAmount;
    paymentData['paymentId'] = paymentData.paymentRefNumber;
    paymentData['lastRechargeDate'] = paymentData.date;
    paymentData['name'] = paymentData.consumerName;

    this.paymentlaertModal = this.modalService.show(RpAlertsComponent, {
      initialState: { paymentData },
      ...this.config,
    });
  }
  selectDates(event: any) {
    console.log(event);
    if (event == undefined) {
      this.fromDate = [];
      this.queryString = '';
      this.store.dispatch(
        getConsumerRechargeHistory({
          pageIndex: this.currentPage,
          pageSize: this.pageSize,
          searchTerm: this.searchedValue,
          filter: this.queryString,
          orderBy: this.orderByQuery,
          ProjectId: this.projectId,
        })
      );
    }
  }
  onSortChange(sort: SortDescriptor[]): void {
    this.consumerRechargesState.sort = sort;
    console.log(this.consumerRechargesState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.consumerRechargesState.sort
    );

    this.orderByQuery = orderByQuery;

    this.store.dispatch(
      getConsumerRechargeHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
  }

  public onrechargeFilterChange(filter: CompositeFilterDescriptor): void {
    this.consumerRechargesState.filter = filter;
    console.log(this.consumerRechargesState);
    const queryString = this.sharedService.createQuery(
      this.consumerRechargesState
    );
    this.queryString = this.sharedService.createQuery(
      this.consumerRechargesState
    );
    this.skip = 0
    this.store.dispatch(
      getConsumerRechargeHistory({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );
    console.log(queryString);
  }

  protected rechargePageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.selectedRowdata = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getConsumerRechargeHistory({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        ProjectId: this.projectId,
      })
    );

    this.loadGridTable();
  }
  loadGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };

    const processedData = process(this.rechargeHistory, state);
    this.consmergridData = {
      data: this.rechargeHistory,
      total: this.totalUserCount,
    };
    this.loaderService.setLoading(false);
  }

  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  onSelect(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdata.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdata.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdata);
      });

      if (this.deselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdata = filteredArray;
      }
    }
  }

  protected consumerStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadGridTable();
  }
}

