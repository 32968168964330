import { Helper } from '../../../core/helpers/helper';
import {
  GetAllMeterDetails,
  getMeterDetails,

  resetMeterDetailsState,
  resetState,
} from '../../../store/meters/meters.actions';
import { Component, ViewChild } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { metersData } from './meters-data';
import { Store, select } from '@ngrx/store';
import { SharedService } from '../../shared.service';
import { Router } from '@angular/router';
import {
  process,
  State,
  toODataString,
  SortDescriptor,
} from '@progress/kendo-data-query';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
  SelectableSettings,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  getAllmeterConsumptionResponse,
  getmeterConsumptionResponse,
} from '../../../store/meters/meters-selector';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { table_pageSize } from '../../constants/templatesNames';

import { PdfexportService } from '../../../core/services/pdfexport.service';
import { Roles, SweetAlertIcon } from '../../constants/enum';
@Component({
  selector: 'app-rp-meters-reading',
  templateUrl: './rp-meters-reading.component.html',
  styleUrl: './rp-meters-reading.component.scss',
})
export class RpMetersReadingsComponent {
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  selectValue!: string[];
  breadCrumbItems: Array<{}> | undefined;
  public meterViewState: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public gridData: unknown[] = metersData;
  public gridView!: unknown[];
  meterViewDetails: any;
  selectableSettings = this.sharedService.selectableSettings;
  public pageSize = 10;
  public skip = 0;
  totalMasterCount: number = 0;
  currentPage: number = 1;
  meterconfigList: any[] = [];
  public sort: SortDescriptor[] = [];
  public metergridData: GridDataResult = { data: [], total: 0 };
  public electric_city_gridData: GridDataResult = { data: [], total: 0 };
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };
  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;
  mySelectionMeter: any = [];
  fromDate: any = [];
  orderByQuery: string = '';
  queryString: string = '';
  searchedValue: any = '';
  meterAllDetails: any[] = [];
  role: any;
  listOfRoles = Roles;
  constructor(
    private store: Store,
    private sharedService: SharedService,
    private router: Router,
    private PdfexportService: PdfexportService
  ) {
    this.store.dispatch(resetMeterDetailsState());
    if (history.state.meterViewDetails) {
      this.meterViewDetails = JSON.parse(history.state.meterViewDetails);
      console.log(this.meterViewDetails?.id);
      this.breadCrumbItems = [
        { label: 'Meters', url: 'meters', active: true },
        {
          label: 'Meter Details',
        },
      ];
    }
    this.getStoreInfo();

    const currentUserString = localStorage.getItem('currentUser');
    let currentUserData = JSON.parse(currentUserString ?? '');
    this.role = currentUserData?.currentUserRoleDetails?.role;
  }
  handleMeterInfo(data: any) { }
  exportAll() {
    this.getAllMeterDetails();
  }
  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }
  getStoreInfo() {
    let MetersConfigurationHistory$ = this.store.pipe(
      select(getmeterConsumptionResponse)
    );

    let getAllmeterConsumptionResponse$ = this.store.pipe(
      select(getAllmeterConsumptionResponse)
    );

    MetersConfigurationHistory$.subscribe((res: any) => {
      if (res) {
        console.log(res)
        this.meterconfigList = [...res.items];
        this.pageSize = res.pageSize;
        this.totalMasterCount = res.totalCount;
        this.loadMterConfig();
      }

      console.log('MetersConfiguration', res);
    });

    /*  getAllmeterConsumptionResponse$.subscribe((res: any) => {
       if (res) {
         this.meterAllDetails = [...res.items];
         if (this.meterAllDetails.length > 0) {
           const transformedData = this.meterAllDetails.map((item: any) => ({
             'utilityType': item.utilityType,
             'Mac Address': item.macAddress,
             'Device Sr.No': item.deviceSRNo,
             'Time Stamp': Helper.formatDate(item.currentDate),
             'Meter Reading': item.meterReading,
             'Battery Voltage': item.batteryVoltage,
             "Meter Reading DG": item.meterReadingDG,
             "Meter Reading EB": item.meterReadingEB,
             "On/Off Status": item.onOffStatus,
             "Battery Level": item.batterLevel,
             'Occupant': item.occupant,
 
             // Placeholder for region.name
             // 'Status Bit': item.statusBits, // Placeholder for bill date
             // Placeholder for status
           }));
           this.PdfexportService.downloadExcel(transformedData, 'MeterReading');
         } else {
           this.sharedService.showMessageDialog(
             'No data to export',
             '',
             SweetAlertIcon.ERROR
           );
         }
       }
       console.log('MetersConfiguration', res);
     }); */
  }

  public onExport(type: string) {
    if (type === 'Excel') {
      if (this.metergridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else {

        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.ERROR
        );
      }
    }
  }
  private loadMterConfig(): void {
    const state: State = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    // alert(JSON.stringify(this.filter))
    //  console.log(state)
    const processedData = process(this.meterconfigList, state);
    //  console.log(processedData)
    this.metergridData = {
      data: processedData.data,
      total: this.totalMasterCount,
    };
    this.metergridData.data = this.metergridData.data.map((meter) => {
      console.log(new Date(meter.currentDate));
      return {
        ...meter,
        currentDate: new Date(meter.currentDate), // Modify the billDate property
      };
    });
    console.log(this.metergridData.data)
  }

  isFilter: any;

  ngOnInit(): void {
    this.isFilter = localStorage.getItem('filter');

    const savedFilter = this.sharedService.getFilters('MeterPage');
    if (this.isFilter == 'true' && savedFilter) {
      this.meterViewState.filter = this.filter;
      console.log(this.filter)
      console.log(this.meterViewState)
      this.queryString = this.sharedService.createQuery(this.meterViewState);
    }
    /* if (this.isFilter == 'true' && this.currentFilterpage == 'Meter') {
      this.filter = this.sharedService.getFilter();
      this.meterViewState.filter = this.filter;
      console.log(this.filter)
      console.log(this.meterViewState)
      this.queryString = this.sharedService.createQuery(this.meterViewState);
    } */
    this.getMeterDetails();
    // this.gridView = this.gridData;
  }

  public onValueChange(ev: string): void {
    // console.log(ev)
    //console.log(ev.length)
    //console.log(this.searchedValue)

    if (ev.length == 0) {
      this.meterConfigPageChange({ skip: 0, take: this.pageSize })
      this.getMeterDetails()
    }
  }

  onSearchMeterReadingUnits(): void {
    if (this.searchedValue.length > 0) {
      this.getMeterDetails()
    }
  }

  ngOnDestroy() {
    this.store.dispatch(resetMeterDetailsState()); // Or set it to [] if you prefer an empty array


  }
  onSortChange(sort: SortDescriptor[]): void {
    this.meterViewState.sort = sort;
    console.log(this.meterViewState.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.meterViewState.sort
    );

    this.orderByQuery = orderByQuery;

    this.store.dispatch(
      getMeterDetails({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
  }

  public onprojectFilterChange(filter: CompositeFilterDescriptor): void {
    this.meterViewState.filter = filter;
    console.log(this.meterViewState);
    this.skip = 0;
    this.sharedService.setFilters('MeterReadingPage', filter);
    const queryString = this.sharedService.createQuery(this.meterViewState);
    this.queryString = this.sharedService.createQuery(this.meterViewState);
    this.store.dispatch(
      getMeterDetails({
        pageIndex: 1,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
    console.log(queryString);
  }

  getMeterDetails() {
    this.store.dispatch(
      getMeterDetails({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
  }

  getAllMeterDetails() {
    this.store.dispatch(
      GetAllMeterDetails({
        pageIndex: 1,
        pageSize: this.totalMasterCount,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
  }

  public onFilterChange(filter: CompositeFilterDescriptor): void {
    this.state.filter = filter;
    const queryString = this.createQuery(this.state);
    console.log(queryString);


    let isFilter = filter.filters.length > 0 ? true : false;
    localStorage.setItem('filter', JSON.stringify(isFilter));
    this, this.currentPage = 1
    this.getMeterDetails()
  }

  private createQuery(state: State): string {
    // Implement this method to create a query string from the state object
    // This should convert the state to your API's query parameters
    // Example implementation
    const queryStr = `${toODataString(state)}&$count=true`;
    // console.log(queryStr);

    let query = `skip=${state.skip}&take=${state.take}`;

    if (state.filter) {
      const filters = state.filter.filters
        .map((f) => {
          const field = (f as any).field;
          const value = (f as any).value;
          return `${field}=${value}`;
        })
        .join('&');
      query += `&${filters}`;
    }

    return query;
  }

  protected meterConfigPageChange({ skip, take }: PageChangeEvent): void {
    console.log('>>>', skip, take);
    this.mySelectionMeter = [];
    this.selectedRowdataOnOff = [];
    this.skip = skip;
    this.pageSize = take;
    this.currentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    this.store.dispatch(
      getMeterDetails({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
    this.loadMterConfig();
  }

  public selectionChanged3(selection: any) {
    // Handle deselected rows
    selection.deselectedRows.forEach((row: any) => {
      const index = this.mySelectionMeter.findIndex(
        (selectedRow: any) => selectedRow.id === row.dataItem.id
      );
      if (index > -1) {
        this.mySelectionMeter.splice(index, 1);
      }
    });

    // Handle selected rows
    selection.selectedRows.forEach((row: any) => {
      if (!this.isMasterRowSelected(row.dataItem)) {
        this.mySelectionMeter.push(row.dataItem);
      }
    });

    console.log(this.mySelectionMeter);


  }

  selectedRowdata: any = [];
  deselectedRowdata: any = [];
  selectedRowdataOnOff: any = [];
  deselectedRowdataOnOff: any = [];
  selectionChanged(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.selectedRowdataOnOff.push(element.dataItem);
        console.log(' selected Values : ', this.selectedRowdataOnOff);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.deselectedRowdataOnOff.push(element.dataItem);
        console.log('deselectedRowdata Values : ', this.deselectedRowdataOnOff);
      });

      if (this.deselectedRowdataOnOff.length > 0) {
        const idsToRemove = new Set(
          this.deselectedRowdataOnOff.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.selectedRowdataOnOff.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.selectedRowdataOnOff = filteredArray;
      }
    }
    console.log(this.selectedRowdataOnOff)
  }

  protected dataStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadMterConfig();
  }

  public isMasterRowSelected = (rowArg: any) => {
    return this.mySelectionMeter.some((row: any) => row.id === rowArg.id);
  };

  public onMeterConfigFilter(value: any): void {
    // const inputValue = value;
    // this.searchedValue = value;
    this.meterConfigPageChange({ skip: 0, take: this.pageSize })
    this.store.dispatch(
      getMeterDetails({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
  }
  filterBasedOnDate(flag: number) {
    if (flag == 0) {
      this.fromDate = [];
    }
    this.store.dispatch(
      getMeterDetails({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
        MeterId: this.meterViewDetails?.id,
        FromDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[0]) : '',
        ToDate:
          this.fromDate.length > 0 ? Helper.formatDate(this.fromDate[1]) : '',
      })
    );
  }
  public onMeterPdfExport() {
    this.hiddenGrid?.saveAsPDF();
  }
}
