import { Component, ViewChild } from '@angular/core';
import { SVGIcon, filePdfIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { billData } from './utilities-data';
import { invoiceData } from './invoice-data';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

import {
  SelectableSettings,
  SelectableMode,
} from '@progress/kendo-angular-grid';
import {
  orderBy,
  SortDescriptor,
  filterBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  FilterableSettings,
  DataBindingDirective,
  GridDataResult,
  PageChangeEvent,
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';

import { Router } from '@angular/router';
import { Store, compose, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import {
  getBillsByIdResponse,
  getresidentialResponse,
} from '../../../store/Bills/bills-selector';
import { take } from 'rxjs';
import {
  createBillChargeResponse,
  deleteBillChargeResponse,
  getAllBillSettingResponse,
  getBillSettingResponse,
  getBillinvloiceResponse,
  getOptionalResponse,
  updateBillChargeResponse,
  updateOptionalBillChargeResponse,
} from '../../../store/billsSettings/billSettings-selector';
import {
  createBillCharge,
  createInvoice,
  createOptionalBillCharge,
  deleteBillCharge,
  deleteInvoice,
  getAllBillSettingslists,
  getBillSettingslists,
  getBillinvloicelist1,
  getOptionallist,
  resetState,
  updateBillCharge,
  updateInvoice,
  updateOptionalBillCharge,
} from '../../../store/billsSettings/billSettings.action';
import { SharedService } from '../../../shared/shared.service';
import { Validations } from '../../../shared/constants/validations';
import { projectBillingDates } from '../../../shared/constants/projectBillingDates';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { process, State } from '@progress/kendo-data-query';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { table_pageSize } from '../../../shared/constants/templatesNames';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { Helper } from '../../../core/helpers/helper';
@Component({
  selector: 'app-bill-settings',
  templateUrl: './bill-settings.component.html',
  styleUrl: './bill-settings.component.scss',
})
export class BillSettingsComponent {
  selectValue!: string[];
  projectBillingDates = projectBillingDates;
  billsChargeForm!: UntypedFormGroup;
  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.png'],
  };

  breadCrumbItems: Array<{}> | undefined;
  invoiceAddForm!: UntypedFormGroup;
  individualbillForm!: UntypedFormGroup;
  combinedBillForm!: UntypedFormGroup;
  public selectableSettings!: SelectableSettings;
  public checkboxOnly = true;
  public drag = false;
  public mode: SelectableMode = 'multiple';
  orderByQuery: string = '';
  queryString: string = '';
  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  public billSettinggridData: GridDataResult = { data: [], total: 0 };
  billSettingList: any[] = [];
  optionalList: any = ([] = []);
  isPenalityApplicable: any = false;
  invoicesearchedValue = '';
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective | undefined;
  public billsSettings: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public billsInvoice: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  public billInvoicegridData: GridDataResult = { data: [], total: 0 };
  billInvoiceList: any[] = [];
  billsInvoicetotalUserCount: number = 0;

  public pageSize = 10;
  public skip = 0;
  billstotalUserCount: number = 0;
  prifileValidations = Validations;

  modelRef_1!: BsModalRef;
  invoiceAdd!: BsModalRef;

  isAllOpen: boolean = true;
  public filterMode: FilterableSettings = 'row';
  public filePdfIcon: SVGIcon = filePdfIcon;
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  url: any = '';
  hidden!: boolean;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };

  modalRef!: BsModalRef;
  config: any = {
    backdrop: true,
    ignoreBackdropClick: true,
  };
  //ublic billpageSizes = true;
  public pageSizes = table_pageSize.pageSizes;
  public previousNext = true;

  public buttonCount = 5;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';

  submitted: boolean = false;
  optionalSubmitted: boolean = false;
  individualbillSubmitted: boolean = false;
  searchedValue: any = '';
  configData: any;
  utilityList: any[] = [];

  selectedUtilityType: any[] = [];
  regex = /^[0-9]*$/;
  //gstregex: RegExp = /^(1?[0-9]|2[0-8])$/;
  gstregex: RegExp = /^(1|[1-9]|[1-2][0-8])$/;

  regex_Alphanumeric = /^[a-zA-Z0-9]* ?[a-zA-Z0-9]*$/; //Only Alpha numeric are allowed
  NumberWithDecimalregex: RegExp = /^\d+(\.\d+)?$/;
  Onlynumberwithdecimals = /^(0|[1-9]\d*)(\.\d+)?$/;


  public gridData: unknown[] = billData;
  public gridView!: unknown[];

  public gridDataInvoice: unknown[] = invoiceData;
  public gridViewInvoice!: unknown[];

  @ViewChild('dp', { static: true }) datePicker: any;

  @ViewChild('pdfGrid') public hiddenGrid: GridComponent | undefined;
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  billGenType?: string;
  allBillSettingList: any[] = [];

  constructor(
    private modalService: BsModalService,
    private sharedService: SharedService,
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    private PdfexportService: PdfexportService
  ) {
    this.breadCrumbItems = [
      {
        label: 'Bill Settings',
      },
    ];
    this.billsChargeForm = this.formBuilder.group({
      name: [
        ,
        [Validators.required, Validators.pattern(this.regex_Alphanumeric)],
      ],
      amount: ['', [Validators.required, Validators.pattern(this.Onlynumberwithdecimals)]],
      utilityType: [,],
      gst: [''],
      GST_Applicable: [false],
      total: [''],
    });
    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag,
    };

    this.individualbillForm = this.formBuilder.group({
      PenalityAmountForElectricity: [
        ,
        [Validators.required, Validators.pattern(this.Onlynumberwithdecimals)],
      ],
      PenalityAmountForOthers: [
        ,
        [Validators.required, Validators.pattern(this.Onlynumberwithdecimals)],
      ],
      PenalityAmountForGas: [
        ,
        [Validators.required, Validators.pattern(this.Onlynumberwithdecimals)],
      ],
      PenalityAmountForWater: [
        ,
        [Validators.required, Validators.pattern(this.Onlynumberwithdecimals)],
      ],

      PenaltyApplicable_dateElectricity: [null, [Validators.required]],
      PenaltyApplicable_dateGas: [null, [Validators.required]],
      PenaltyApplicable_dateWater: [null, [Validators.required]],
      PenaltyApplicable_dateOthers: [null, [Validators.required]],
    });
    this.invoiceAddForm = this.formBuilder.group({
      slot: ['', [Validators.required]],
      date: ['', [Validators.required]],
      files: ['', [Validators.required]],
    });

    this.combinedBillForm = this.formBuilder.group({
      amount: [
        '',
        [Validators.required, Validators.pattern(this.Onlynumberwithdecimals)],
      ],
      date: ['', [Validators.required]],
    });
    this.getAllConfigData();
    this.getBillsStoreInfo();
  }


  onPenalityApplicableChange(isApplicable: boolean) {
    const individualFields = [
      'PenalityAmountForElectricity',
      'PenalityAmountForOthers',
      'PenalityAmountForGas',
      'PenalityAmountForWater',
      'PenaltyApplicable_dateElectricity',
      'PenaltyApplicable_dateGas',
      'PenaltyApplicable_dateWater',
      'PenaltyApplicable_dateOthers',
    ];

    // Toggle validators for individualbillForm fields
    individualFields.forEach(field => {
      const control = this.individualbillForm.get(field);
      if (control) {
        if (isApplicable) {
          control.setValidators([Validators.required, Validators.pattern(this.Onlynumberwithdecimals)]);
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity();
      }
    });

    // Toggle validators for combinedBillForm fields
    const combinedFields = ['amount', 'date'];
    combinedFields.forEach(field => {
      const control = this.combinedBillForm.get(field);
      if (control) {
        if (isApplicable) {
          control.setValidators([Validators.required, Validators.pattern(this.Onlynumberwithdecimals)]);
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity();
      }
    });
  }




  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    // Check if the element is a table cell
    if (element.nodeName === 'TD') {
      const cellValue = element.innerText.trim();

      // Only show the tooltip if the cell is not empty, null, or ''
      if (cellValue && !element.closest('.no-tooltip')) {
        this.tooltipDir?.toggle(element);
      } else {
        this.tooltipDir?.hide();
      }
    } else if (element.classList.contains('k-column-title')) {
      this.tooltipDir?.toggle(element);
    } else {
      this.tooltipDir?.hide();
    }
  }

  getAllConfigData() {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data.data;
        this.utilityList = this.configData.utilities;
      }
    });
  }
  get billsControls() {
    return this.billsChargeForm.controls;
  }
  get combinedBillFormControls() {
    return this.combinedBillForm.controls;
  }
  get invoiceAddFormFormControls() {
    return this.invoiceAddForm.controls;
  }

  get optionalbillsControls() {
    return this.individualbillForm.controls;
  }
  private updateBillChargeResponse$: Subscription = new Subscription();
  private exportSubscription: Subscription = new Subscription();
  getBillsStoreInfo() {
    this.store.dispatch(resetState());
    let getBillSetting$ = this.store.pipe(select(getBillSettingResponse));
    let getInvoiceAds$ = this.store.pipe(select(getBillinvloiceResponse));
    let deleteResponse$ = this.store.pipe(select(deleteBillChargeResponse));
    let createresponse$ = this.store.pipe(select(createBillChargeResponse));
    let updateOptionalBillChargeResponse$ = this.store.pipe(select(updateOptionalBillChargeResponse));


    this.updateBillChargeResponse$ = this.store.pipe(select(updateBillChargeResponse)).subscribe({

      next: (res: any) => {
        if (res) {
          // console.log(res)
          //this.loaderService.setLoading(false);
          this.getbillSettings();
        }
      }
    });

    //

    this.exportSubscription = this.store
      .pipe(select(getAllBillSettingResponse))
      .subscribe((res: any) => {
        if (res) {
          this.allBillSettingList = [];
          this.loaderService.setLoading(false);
          if (res.items.length > 0) {
            this.allBillSettingList = [...res.items];
            const transformedData = this.allBillSettingList.map(
              (item: any) => ({
                'Name': item.name,
                'Amount': item.amount,
                'Utility': item.utility,
                'GST Applicable': item.isGSTApplicable ? 'Yes' : 'No',
                'GST(%)': item.gst,
                'Total': item.total,
              })
            );
            this.PdfexportService.downloadExcel(
              transformedData,
              'billsSettingsxlsx'
            );
          } else {
            this.sharedService.showMessageDialog(
              'No data to export',
              '',
              SweetAlertIcon.ERROR
            );
          }
        }
      });

    let getOptionallist$ = this.store.pipe(select(getOptionalResponse));

    getOptionallist$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.optionalList = res;
        // console.log(res);
        //  console.log(res);
        this.isPenalityApplicable = this.optionalList[0].isPenaltyApplicable;
        if (this.billGenType == 'Combined') {
          this.combinedBillForm.patchValue({
            amount: this.optionalList[0].penalityAmountForCombined,
            date: this.optionalList[0].penaltyApplicable_dateCombined,
          });
        } else {
          this.optionalList.map((item: any) => {
            this.combinedBillForm.patchValue({
              amount: item.penalityAmountForCombined,
              date: item.penaltyApplicable_dateCombined,
            });
          });
          this.individualbillForm.patchValue({
            PenalityAmountForElectricity:
              this.optionalList[0].penalityAmountForElectricity,
            PenalityAmountForGas: this.optionalList[0].penalityAmountForGas,
            PenalityAmountForWater: this.optionalList[0].penalityAmountForWater,
            PenalityAmountForOthers:
              this.optionalList[0].penalityAmountForOthers,

            PenaltyApplicable_dateElectricity:
              this.optionalList[0].penaltyApplicable_dateElectricity,
            PenaltyApplicable_dateGas:
              this.optionalList[0].penaltyApplicable_dateGas,
            PenaltyApplicable_dateWater:
              this.optionalList[0].penalityAmountForElectricity,
            PenaltyApplicable_dateOthers:
              this.optionalList[0].penaltyApplicable_dateOthers,
          });
        }
      }
      // console.log('this.optionalList', res, this.optionalList);
    });

    getBillSetting$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.billSettingList = [...res.items];
        this.pageSize = res.pageSize;
        this.billstotalUserCount = res.totalCount;
        this.loadBillsGridTable();
      }
      // console.log('billSettingList', res, this.billSettingList);
    });
    getInvoiceAds$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.billInvoiceList = [...res.items];
        this.pageSize = res.pageSize;
        this.billstotalUserCount = res.totalCount;
        this.loadBillsInvoiceGridTable();
      }
      // console.log('billSettingList', res, this.billInvoiceList);
    });

    deleteResponse$.subscribe((res: any) => {
      if (res) {
        this.getbillSettings();
      }
    });
    createresponse$.subscribe((res: any) => {
      if (res) {
        this.getbillSettings();
      }
    });


    updateOptionalBillChargeResponse$.subscribe((res: any) => {
      if (res) {
        this.store.dispatch(getOptionallist({ projectId: this.projectId }));
      }
    });
  }

  ngOnDestroy() {
    this.updateBillChargeResponse$.unsubscribe();
    this.exportSubscription.unsubscribe(); // Or set it to [] if you prefer an empty array
  }

  loadBillsInvoiceGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.billInvoiceList, state);
    this.billInvoicegridData = {
      data: this.billInvoiceList,
      total: this.billsInvoicetotalUserCount,
    };
    // console.log(this.billInvoicegridData);
    this.loaderService.setLoading(false);
  }

  loadBillsGridTable() {
    const state: any = {
      take: this.pageSize,
      sort: this.sort,
      filter: this.filter,
    };
    const processedData = process(this.billSettingList, state);
    this.billSettinggridData = {
      data: this.billSettingList,
      total: this.billstotalUserCount,
    };
    //console.log(this.billSettinggridData);
    this.billSettinggridData.data = this.billSettinggridData?.data.map((bill) => {
      return {
        ...bill,
        gstStatus: bill.isGSTApplicable ? 'Yes' : 'No',
        createdOn: ` ${Helper.excelformatDate(bill?.createdOn)}`
      };
    });
    this.loaderService.setLoading(false);
  }
  private files: any[] = [];
  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      // this.file = event.target.files[0]
      var fromdate = new FormData();
      //this.invoiceAddForm.patchValue({ image: this.file });
      // fromdate.append('file', file);

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event?.target?.result;
        //  console.log(this.url);
      };
    }
  }
  onFilter(value: any) {
    const inputValue = value;
    this.invoicesearchedValue = value;
    this.store.dispatch(
      getBillinvloicelist1({
        pageIndex: this.invoicecurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.invoicesearchedValue,
      })
    );
  }
  deleteInvoice() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }

  utilityChange(event: any) {
    console.log(event);
  }
  /**
   * Advertisment modal
   * @param addAdvertismentModal Advertisment modal data
   */
  showAdvertismentModal(addAdvertismentModal: any) {
    this.modalRef = this.modalService.show(addAdvertismentModal);
  }

  /**
   * Charge modal
   * @param addChargeModal Advertisment modal data
   */
  showChargeModal(addChargeModal: any) {
    this.modalRef = this.modalService.show(addChargeModal);
  }
  currentUserData: any;
  projectId: any;
  billingType: any;
  ngOnInit(): void {
    this.gridView = this.gridData;
    this.billsChargeForm.controls['total'].disable();
    this.gridViewInvoice = this.gridDataInvoice;

    const currentUserString = localStorage.getItem('currentUser');
    this.currentUserData = JSON.parse(currentUserString ?? '');
    if (this.currentUserData.project !== null) {
      this.projectId = this.currentUserData.project.id;
      this.billingType = this.currentUserData.project.billingType;
      this.billGenType = this.currentUserData.project.billGenerationType;
      // console.log(this.billGenType);
      // console.log(this.billingType);

      // this.billGenType = "Individual"              
    }
    this.getbillSettings();
    // this.getInvoiceList()
    this.getOptionallist();
  }
  public onValueChange(ev: string): void {

    if (ev.length == 0) {
      this.invoicePageChange({ skip: 0, take: this.pageSize })
      this.getbillSettings()
    }
  }

  onSearchWalletSettings() {
    if (this.searchedValue.length > 0) {
      this.getbillSettings()
    }
  }

  getbillSettings() {
    this.store.dispatch(
      getBillSettingslists({
        projectId: this.projectId,
        pageIndex: this.billscurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchedValue,
        filter: this.queryString,
        orderBy: this.orderByQuery,
      })
    );
  }

  getAllbillSettings() {
    this.store.dispatch(
      getAllBillSettingslists({
        projectId: this.projectId,
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
      })
    );
  }
  getInvoiceList() {
    this.store.dispatch(
      getBillinvloicelist1({
        pageIndex: this.invoicecurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.invoicesearchedValue,
        orderBy: this.orderByQuery,
      })
    );
  }
  getOptionallist() {
    this.store.dispatch(getOptionallist({ projectId: this.projectId }));
  }

  saveCharge(type: string) {
    this.submitted = true;
    if (this.billsChargeForm.invalid) {
      return;
    } else {
      // console.log(this.billsChargeForm.value);
      let request = {
        name: this.billsChargeForm.get('name')?.value,
        amount: this.billsChargeForm.get('amount')?.value,
        utilityId: this.billsChargeForm.get('utilityType')?.value,
        isGSTApplicable:
          this.billsChargeForm.get('GST_Applicable')?.value == null
            ? false
            : this.billsChargeForm.get('GST_Applicable')?.value,
        gst:
          this.billsChargeForm.get('gst')?.value == null
            ? ''
            : this.billsChargeForm.get('gst')?.value,

        total: '',

        projectId: this.projectId,
      };
      const amountString = this.billsChargeForm.get('total')?.value;
      const amountNumber = parseFloat(amountString);
      if (!isNaN(amountNumber)) {
        const formattedAmount = amountNumber.toFixed(2);
        //  console.log(formattedAmount);
        request['total'] = formattedAmount;
      }

      if (type == 'Create') {
        this.store.dispatch(createBillCharge({ billCharge: request }));
      } else {
        this.store.dispatch(
          updateBillCharge({ UpdateBillCharge: request, id: this.billChargeId })
        );
      }
      //this.getbillSettings();
      this.modelRef_1.hide();
    }
  }
  handleFileSelected(event: any) {
    this.files = [];
    const newFiles = event.files.filter(
      (f: any) =>
        !this.files.find((existingFile) => existingFile.name == f.name)
    );
    this.files = [...this.files, ...newFiles];
    //  console.log(this.files);
  }

  handleFileRemoved(event: any) {
    //  console.log(event);
  }
  invoiceId: any;
  saveinvoiceAdd(type: string) {
    this.individualbillSubmitted = true;
    // console.log(this.invoiceAddForm.value);
    if (this.invoiceAddForm.invalid) {
      return;
    } else {
      if (type == 'Create') {
        this.store.dispatch(
          createInvoice({
            Invoice: this.invoiceAddForm.value,
            projectId: this.projectId,
          })
        );
      } else {
        this.store.dispatch(
          updateInvoice({
            UpdateInvoice: this.invoiceAddForm.value,
            id: this.invoiceId,
            projectId: this.projectId,
          })
        );
      }
      getBillinvloicelist1({
        pageIndex: this.invoicecurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.invoicesearchedValue,
        orderBy: this.orderByQuery,
      });
      this.invoiceAdd.hide();
    }
  }

  saveOptionalfrom() {
    if (this.billGenType == 'Combined') {
      this.individualbillSubmitted = true;
      if (this.combinedBillForm.invalid) {
        return;
      } else {
        request = {
          optional: [
            {
              utility: 'Total',
              date: this.combinedBillForm.get('date')?.value
                ? this.combinedBillForm.get('date')?.value.toString()
                : null, // Default to an empty string if null
              amount: this.combinedBillForm.get('amount')?.value || 0, // Default to an empty string if null
            },
          ],
          projectId: this.projectId,
          IsPenaltyApplicable: this.isPenalityApplicable
        };

      }
    } else {
      this.optionalSubmitted = true;
      if (this.individualbillForm.invalid) {
        return;
      } else {
        var request = {};
        request = {
          optional: [
            {
              utility: 'Water',
              date: this.individualbillForm.get('PenaltyApplicable_dateWater')?.value
                ? this.individualbillForm.get('PenaltyApplicable_dateWater')?.value.toString()
                : null,
              amount: this.individualbillForm.get('PenalityAmountForWater')?.value || 0, // Default to 0 if null
            },
            {
              utility: 'Gas',
              date: this.individualbillForm.get('PenaltyApplicable_dateGas')?.value
                ? this.individualbillForm.get('PenaltyApplicable_dateGas')?.value.toString()
                : null,
              amount: this.individualbillForm.get('PenalityAmountForGas')?.value || 0,
            },
            {
              utility: 'Electricity',
              date: this.individualbillForm.get('PenaltyApplicable_dateElectricity')?.value
                ? this.individualbillForm.get('PenaltyApplicable_dateElectricity')?.value.toString()
                : null,
              amount: this.individualbillForm.get('PenalityAmountForElectricity')?.value || 0,
            },
            {
              utility: 'Others',
              date: this.individualbillForm.get('PenaltyApplicable_dateOthers')?.value
                ? this.individualbillForm.get('PenaltyApplicable_dateOthers')?.value.toString()
                : null,
              amount: this.individualbillForm.get('PenalityAmountForOthers')?.value || 0,
            },
          ],
          projectId: this.projectId,
          IsPenaltyApplicable: this.isPenalityApplicable // ensure this is a boolean
        };


      }
    }


    this.store.dispatch(
      updateOptionalBillCharge({ UpdateOptionalBillCharge: request })
    );
    this.individualbillSubmitted = false;
    // this.store.dispatch(getOptionallist({ projectId: this.projectId }));

    //this.individualbillForm.reset()
    //this.combinedBillForm.reset()
  }

  deletdinvoiceAdd(dataItem: any): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(deleteInvoice({ id: dataItem.id }));
          getBillinvloicelist1({
            pageIndex: this.invoicecurrentPage,
            pageSize: this.pageSize,
            searchTerm: this.invoicesearchedValue,
            orderBy: this.orderByQuery,
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }

  deletdBillCharge(dataItem: any): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-primary ms-2',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.store.dispatch(deleteBillCharge({ id: dataItem.id }));
          this.getbillSettings();
          /*  swalWithBootstrapButtons.fire(
             'Deleted!',
             'Your file has been deleted.',
             'success'
           ); */
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error'
          );
        }
      });
  }

  billChargeId: any;
  editBillCharge(data: any, addChargeModal: any) {
    this.billChargeId = data.id;
    // console.log(data);
    let temp = this.utilityList.filter((x: any) => x.name == data.utility);
    // console.log(temp)
    this.billsChargeForm.patchValue({
      name: data.name,
      amount: data.amount,
      utilityType: temp.length > 0 ? temp[0].id : null,
      GST_Applicable: data.isGSTApplicable,
      gst: data.gst,
      total: data.total,
    });

    this.modelRef_1 = this.modalService.show(addChargeModal, {
      class: 'modal-dialog-centered',
    });
  }
  editInvoice(data: any, addInvoiceModal: any) {
    this.invoiceId = data.id;
    let dt = [];
    dt.push(new Date(data.from));
    dt.push(new Date(data.to));
    this.invoiceAddForm.patchValue({
      date: dt,
      slot: data.slot,
    });
    //console.log(this.invoiceAddForm.value);
    this.invoiceAdd = this.modalService.show(addInvoiceModal, {
      class: 'modal-dialog-centered',
    });
  }
  exportAll() {
    this.getAllbillSettings();
  }
  public onExport(type: string) {
    if (type === 'Excel') {
      if (this.billSettinggridData.data.length > 0) {
        setTimeout(() => {
          this.hiddenGrid?.saveAsExcel();
        }, 1000);
      } else {
        this.sharedService.showMessageDialog(
          'No data to export',
          '',
          SweetAlertIcon.ERROR
        );
      }
    } else if (type === 'Pdf') {
      setTimeout(() => {
        this.hiddenGrid?.saveAsPDF();
      }, 1000);
    }
    /*  if (this.billselectedRowdata.length == 0) {
       this.getAllbillSettings();
     } else {
       if (type === 'Excel') {
         setTimeout(() => {
           this.hiddenGrid?.saveAsExcel();
         }, 1000);
       } else if (type === 'Pdf') {
         setTimeout(() => {
           this.hiddenGrid?.saveAsPDF();
         }, 1000);
       }
     } */
  }

  billselectedRowdata: any = [];
  billsdeselectedRowdata: any = [];
  billsonSelectforBillSetting(event: any) {
    // console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.billselectedRowdata.push(element.dataItem);
        //  console.log(' selected Values : ', this.billselectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.billsdeselectedRowdata.push(element.dataItem);
        //  console.log('deselectedRowdata Values : ', this.billsdeselectedRowdata);
      });

      if (this.billsdeselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.billsdeselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.billselectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        console.log('', filteredArray);
        this.billselectedRowdata = filteredArray;
      }
    }
  }

  invoiceselectedRowdata: any = [];
  invoicedeselectedRowdata: any = [];
  invoiceSelectforBillSetting(event: any) {
    console.log(event.selectedRows);
    var selectedval = event.selectedRows;
    var deselectedVal = event.deselectedRows;
    if (selectedval.length > 0) {
      selectedval.forEach((element: any) => {
        this.invoiceselectedRowdata.push(element.dataItem);
        //  console.log(' selected Values : ', this.invoicedeselectedRowdata);
      });
    }
    if (deselectedVal.length > 0) {
      deselectedVal.forEach((element: any) => {
        this.invoicedeselectedRowdata.push(element.dataItem);
        console.log(
          'deselectedRowdata Values : ',
          this.invoicedeselectedRowdata
        );
      });

      if (this.invoicedeselectedRowdata.length > 0) {
        const idsToRemove = new Set(
          this.invoicedeselectedRowdata.map((item: { id: any }) => item.id)
        );

        const filteredArray = this.invoicedeselectedRowdata.filter(
          (item: { id: unknown }) => !idsToRemove.has(item.id)
        );
        // console.log('', filteredArray);
        this.invoicedeselectedRowdata = filteredArray;
      }
    }
  }
  invoicecurrentPage: number = 1;
  protected invoicePageChange({ skip, take }: PageChangeEvent): void {
    //  console.log('>>>', skip, take);
    this.skip = skip;
    this.pageSize = take;
    this.invoicecurrentPage = Math.floor(skip / take) + 1;
    console.log('currentpage', Math.floor(skip / take) + 1);
    getBillinvloicelist1({
      pageIndex: this.invoicecurrentPage,
      pageSize: this.pageSize,
      searchTerm: this.invoicesearchedValue,
      orderBy: this.orderByQuery,
    });
    this.loadBillsInvoiceGridTable();
  }

  billscurrentPage: number = 1;
  protected PageChange({ skip, take }: PageChangeEvent): void {
    /// console.log('>>>', skip, take);
    this.billselectedRowdata = [];
    this.skip = skip;
    this.pageSize = take;
    this.billscurrentPage = Math.floor(skip / take) + 1;
    //  console.log('currentpage', Math.floor(skip / take) + 1);
    this.getbillSettings();
    this.loadBillsGridTable();
  }

  protected billStateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.pageSize = state.take;
    this.filter = state.filter || { logic: 'and', filters: [] };

    this.loadBillsGridTable();
  }
  protected invoicetateChange(state: DataStateChangeEvent): void {
    this.sort = state.sort || [];
    this.filter = state.filter || { logic: 'and', filters: [] };
    this.loadBillsInvoiceGridTable();
  }

  public bilsonFilter(value: any): void {
    this.invoicePageChange({ skip: 0, take: this.pageSize })
    this.getbillSettings();
  }
  onCheckboxChange(event: any) {
    //console.log(event.target.checked);
    if (event.target.checked == true) {
      this.billsChargeForm.controls['gst'].setValue('');
      this.billsControls['gst'].setValidators([
        Validators.required,
        Validators.pattern(this.regex),
      ]);
      this.billsControls['gst'].updateValueAndValidity();
    } else {
      this.billsChargeForm.controls['gst'].setValue('');
      this.billsControls['gst'].clearValidators();
      this.billsControls['gst'].updateValueAndValidity();
      let amount = this.billsChargeForm.get('amount')?.value;
      this.billsChargeForm.controls['total'].setValue(amount);
    }
  }
  onFocusOutAmount(event: any) {
    let totalamount: number;
    let gstAmount: number;
    // console.log(this.billsChargeForm.get('gst')?.value);
    // let dt = this.billsChargeForm.get('gst')?.value.replace('%', '');
    let dt = this.billsChargeForm.get('gst')?.value;
    if (
      this.billsChargeForm.get('amount')?.value &&
      this.billsChargeForm.get('gst')?.value
    ) {
      gstAmount = (parseInt(event.target.value) * dt) / 100;
      totalamount =
        parseInt(this.billsChargeForm.get('amount')?.value) + gstAmount;
      this.billsChargeForm.controls['total'].setValue(totalamount);
    } else {
      this.billsChargeForm.controls['total'].setValue(
        this.billsChargeForm.get('amount')?.value
      );
    }
  }

  onFocusOut(event: any) {
    // console.log('Focus out', event.target.value);
    if (
      this.billsChargeForm.get('amount')?.value &&
      this.billsChargeForm.get('gst')?.value
    ) {
      let totalamount: number;
      let gstAmount: number;
      gstAmount =
        (parseInt(event.target.value) *
          this.billsChargeForm.get('amount')?.value) /
        100;
      totalamount =
        parseInt(this.billsChargeForm.get('amount')?.value) + gstAmount;
      this.billsChargeForm.controls['total'].setValue(totalamount);
    } else {
      this.billsChargeForm.controls['total'].setValue(
        this.billsChargeForm.get('amount')?.value
      );
    }
  }

  openChargeModel(addChargeModal: any) {
    this.billsChargeForm.reset();
    this.billChargeId = '';
    this.submitted = false

    this.modelRef_1 = this.modalService.show(addChargeModal, {
      class: 'modal-dialog-centered',
    });
  }

  opemInvoiceModel(addInvoiceModal: any) {
    this.invoiceId = '';
    this.invoiceAddForm.reset();
    this.invoiceAdd = this.modalService.show(addInvoiceModal, {
      class: 'modal-dialog-centered',
    });
  }

  public onbillsFilterChange(filter: CompositeFilterDescriptor): void {
    this.billsSettings.filter = filter;
    this.skip = 0
    // console.log(this.billsSettings);
    const queryString = this.sharedService.createQuery(this.billsSettings);
    this.queryString = this.sharedService.createQuery(this.billsSettings);
    this.billscurrentPage = 1
    this.getbillSettings();
    // console.log(queryString);
  }
  onSortChange(sort: SortDescriptor[]): void {
    this.billsInvoice.sort = sort;
    // console.log(this.billsInvoice.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.billsInvoice.sort
    );
    this.orderByQuery = orderByQuery;

    /*  this.store.dispatch(
       getBillinvloicelist1({
         pageIndex: this.invoicecurrentPage,
         pageSize: this.pageSize,
         searchTerm: this.invoicesearchedValue,
         orderBy: this.orderByQuery,
       })
     ); */
  }
  onbillsSortChange(sort: SortDescriptor[]): void {
    this.billsSettings.sort = sort;
    // console.log(this.billsSettings.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.billsSettings.sort
    );
    this.orderByQuery = orderByQuery;

    this.getbillSettings();
  }

  public onInvoiceFilterChange(filter: CompositeFilterDescriptor): void {
    this.billsInvoice.filter = filter;
    // console.log(this.billsInvoice);
    const queryString = this.sharedService.createQuery(this.billsInvoice);
    this.queryString = this.sharedService.createQuery(this.billsInvoice);
    this.store.dispatch(
      getBillinvloicelist1({
        pageIndex: this.invoicecurrentPage,
        pageSize: this.pageSize,
        searchTerm: this.invoicesearchedValue,
        orderBy: this.orderByQuery,
      })
    );
    //  console.log(queryString);
  }
  onInvoicdSortChange(sort: SortDescriptor[]): void {
    this.billsInvoice.sort = sort;
    console.log(this.billsInvoice.sort);
    const orderByQuery = this.sharedService.createOrderByQuery(
      this.billsInvoice.sort
    );
    this.orderByQuery = orderByQuery;

    this.getbillSettings();
  }
}
