<ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">

    <kendo-grid [data]="residenatilUintExtendedgridData" [navigable]="true" kendoGridFocusable [sortable]="false"
        [sort]="multiFlatsState.sort" [skip]="multiFlatsState.skip" filterable="menu" [columnMenu]="{ filter: false }"
        [resizable]="true" [filter]="multiFlatsState.filter" kendoGridFocusable>

        <kendo-grid-column field="flatNo" title="Flat No"></kendo-grid-column>

        <!--  <kendo-grid-column field="floor" title="Floor"></kendo-grid-column>
        <kendo-grid-column field="block" title="Block"></kendo-grid-column>
        <kendo-grid-column field="tower" title="Tower"></kendo-grid-column> -->
    </kendo-grid>


</div>