import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LoaderService } from './../../../core/services/loader.service';
import { Router } from '@angular/router';
import { SharedService } from '../../../shared/shared.service';
import { Store, select } from '@ngrx/store';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  createUser,
  resetCreateState,
  updateuserlist,
} from '../../../store/UserList/userlist.action';
import { Validations } from '../../../shared/constants/validations';
import { createMasterResponse } from '../../../store/Projects/projects-selector';
import { createUserResponse } from '../../../store/UserList/userlist-selector';
import { Roles } from '../../../shared/constants/enum';

@Component({
  selector: 'app-rp-create-user',
  templateUrl: './rp-create-user.component.html',
  styleUrl: './rp-create-user.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RpCreateUserComponent {
  createUserForm!: UntypedFormGroup;
  submitted: boolean = false;
  configData: any;
  tempprojectsList: any = [];
  userData: any;
  fieldTextType!: boolean;
  public isChecked = false;
  formControl: FormControl = new FormControl();
  prifileValidations = Validations.ProfilePage;
  selectedProject: any;
  isDisabled: boolean = true;
  pwdRegex = '^.*(?=.{8,})(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[A-Z]).*$';
  emailRgex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$';
  nameRegex = '^[A-Za-z]+( [A-Za-z]+)?$';
  projectlist: any[] = [];
  roles: any[] = [];
  roleList = Roles;
  breadCrumbItems: Array<{}> | undefined;
  public disabledMultiSelect = false; // Toggle for disabling MultiSelect
  selectedRole: string = '';
  constructor(
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.createUsersForm();
    if (
      history.state.userData !== undefined &&
      history.state.userData != null
    ) {
      this.userData = history.state.userData;
      this.breadCrumbItems = [
        { label: 'User', url: 'users', active: true },
        { label: 'Edit User' },
      ];
    } else {
      this.breadCrumbItems = [
        { label: 'User', url: 'users', active: true },
        { label: 'Create User' },
      ];
    }
  }
  public checked = true;
  ngOnInit(): void {
    this.formControl = this.createUserForm.get('islocked') as FormControl;
    this.loaderService.setLoading(true);
    this.getAllConfigData();

    this.createUserForm.get('role')?.valueChanges.subscribe((value) => {
      const selectedRole = this.configData?.data?.roles.find(
        (role: any) => role.id === value
      );
      this.selectedRole = selectedRole.name;
      if (selectedRole.name == this.roleList.ADMIN) {
        this.createUserForm
          .get('projectIds')
          ?.setValidators([Validators.required]); // Alerts the name of the selected role
      } else if (selectedRole.name == this.roleList.SUPERADMIN) {
        this.createUserForm.get('projectIds')?.clearValidators();
      }
      this.createUserForm.get('projectIds')?.updateValueAndValidity();
    });
  }


  onCountryCodeChange(event: any): void {
    console.log(event);

    // this.selectedCountryCode = event.target.value;
  }
  createUsersForm() {
    this.createUserForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
          Validators.pattern(this.nameRegex),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(25),
          Validators.pattern(this.nameRegex),
        ],
      ],
      email: ['', [Validators.required, Validators.pattern(this.emailRgex)]],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(/^[6-9]\d{9}$/),
        ],
      ],
      countryCode: [null, [Validators.required]],
      // password: [''],
      role: ['', [Validators.required]],
      projectIds: [null],
      status: [false],
      islocked: [false],
    });
  }
  valueChange(value: any): void {
    console.log('valueChange', value);
    this.selectedProject = value;
    if (value.name == 'SuperAdmin') {
      this.usersControls['projectIds'].clearValidators();
      this.usersControls['projectIds'].updateValueAndValidity();

      this.createUserForm.controls['projectIds'].disable();
    } else {
      this.usersControls['projectIds'].setValidators(Validators.required);
      this.usersControls['projectIds'].updateValueAndValidity();
      this.createUserForm.controls['projectIds'].enable();
    }
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }


  selectedCountyCode: any;
  valiadationMessage: String = ''
  valiadationMobile(event: any) {
    // console.log(event)
    this.selectedCountyCode = event.code;
    switch (this.selectedCountyCode) {
      /* case '+971'://United Arab Emirates
        this.usersControls['phoneNumber'].setValidators([Validators.required, Validators.maxLength(9), Validators.minLength(9), Validators.pattern(/^05[0-9]{8}$/),]);
        this.usersControls['phoneNumber'].updateValueAndValidity();
        this.valiadationMessage = 'Mobile Number Should Conatines min 9 and max 9 characters'

        break; */
      case '+91': // India
        this.usersControls['phoneNumber'].setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^[6-9]\d{9}$/),]);
        this.usersControls['phoneNumber'].updateValueAndValidity();
        this.valiadationMessage = 'Mobile Number should contain 10 characters'
        break;
      /*  case '+86'://China
         this.usersControls['phoneNumber'].setValidators([Validators.required, Validators.maxLength(11), Validators.minLength(11), Validators.pattern(/^1[3-9]\d{9}$/),]);
         this.usersControls['phoneNumber'].updateValueAndValidity();
         this.valiadationMessage = 'Mobile Number Should Conatines min 11 and max 11 characters'
         break; */


    }
  }


  getAllConfigData(): void {
    this.sharedService.getAllConfigData().subscribe((data) => {
      if (
        data.statusCode == 200 &&
        data.message == 'Config data retrieved successfully.'
      ) {
        this.configData = data;
        this.tempprojectsList = this.configData?.data?.projects;
        this.projectlist = this.configData?.data?.projects;
        this.roles = this.configData?.data?.roles;
        this.createUserForm.patchValue({
          role: this.roles[1].id,
        });
        this.selectedProject


        if (this.userData?.role == 'SuperAdmin') {
          this.createUserForm.controls['projectIds'].disable();
        } else {
          this.usersControls['projectIds'].setValidators(Validators.required);
          this.usersControls['projectIds'].updateValueAndValidity();
        }



        console.log(this.userData);
        if (Object.keys(this.userData ?? {}).length > 0) {
          this.createUserForm.patchValue({
            firstName: this.userData?.firstName ? this.userData.firstName : '',
            lastName: this.userData?.lastName ? this.userData.lastName : '',
            email: this.userData?.email ? this.userData.email : '',
            role: this.userData?.roleId ? this.userData.roleId : '',
            countryCode: this.userData?.countryCodeId ? this.userData.countryCodeId : '',
            phoneNumber: this.userData?.mobile ? this.userData.mobile : '', // password: this.userData?.password ? this.userData.password : '',
            projectIds: this.userData?.project ? this.userData.project.id : '',
            status: this.userData?.status == true ? true : false,
            islocked: this.userData?.isLocked ? this.userData.isLocked : false,
          });
        }

        if (this.userData.firstName) {
          this.createUserForm.controls['role'].disable();
          this.createUserForm.controls['projectIds'].disable();
        }
        this.configData?.data?.countryCodes.forEach((code: any) => {
          if (this.userData.countryCodeId == code.id) {
            this.valiadationMobile(code);
            console.log("dhsad")
          }

        })
      }
      // console.log('Projects', this.createUserForm.value);
      // console.log('Types', this.configData.data.types);
    });
  }

  get usersControls() {
    return this.createUserForm.controls;
  }

  public onFilterChange(searchTerm: any): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());

    this.projectlist = this.tempprojectsList.filter(contains(searchTerm));
  }

  userProjectsFilter(value: any) {
    this.projectlist = this.tempprojectsList.filter(
      (s: { name: string }) =>
        s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  createUser(type: string): void {
    this.submitted = true;
    if (this.createUserForm.valid) {
      this.loaderService.setLoading(true);
      console.log(this.createUserForm.value);
      var request = {
        firstName: this.createUserForm.get('firstName')?.value,
        lastName: this.createUserForm.get('lastName')?.value,
        email: this.createUserForm.get('email')?.value,
        roleId: this.createUserForm.get('role')?.value,
        // password: this.createUserForm.get('password')?.value,
        countryCode: this.createUserForm.get('countryCode')?.value,
        projectId:
          this.createUserForm.get('role')?.value ==
            '86f919ac-1942-42db-80d3-a9bf5c74b638'
            ? null
            : this.createUserForm.get('projectIds')?.value,
        phoneNumber: this.createUserForm.get('phoneNumber')?.value,
        status: this.createUserForm.get('status')?.value,
        islocked: this.createUserForm.get('islocked')?.value,
      };
      request.projectId = request.projectId == '' ? null : request.projectId;
      if (type == 'Create') {
        this.store.dispatch(createUser({ userData: request }));
        // this.loaderService.setLoading(false);
      } else if (type == 'Update') {
        console.log(this.createUserForm.value);
        this.store.dispatch(
          updateuserlist({ updatedData: request, id: this.userData.id })
        );
      }
    } else {
      return;
    }
  }
}
