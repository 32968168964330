import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GetAllMeterDetails, resetMeterDetailsState } from '../../../store/meters/meters.actions';
import { Subscription } from 'rxjs';
import { PdfexportService } from '../../../core/services/pdfexport.service';
import { getAllmeterConsumptionResponse } from '../../../store/meters/meters-selector';
import { LoaderService } from '../../../core/services/loader.service';
import { SharedService } from '../../../shared/shared.service';
import { SweetAlertIcon } from '../../../shared/constants/enum';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-rp-meters',
  templateUrl: './rp-meters.component.html',
  styleUrl: './rp-meters.component.scss'
})
export class RpMetersComponent {
  breadCrumbItems: Array<{}> | undefined;
  private getAllmeterConsumptionResponse$: Subscription = new Subscription();
  meterAllDetails: any[] = [];
  constructor(
    private sharedService: SharedService,
    private loaderService: LoaderService,
    private store: Store, private PdfexportService: PdfexportService
  ) {
    this.getStoreInfo()
    this.breadCrumbItems = [{ label: 'Meter Readings ' }];
  }


  exportAll() {
    this.store.dispatch(resetMeterDetailsState());
    this.getAllMeterDetails();
  }

  getAllMeterDetails() {
    this.store.dispatch(
      GetAllMeterDetails({
        pageIndex: 1,
        pageSize: undefined,
        searchTerm: '',
        filter: '',
        orderBy: '',
        MeterId: '',
        FromDate: '',
        ToDate: '',
      })
    );
  }



  getStoreInfo() {
    this.getAllmeterConsumptionResponse$ = this.store.pipe(select(getAllmeterConsumptionResponse)).subscribe((res: any) => {
      console.log(res)
      if (res) {
        this.meterAllDetails = [...res.items];
        if (this.meterAllDetails.length > 0) {
          const transformedData = this.meterAllDetails.map((item: any) => ({
            'Device SR.No': item.deviceSRNo,
            'Utility': item.utilityType,
            'Time Stamp': formatDate(item.currentDate, 'dd MMM yyyy hh:mm a', 'en-US'),
            'Meter Reading': item.meterReading,
            'Meter Reading DG': item.meterReadingDG,
            'Meter Reading EB': item.meterReadingEB,
            'On/Off Status': item.onOffStatus,
            "Battery Level": item.batterLevel,
            'Battery Voltage': item.batteryVoltage, // Placeholder for region.name
            // 'Status Bit': item.statusBits, // Placeholder for bill date
            Occupant: item.occupant,


            // Placeholder for status
          }));
          this.PdfexportService.downloadExcel(transformedData, 'Meter Readings');
        } else {
          this.sharedService.showMessageDialog(
            'No data to export',
            '',
            SweetAlertIcon.INFO
          );
        }
      }
      console.log('MetersConfiguration', res);
    });
  }
  ngOnDestroy() {
    this.getAllmeterConsumptionResponse$.unsubscribe()
  }
}
