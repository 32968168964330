<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        <span class="breadcrumb m-0">
          <button class="btn btn-primary d-flex align-items-center" (click)="exportAll()">
            <span class="fas fa-file-excel"> </span> &nbsp;Export All to Excel
          </button>

        </span>

      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <div class="page-title-right">
          <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
        </div>
      </div>
    </div>
  </div> -->



  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <!-- <ng-stepper #cdkStepper [linear]="true" class="wizard sm-stepper custom-steeper">

            <cdk-step [optional]="false">
              <ng-template cdkStepLabel>
                <span class="number">1</span>
                <span> Bill Settings</span>
              </ng-template>
              @if(cdkStepper.selectedIndex === 0){ -->

          <section class="">
            <div>
              <!--  <div class="d-flex justify-content-end mb-3">
                    <button class="btn btn-primary" (click)="openChargeModel(addChargeModal)">Add Charge </button>
                  </div> -->
              <accordion [closeOthers]="false" class="accordion custom-accordian" [isAnimated]="true">
                <accordion-group [isOpen]="isAllOpen" heading="Additional Charges"
                  class="accordion-item mb-3 panel-open" #group>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                    <i class="mdi font-size-24"
                      [ngClass]="!group?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                  </span>
                  <ng-template #template let-anchor>
                    <span>{{ anchor.nativeElement.innerText }}</span>
                  </ng-template>

                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid [data]="billSettinggridData" [pageSize]="pageSize" [skip]="skip" [sortable]="true"
                      [pageable]="{
                                            buttonCount: buttonCount,
                                            info: info,
                                            type: type,
                                            pageSizes: pageSizes,
                                            previousNext: previousNext
                                          }" [selectable]="selectableSettings" [reorderable]="true" filterable="menu"
                      [filter]="billsSettings.filter" [sort]="billsSettings.sort" [columnMenu]="{ filter: true }"
                      (filterChange)="onbillsFilterChange($event)" (sortChange)="onbillsSortChange($event)"
                      [resizable]="true" scrollable="scrollable" (pageChange)="PageChange($event)"
                      (selectionChange)="billsonSelectforBillSetting($event)"
                      (dataStateChange)="billStateChange($event)">

                      <ng-template kendoGridToolbarTemplate>
                        <!-- <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                              [(ngModel)]="searchedValue" (valueChange)="bilsonFilter($event)"></kendo-textbox> -->

                        <div class="search_icon">
                          <kendo-textbox [style.width.px]="250" [style.height.px]="35" [clearButton]="true"
                            (valueChange)="onValueChange($event)"   (keydown.enter)="onSearchWalletSettings()" [(ngModel)]="searchedValue"
                            placeholder="Search in all columns...">
                          </kendo-textbox>

                          <button class="btn btn-primary btn-m border-left-none" (click)="bilsonFilter($event)">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                        <kendo-grid-spacer></kendo-grid-spacer>
                        <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                          (click)="openChargeModel(addChargeModal)">Add Charge
                        </button>

                        <button class="btn btn-outline-primary btn-sm d-flex align-items-center"
                          (click)="onExport('Excel')">
                          <span class="fas fa-file-excel"> </span> &nbsp; Export to Excel
                        </button>
                        <!-- <button class="btn btn-light" (click)="onExport('Pdf')">
                            <span class="fas fa-file-pdf"> </span> &nbsp; Export to PDF</button> -->


                      </ng-template>




                      <!-- Pdf   start-->
                      <div style="position: absolute; top: 0; left: -10000px; width: 500px;">
                        <kendo-grid
                          [kendoGridBinding]="billselectedRowdata.length>0 ? billselectedRowdata:billSettinggridData.data"
                          #pdfGrid>
                          <kendo-grid-column field="name" title="Name"></kendo-grid-column>
                          <kendo-grid-column field="amount" title="Amount">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{ dataItem.availableBalance | currency: 'INR':'symbol':'1.2-2' }}
                            </ng-template>
                          </kendo-grid-column>
                          <!-- <kendo-grid-column field="amount" title="Amount"></kendo-grid-column> -->
                          <kendo-grid-column field="utility" title="Utility"></kendo-grid-column>
                          <kendo-grid-column field="gstStatus" title="GST Applicable">
                          </kendo-grid-column>
                          <kendo-grid-column field="createdOn" title="Created On" [width]="100" filter="date">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{dataItem.createdOn | date: 'dd MMM yyyy'}}
                            </ng-template>
                          </kendo-grid-column>
                          
                          <kendo-grid-column field="gst" title="GST(%)"></kendo-grid-column>
                          <kendo-grid-column field="total" title="Total">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{ dataItem.availableBalance | currency: 'INR':'symbol':'1.2-2' }}
                            </ng-template>
                          </kendo-grid-column>
                          <!--   <kendo-grid-column field="total" title="Total"></kendo-grid-column> -->
                          <kendo-grid-pdf fileName="manualBills.pdf" [allPages]="true" paperSize="A4"
                            [landscape]="true"></kendo-grid-pdf>
                          <kendo-grid-excel fileName="billsSettings.xlsx"></kendo-grid-excel>
                        </kendo-grid>
                      </div>
                      <!-- Pdf Edrt-->



                      <kendo-grid-checkbox-column [columnMenu]="false" [showSelectAll]="true" [width]="40"
                        class="no-tooltip"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="name" title="Name" [width]="200"></kendo-grid-column>
                      <kendo-grid-column field="amount" title="Amount" filter="numeric" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.amount | currency: 'INR':'symbol':'1.2-2' }}
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="utility" title="Utility" [width]="100"></kendo-grid-column>
                      <kendo-grid-column field="isGSTApplicable" title="GST Applicable" filter="boolean" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.isGSTApplicable == true ? 'Yes' : 'No' }}
                          <!-- <div class="d-flex align-items-center justify-content-center">
                            <div class="form-check mb-3">
                              <input class="form-check-input" disabled type="checkbox"
                                [checked]="dataItem.isGSTApplicable" id="formCheck1">
                            </div>
                          </div> -->
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="createdOn" title="Created On" filter="date" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.createdOn | date: 'dd MMM yyyy'}}
                        </ng-template>
                      </kendo-grid-column>
                      
                      <kendo-grid-column field="gst" title="GST(%)" [width]="90" filter="numeric"></kendo-grid-column>
                      <kendo-grid-column field="total" title="Total" [width]="100" filter="numeric">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          {{ dataItem.total | currency: 'INR':'symbol':'1.2-2' }}
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-command-column title="" [columnMenu]="false" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex">
                            <!-- Edit Bill Charge Icon (Clickable) -->
                            <span kendoTooltip title="Go to Bill Edit Charge"
                              (click)="editBillCharge(dataItem, addChargeModal)" style="cursor: pointer;" class="me-3">
                              <i class="fas fa-edit text-primary"></i>
                            </span>

                            <!-- Delete Bill Charge Icon (Clickable) -->
                            <span kendoTooltip title="Delete Bill Charge" (click)="deletdBillCharge(dataItem)"
                              style="cursor: pointer;">
                              <i class="fas fa-trash-alt text-danger"></i>
                            </span>
                          </div>
                        </ng-template>
                      </kendo-grid-command-column>


                    </kendo-grid>
                  </div>


                </accordion-group>

                @if(billingType=='Postpaid'){ <accordion-group [isOpen]="isAllOpen" heading="Optional"
                  class="accordion-item mb-3 panel-open" #item3>
                  <span accordion-heading class="float-end fs-5 d-flex align-items-center">

                    <i class="fas font-size-24"
                      [ngClass]="!item3?.isOpen ? 'mdi mdi-chevron-down accor-plus-icon ': 'mdi mdi-chevron-up accor-plus-icon '"></i>
                  </span>

                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="basicpill-pancard-input"> </label>
                      <div>
                        <input class="form-check-input" type="checkbox" [(ngModel)]="isPenalityApplicable"
                          (ngModelChange)="onPenalityApplicableChange($event)" id="IsWalletEnabled">
                        <label class="form-check-label ms-2" for="IsWalletEnabled">Is penality applicable </label>
                      </div>
                    </div>
                  </div>

                  @if(this.billGenType !=='Combined' && isPenalityApplicable){
                  <form [formGroup]="individualbillForm">
                    <div class="row">
                      <div class="col-lg-3 mb-3">
                        <div class="mb-3">
                          <label>Penality applicable date for electricity <span class="required">*</span></label>
                          <ng-select [items]="projectBillingDates" formControlName="PenaltyApplicable_dateElectricity"
                            id="billDate" [multiple]="false"></ng-select>
                          @if(optionalSubmitted &&
                          optionalbillsControls['PenaltyApplicable_dateElectricity'].errors){
                          <div class="invalid-feedback">
                            @if(optionalbillsControls['PenaltyApplicable_dateElectricity'].errors['required']){
                            <div>{{prifileValidations.billCharge.PenaltyApplicable_dateElectricity}}</div>}
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-3 mb-3">
                        <div class="mb-3">
                          <label>Penality applicable date for gas <span class="required">*</span></label>
                          <ng-select [items]="projectBillingDates" formControlName="PenaltyApplicable_dateGas"
                            [placeholder]="'Select..'" [multiple]="false" [deselectOnClick]="true"></ng-select>
                          @if(optionalSubmitted && optionalbillsControls['PenaltyApplicable_dateGas'].errors){
                          <div class="invalid-feedback">
                            @if(optionalbillsControls['PenaltyApplicable_dateGas'].errors['required']){
                            <div>{{prifileValidations.billCharge.PenaltyApplicable_dateGas}}</div>}

                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-3 mb-3">
                        <div class="mb-3">
                          <label>Penality applicable date for water<span class="required">*</span></label>
                          <ng-select [items]="projectBillingDates" formControlName="PenaltyApplicable_dateWater"
                            [placeholder]="'Select..'" [multiple]="false" [deselectOnClick]="true"></ng-select>
                          @if(optionalSubmitted && optionalbillsControls['PenaltyApplicable_dateWater'].errors){
                          <div class="invalid-feedback">
                            @if(optionalbillsControls['PenaltyApplicable_dateWater'].errors['required']){
                            <div>{{prifileValidations.billCharge.PenaltyApplicable_dateWater}}</div>}

                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-3 mb-3">
                        <div class="mb-3">
                          <label>Penality applicable date for others<span class="required">*</span></label>
                          <ng-select [items]="projectBillingDates" formControlName="PenaltyApplicable_dateOthers"
                            [placeholder]="'Select..'" [multiple]="false" [deselectOnClick]="true"></ng-select>
                          @if(optionalSubmitted && optionalbillsControls['PenaltyApplicable_dateOthers'].errors){
                          <div class="invalid-feedback">
                            @if(optionalbillsControls['PenaltyApplicable_dateOthers'].errors['required']){
                            <div>{{prifileValidations.billCharge.PenaltyApplicable_dateOthers}}</div>}

                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-3 mb-3">
                        <div class="mb-3">
                          <label>Penality amount for electricity<span class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="PenalityAmountForElectricity"
                            placeholder="Enter Amount ">
                          @if(optionalSubmitted && optionalbillsControls['PenalityAmountForElectricity'].errors){
                          <div class="invalid-feedback">
                            @if(optionalbillsControls['PenalityAmountForElectricity'].errors['required']){
                            <div>{{prifileValidations.billCharge.PenalityAmountForElectricity}}</div>}
                            @if(optionalbillsControls['PenalityAmountForElectricity'].errors['pattern']){
                            <div>{{prifileValidations.billCharge.Onlynumberwithdecimals}}</div>}
                          </div>}

                        </div>
                      </div>

                      <div class="col-lg-3 mb-3">
                        <div class="mb-3">
                          <label>Penality amount for gas<span class="required">*</span></label>
                          <input type="text" class="form-control" formControlName="PenalityAmountForGas"
                            placeholder="Enter Amount ">
                          @if(optionalSubmitted && optionalbillsControls['PenalityAmountForGas'].errors){
                          <div class="invalid-feedback">
                            @if(optionalbillsControls['PenalityAmountForGas'].errors['required']){
                            <div>{{prifileValidations.billCharge.PenalityAmountForGas}}</div>}
                            @if(optionalbillsControls['PenalityAmountForGas'].errors['pattern']){
                            <div>{{prifileValidations.billCharge.Onlynumberwithdecimals}}</div>}
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-3 mb-3">
                        <div class="mb-3">
                          <label>Penality amount for water <span class="required">*</span></label>
                          <input type="text" class="form-control" placeholder="Enter Amount"
                            formControlName="PenalityAmountForWater">
                          @if(optionalSubmitted && optionalbillsControls['PenalityAmountForWater'].errors){
                          <div class="invalid-feedback">
                            @if(optionalbillsControls['PenalityAmountForWater'].errors['required']){
                            <div>{{prifileValidations.billCharge.PenalityAmountForWater}}</div>}
                            @if(optionalbillsControls['PenalityAmountForWater'].errors['pattern']){
                            <div>{{prifileValidations.billCharge.Onlynumberwithdecimals}}</div>}
                          </div>}
                        </div>
                      </div>

                      <div class="col-lg-3 mb-3">
                        <div class="mb-3">
                          <label>Penality amount for others <span class="required">*</span></label>
                          <input type="text" class="form-control" placeholder="Enter Amount"
                            formControlName="PenalityAmountForOthers">
                          @if(optionalSubmitted && optionalbillsControls['PenalityAmountForOthers'].errors){
                          <div class="invalid-feedback">
                            @if(optionalbillsControls['PenalityAmountForOthers'].errors['required']){
                            <div>{{prifileValidations.billCharge.PenalityAmountForOthers}}</div>}
                            @if(optionalbillsControls['PenalityAmountForOthers'].errors['pattern']){
                            <div>{{prifileValidations.billCharge.Onlynumberwithdecimals}}</div>}
                          </div>}
                        </div>
                      </div>


                    </div>
                  </form>
                  }
                  @else if(isPenalityApplicable){
                  <form [formGroup]="combinedBillForm">
                    <div class="row">
                      <div class="col-lg-3 mb-3">
                        <div class="mb-3">
                          <label>Penality applicable Date <span class="required">*</span> </label>
                          <ng-select [items]="projectBillingDates" formControlName="date" id="billDate"
                            [multiple]="false"></ng-select>
                          @if(individualbillSubmitted &&
                          combinedBillFormControls['date'].errors){
                          <div class="invalid-feedback">
                            @if(combinedBillFormControls['date'].errors['required']){
                            <div>{{prifileValidations.billCharge.Penalitydate}}</div>}
                          </div>}
                        </div>
                      </div>
                      <div class="col-lg-3 mb-3">
                        <label>Penality Amount <span class="required">*</span></label>
                        <input type="text" class="form-control" placeholder="Enter Amount" formControlName="amount">
                        @if(individualbillSubmitted &&
                        combinedBillFormControls['amount'].errors){
                        <div class="invalid-feedback">
                          @if(combinedBillFormControls['amount'].errors['required']){
                          <div>{{prifileValidations.billCharge.PenalityAmount}}</div>}
                          @if(combinedBillFormControls['amount'].errors['pattern']){
                          <div>{{prifileValidations.billCharge.Onlynumberwithdecimals}}</div>}

                        </div>}
                      </div>

                    </div>
                  </form>
                  }

                  <ul class="list-inline wizard d-flex justify-content-end mt-4">
                    <!-- <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                                        cdkStepperPrevious>Previous</button></li>
                                     -->
                    <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                        (click)="saveOptionalfrom()">Save</button></li>
                    <!-- <li class="next list-inline-item" aria-disabled="false"><button class="btn btn-primary"
                            cdkStepperNext>Next</button></li> -->
                  </ul>
                </accordion-group>}
              </accordion>



              <!-- <div class="d-flex justify-content-end">
                                  <button type="button" class="btn btn-primary"> Save </button>
                              </div> -->

            </div>

          </section>


          <!--  }
            </cdk-step> -->

          <!-- <cdk-step [optional]="true" [hidden]="true">
              <ng-template cdkStepLabel>
                <span class="number">2.</span>
                <span>Invoice Ads</span>
              </ng-template>
              @if(cdkStepper.selectedIndex === 1){
              <section class="">

                <kendo-grid [data]="billInvoicegridData" [reorderable]="true" filterable="menu"
                  [columnMenu]="{ filter: true }" [resizable]="true" [pageable]="{
                  buttonCount: buttonCount,
                  info: info,
                  type: type,
                  pageSizes: billpageSizes,
                  previousNext: billpreviousNext
                }" [pageSize]="10" [sortable]="true" [selectable]="selectableSettings" [filter]="billsInvoice.filter"
                  [skip]="billsInvoice.skip" [sort]="billsInvoice.sort" [columnMenu]="{ filter: true }"
                  (filterChange)="onInvoiceFilterChange($event)" (sortChange)="onSortChange($event)"
                  kendoGridSelectBy="id" [resizable]="true" (pageChange)="invoicePageChange($event)"
                  (dataStateChange)="invoicetateChange($event)" (selectionChange)="invoiceSelectforBillSetting($event)">

                  <ng-template kendoGridToolbarTemplate>
                    <kendo-textbox [style.width.px]="250" placeholder="Search in all columns..."
                      [(ngModel)]="invoicesearchedValue" (valueChange)="onFilter($event)"></kendo-textbox>
                    <kendo-grid-spacer></kendo-grid-spacer>
                    <button class="btn btn-primary btn-sm" (click)="opemInvoiceModel(addAdvertismentModal)">Add New
                      Advertisement
                    </button>
                  </ng-template>


                  <kendo-grid-column field="ProjectCode" title="Image" [width]="100" [class]="{ 'text-center': true }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <img class="rounded" [src]="dataItem.fileUrl" [width]="30" />
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="from" title="From to date">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      {{dataItem.from | date: 'dd/MM/yyyy'}}--{{dataItem.to | date: 'dd/MM/yyyy'}}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="slot" title="Slot Position"></kendo-grid-column>
                  <kendo-grid-command-column title="Actions" [columnMenu]="false" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <div class="d-flex">
                        <button class="btn btn-primary btn-sm d-flex align-items-center m-lg-auto "
                          (click)="editInvoice(dataItem,addAdvertismentModal)">
                          Edit
                        </button>
                        <button (click)="deleteInvoice()"
                          class="btn btn-light btn-sm d-flex align-items-center m-lg-auto "
                          (click)="deletdinvoiceAdd(dataItem)">
                          Delete
                        </button>
                      </div>
                    </ng-template>
                  </kendo-grid-command-column>

                </kendo-grid>

                <ul class="list-inline wizard d-flex justify-content-end mt-4">
                  <li class="previous list-inline-item" aria-disabled="true"><button class="btn btn-outline-primary"
                      cdkStepperPrevious>Previous</button></li>
                </ul>



              </section>
              }
            </cdk-step> -->

          <!-- </ng-stepper> -->
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Add Charge modal -->
<ng-template #addChargeModal>
  <div class="modal-lg ">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        @if(billChargeId) { <h5 class="modal-title">Edit Charge</h5>}
        @else {<h5 class="modal-title">Add Charge</h5>}
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="modelRef_1.hide()"></button>
      </div>
      <div class="modal-body">

        <form [formGroup]="billsChargeForm">
          <div class="row">

            <div class="col-lg-6 mb-3">
              <label>Name <span class="required">*</span></label>
              <input type="text" placeholder="Enter Name" formControlName="name" class="form-control" />
              @if(submitted && billsControls['name'].errors){
              <div class="invalid-feedback">
                @if(billsControls['name'].errors['required']){
                <div>{{prifileValidations.billCharge.name}}</div>}
                @if(billsControls['name'].errors['pattern']){
                <div>{{prifileValidations.billCharge.OnlyAlphanumeric_patten}}</div>}
              </div>}
            </div>

            <div class="col-lg-6 mb-3">
              <label>Amount <span class="required">*</span></label>
              <input type="text" placeholder="Enter Amount" (keyup)="onFocusOutAmount($event)" formControlName="amount"
                class="form-control" />
              @if(submitted && billsControls['amount'].errors){
              <div class="invalid-feedback">
                @if(billsControls['amount'].errors['required']){
                <div>{{prifileValidations.billCharge.amount}}</div>}
                @if(billsControls['amount'].errors['pattern']){
                <div>{{prifileValidations.billCharge.Onlynumberwithdecimals}}</div>}
              </div>}
            </div>

            <div class=" col-lg-6 mb-3">
              <label>Utility</label>
              <ng-select [items]="utilityList" formControlName="utilityType" id="ocupantName" name="ocupantName"
                (change)="utilityChange($event)" [multiple]="false" bindLabel="name" bindValue="id"
                [placeholder]="'Select...'"></ng-select>
              <!--  @if(submitted && billsControls['utilityType'].errors){
              <div class="invalid-feedback">
                @if(billsControls['utilityType'].errors['required']){
                <div>{{prifileValidations.billCharge.utilityType}}</div>}

              </div>} -->
            </div>

            <div class="col-lg-6 mt-4">
              <input class="form-check-input" type="checkbox" (change)="onCheckboxChange($event)"
                formControlName="GST_Applicable" id="formCheck1">
              <label class="form-check-label" for="formCheck1">GST Applicable</label>
              <!-- <label class="font-size-14 font-weight-bold ms-2">GST
                Applicable</label> -->
            </div>
            @if(billsControls['GST_Applicable'].value == true ){
            <div class="col-lg-6 mb-3">
              <label>GST(%)<span class="required">*</span></label>
              <input type="text" placeholder="Enter GST" formControlName="gst" class="form-control"
                (keyup)="onFocusOut($event)">
              @if(submitted && billsControls['gst'].errors){
              <div class="invalid-feedback">
                @if(billsControls['gst'].errors['required']){
                <div>{{prifileValidations.billCharge.gst}}</div>}
                @if(billsControls['gst'].errors['pattern']){
                <div>{{prifileValidations.billCharge.patten}}</div>}
              </div>}
            </div>
            }
            <div class="col-lg-6 mb-3">
              <label>Total <span class="required">*</span></label>
              <input type="text" formControlName="total" class="form-control" />
              @if(submitted && billsControls['total'].errors){
              <div class="invalid-feedback">
                @if(billsControls['total'].errors['required']){
                <div>{{prifileValidations.billCharge.total}}</div>}

              </div>}
            </div>
          </div>
        </form>


      </div>
      <div class="modal-footer">
        @if(billChargeId){ <button type="button" class="btn btn-primary waves-effect waves-light"
          (click)="saveCharge('update')">Update</button>}
        @else {<button type="button" class="btn btn-primary waves-effect waves-light"
          (click)="saveCharge('Create')">Save</button>}
      </div>
    </div>
  </div>

</ng-template>
<!-- Add Charge modal End  -->