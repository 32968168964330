<app-rp-loader></app-rp-loader>
<div class="container-fluid">
  <app-rp-breadcrumb [breadcrumbItems]="breadCrumbItems"></app-rp-breadcrumb>
  <!-- <div class="row">
    <div class="col-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 class="mb-0 font-size-18">Add FAQ</h4>
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item active"><a href="javascript: void(0);" [routerLink]="'/faq'">FAQ</a>
            </li>

            <li class="breadcrumb-item">Create New
            </li>

          </ol>
        </div>
      </div>
    </div>
  </div> -->

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">FAQ Details</h4>
          <form class="needs-validation" [formGroup]="faqForm">

            <div class="row">
              <div class="col-lg-4">
                <div class="mb-4">
                  <label for="">FAQ Title <span class="required">*</span></label>
                  <input type="text" class="form-control" formControlName="Title" id="Title"
                    placeholder="Enter FAQ Title">
                  @if(submitted && f['Title'].errors){
                  <div class="invalid-feedback">
                    @if(f['Title'].errors['required']){
                    <div>Title is required</div>}
                    @if(f['Title'].errors['minlength']) {
                    <div>Title name should contain min of 2 characters </div>}
                    @if(f['Title'].errors['maxlength']) {
                    <div>Title name should contain max of 100 characters</div>}
                    <div *ngIf="f['Title'].errors['whitespace']">Title cannot be blank spaces.</div>

                    <div *ngIf="f['Title'].errors['pattern']">Title cannot contain special characters</div>
                  </div>}


                </div>
                <div class="mb-3">
                  <label for="b">Attachment </label>
                  <kendo-upload [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl" formControlName="Files"
                    id="Files" (select)="handleFileSelected($event)" (remove)="handleFileRemoved($event)"
                    (success)="handleFileUploadSuccess($event)" (error)="handleFileUploadError($event)">
                  </kendo-upload>



                  @if(receivedData){<div class="uploaded-files-container" style="margin-top: 5px;">
                    <ul class="uploaded-files-list">
                      <li *ngFor="let file of listOffiles; let i = index" class="uploaded-file-item">
                        <ng-container *ngIf="file.fileType.startsWith('image'); else fileIcon">
                          <img [src]="file.fileUrl" alt="{{ file.fileName }}" class="thumbnail">
                        </ng-container>
                        <ng-template #fileIcon>
                          <i class="fas fa-file-alt file-icon"></i>
                        </ng-template>
                        <span class="file-info">{{ file.fileName }} </span>
                        <button type="button" class="btn btn-danger btn-sm delete-button"
                          (click)="removeUploadedFile(file.id)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </li>
                    </ul>
                  </div>}


                </div>

              </div>
              <div class="col-lg-8">
                <div class="mb-4">
                  <label for="">FAQ Content<span class="required">*</span></label>
                  <textarea formControlName="textarea" formControlName="Content" id="Content"
                    placeholder="Enter FAQ Content" class="form-control" rows="6"></textarea>
                  @if(submitted && f['Content'].errors){
                  <div class="invalid-feedback">
                    @if(f['Content'].errors['required']){
                    <div>Content is required</div>}
                    <span *ngIf="f['Content'].hasError('whitespace')">Content cannot be blank spaces.</span>
                  </div>}
                  <!-- <div class="invalid-feedback" *ngIf="f['Content'].errors">
                    <div *ngIf="f['Content'].hasError('required')">Content is required.</div>
                    <div *ngIf="f['Content'].errors['whitespace']">Content cannot be blank spaces.</div>
                  </div> -->

                </div>
                <div>
                  <ul class="list-inline wizard d-flex justify-content-end mb-0">
                    <li class="previous list-inline-item"><button class="btn btn-outline-primary"
                        (click)="navToFaqPage()">Cancel</button>
                    </li>

                    @if(faqId){<li class="next list-inline-item"><button class="btn btn-primary"
                        [disabled]="disableFile" (click)="updateFaq()">Update
                        FAQ's</button></li>}@else{
                    <li class="next list-inline-item"><button class="btn btn-primary" [disabled]="disableFile"
                        (click)="onSubmit()">Save
                        FAQ's</button>
                    </li>
                    }
                  </ul>
                </div>
              </div>

            </div>
            <!-- 
            <ul class="list-inline wizard d-flex justify-content-end mb-0">
              <li class="previous list-inline-item"><button class="btn btn-outline-primary"
                  (click)="navToFaqPage()">Cancel</button></li>

              @if(faqId){<li class="next list-inline-item"><button class="btn btn-primary" [disabled]="disableFile"
                  (click)="updateFaq()">Update
                  FAQ's</button></li>}@else{
              <li class="next list-inline-item"><button class="btn btn-primary" [disabled]="disableFile"
                  (click)="onSubmit()">Save FAQ's</button>
              </li>
              }
            </ul> -->

          </form>

        </div>
      </div>
    </div>
  </div>

</div>