import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import { getBilldeatilsResponse } from '../../../store/customer/consumer-selector';
import { getBilldeatils, resegetBilldeatils } from '../../../store/customer/consumer.action';
import { SharedService } from '../../../shared/shared.service';
@Component({
  selector: 'app-rp-bill-history-view',
  templateUrl: './rp-bill-history-view.component.html',
  styleUrl: './rp-bill-history-view.component.scss'
})
export class RpBillHistoryViewComponent {
  billGenarateOpen: boolean = false
  isAllOpen: boolean = true;
  billNo: any;
  currentUser: any
  billHostorydeatils: any;
  undefinde: any;
  role: any
  billGenerationType: any;
  breadCrumbItems: Array<{}> | undefined;
  constructor(private loaderService: LoaderService, private store: Store, private sharedService: SharedService,) {
    this.billGenerationType = "Combined"
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.role = this.currentUser.currentUserRoleDetails.role;
    if (history.state.billHostorydeatils != undefined && history.state.billHostorydeatils !== null) {
      this.billNo = history.state.billHostorydeatils.billNo;
      console.log(this.billNo)
      if (this.role.toLowerCase() == 'admin') {
        this.breadCrumbItems = [{ label: 'Wallet', url: 'wallet', active: true }, { label: 'Wallet Details', url: 'wallet/walletDetails', active: true }, { label: ' View Bill Histrory', },];
      } else {
        this.breadCrumbItems = [{ label: 'Bill Histrory', url: 'billHistory', active: true }, { label: ' View Bill Histrory', },];
      }

      //  console.log(this.billHostorydeatils)
      this.sharedService.setbilldeatilsById(history.state.billHostorydeatils)
    }
    this.store.dispatch(resegetBilldeatils());
    /*  let getBillDetailsbyId$ = this.store.pipe(select(getBilldeatilsResponse));
     getBillDetailsbyId$.subscribe((data: any) => {
       if (data) {
         this.billHostorydeatils = data;
 
         console.log(this.billHostorydeatils)
       }
     }); */

  }
  ngOnInit(): void {
    //this.store.dispatch(getBilldeatils({ BillNo: this.billNo, }))
  }

}
